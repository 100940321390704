import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "assets/icons/icons/arrow_back.svg";
//MUI
import IconButton from "@material-ui/core/IconButton";

const BackButton = ({ className, ...rest }) => {
  const history = useHistory();

  const handleForwardToPreviousPage = () => {
    history.goBack();
  };
  return (
    <IconButton
      classes={{ root: className }}
      onClick={handleForwardToPreviousPage}
    >
      <img src={ArrowBackIcon} alt="arrow back" />
    </IconButton>
  );
};

export default BackButton;
