import React from "react";
import MainContent from "./components/MainContent/MainContent";

// Shared components
import PageHeader from "shared_components/PageHeader/PageHeader";
import Navbar from "shared_components/Navbar/Navbar";
import FinishedRegCheck from "auth/FinishedRegCheck";

//MUI
import useStyles from "./styles";

const Following = () => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <FinishedRegCheck />
      <Navbar />
      <PageHeader
        title="Browse funds"
        bodyText="Discover all your preffered funds"
      />
      <MainContent />
    </div>
  );
};

export default Following;
