import React from "react";
import Navbar from "shared_components/Navbar/Navbar";
import Navigation from "./components/Navigation/Navigation";
import Banner from "./components/Banner/Banner";
import FinishedRegCheck from "auth/FinishedRegCheck";
//MUI
import useStyles from "./styles";

const VideoChannel = () => {
  const classes = useStyles();
  return (
    <>
      <FinishedRegCheck />
      <Navbar />
      <main className={classes.main}>
        <Banner />
        <Navigation />
      </main>
    </>
  );
};

export default VideoChannel;
