import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navigationWrapper: {
    overflowX: "scroll",
    // hide scroll bar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(2)}px`,
      justifyContent: "flex-start",
    },
  },
  navigation: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.background.border}`,
    paddingLeft: `${theme.spacing(6)}px !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(4)}px !important`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 !important`,
      gap: `${theme.custom.pxToRem(32)}`,
      width: "fit-content",
    },
  },
}));
