import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: `${theme.custom.pxToRem(16)}`,
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(8)} `,

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(4)}px ${theme.spacing(5)}px 0 !important`,
      transform: "translateX(-0.5rem)",
    },
    [theme.breakpoints.down("md")]: {
      transform: "translateX(-0.5rem)",
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        height: `${theme.custom.pxToRem(16)}`,
        width: `${theme.custom.pxToRem(23)}`,
      },
      padding: `${theme.custom.pxToRem(11)} ${theme.custom.pxToRem(8)}`,
    },
    margin: 0,
  },
  grid: {
    width: "100% !important",
  },
  title: {
    margin: `${theme.custom.pxToRem(12)} 0 ${theme.custom.pxToRem(24)}`,
  },
  text: {
    marginBottom: `${theme.custom.pxToRem(12)}`,
  },
  buttonWrapper: {
    maxWidth: "100% !important",
    marginBottom: `${theme.spacing(6)}px  !important`,
  },
}));
