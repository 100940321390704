import React from "react";
import { Route, Redirect } from "react-router-dom";

const LoaderRoute = ({ component: Component, ...rest }) => {
  const lastUrl = localStorage.getItem("lastUrl");
  return (
    <Route
      {...rest}
      render={(props) => {
        //   continue rendering of loader if we hit this page from the login page otherwise return to main page
        if (lastUrl === "/login") {
          localStorage.removeItem("lastUrl");
          return <Component {...props} />;
        } else return <Redirect to="/" />;
        //last else prevents user to come back to this page and get stucked here and also prevents to go back to login page
      }}
    />
  );
};

export default LoaderRoute;
