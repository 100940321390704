import React from "react";
import Navbar from "shared_components/Navbar/Navbar";
import Carousel from "./components/Carousel/Carousel";
import BrowsingContent from "./components/BrowsingContent/BrowsingContent";
import FinishedRegCheck from "auth/FinishedRegCheck";

//MUI
import useStyles from "./styles";

const Home = () => {
  const classes = useStyles();
  return (
    <>
      <FinishedRegCheck />
      <Navbar />
      <main className={classes.main}>
        <Carousel />
        <BrowsingContent />
      </main>
    </>
  );
};

export default Home;
