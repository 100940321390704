import React from "react";
import Slider from "../Slider/Slider";
//MUI
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const Category = ({ showFundSwiperslide, category }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h2" className={classes.title}>
        {category.title || category.name}
      </Typography>
      <Slider showFundSwiperslide={showFundSwiperslide} category={category} />
    </div>
  );
};

export default Category;
