import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  itemsWrapper: {
    flex: 1,
    marginLeft: `${theme.custom.pxToRem(16)} !important`,
  },
  listItem: {
    background: ({ wasRead }) => {
      if (!wasRead) {
        return theme.custom.palette.unread;
      } else {
        return "inherit";
      }
    },
    "&:hover": {
      backgroundColor: theme.palette.grey_tone[100],
    },
    padding: `0 ${theme.custom.pxToRem(16)} !important`,
  },
  noFundImgWrapper: {
    height: `${theme.custom.pxToRem(40)}`,
    width: `${theme.custom.pxToRem(40)}`,
    borderRadius: `${theme.custom.pxToRem(4)}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey_tone[300],
  },
  timestamp: {
    color: theme.palette.grey_tone[400],
  },
  link: {
    textDecoration: "none",
  },
}));
