import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  main: {
    backgroundColor: palette.primary,
    height: "100%",
    paddingTop: "20vh",
  },
  logo: {
    position: "absolute",
    top: theme.custom.pxToRem(20),
    left: theme.custom.pxToRem(20),
  },
  page: {
    backgroundColor: palette.grey_tone[0],
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "12px 12px 0px 0px",
    paddingBottom: "64px !important",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.custom.pxToRem(8),
    paddingTop: theme.custom.pxToRem(48),
    paddingBottom: theme.custom.pxToRem(40),
    paddingLeft: theme.custom.pxToRem(64),
    paddingRight: theme.custom.pxToRem(64),
  },
  content: {
    paddingTop: theme.custom.pxToRem(48),
    paddingLeft: theme.custom.pxToRem(64),
    paddingRight: theme.custom.pxToRem(64),
  },
  summary: {
    paddingTop: theme.custom.pxToRem(8),
  },
  summaryItem: {
    marginTop: theme.custom.pxToRem(8),
  },
  number: {
    fontFamily: "ProximaNovaSemibold",
    fontWeight: 600,
  },
  paragraphItems: {
    marginTop: theme.custom.pxToRem(40),
  },
  paragraphHeader: {
    marginBottom: theme.custom.pxToRem(8),
  },
}));
