import React from "react";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import Dot from "assets/decoration/Dot.svg";
import Link from "shared_components/Link/Link";
import { VIDEO } from "navigation/constants";
import time from "video-time";
import moment from "moment";
import { numFormatter } from "shared_functions/shared_functions";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const VideoCard = ({
  snippet: {
    thumbnail_pic,
    title,
    video_guid,
    duration,
    time_stamp,
    views_count,
  },
}) => {
  const classes = useStyles();

  return (
    <Link to={`${VIDEO}/${video_guid}`} className={classes.link}>
      <div className={classes.card}>
        <div className={classes.thumbnailWrapper}>
          <img
            className={classes.picture}
            src={thumbnail_pic}
            alt="thumbnail"
          />
          <div className={classes.duration}>{time(duration)}</div>
        </div>
        <div className={classes.informationWrapper}>
          <Typography variant="h4">
            <CustomEllipsis lines={3} text={title} />
          </Typography>
          <div className={classes.labelsWrapper}>
            {/*TODO: put this back when we decide to display views count 
            <Typography variant="h5">
              <span className={classes.count}>{numFormatter(views_count)}</span>{" "}
              views
            </Typography>
            <img src={Dot} alt="dot" className={classes.dot} /> */}
            <Typography variant="h5">{moment(time_stamp).fromNow()}</Typography>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default VideoCard;
