import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  section: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.background.border}`,
    maxHeight: `${theme.custom.pxToRem(241)}`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      maxHeight: "unset",
    },
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(
      8
    )} !important`,
    margin: `-${theme.custom.pxToRem(16)} 0 0 -${theme.custom.pxToRem(8)}`,
  },
  leftSide: {
    width: "50%",
    padding: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(48)}`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(
        32
      )} ${theme.custom.pxToRem(8)}`,
      flex: "1",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: `0`,
    },
  },
  name: {
    marginTop: `${theme.custom.pxToRem(8)}`,
    marginBottom: `${theme.custom.pxToRem(8)}`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(0)}`,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(0)}`,
      padding: `${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(16)} 0`,
    },
  },
  underTitle: {
    display: "flex",
    gap: `${theme.custom.pxToRem(8)}`,
    marginBottom: `${theme.custom.pxToRem(8)}`,
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(16)}`,
    },
  },
  playlistLabel: {
    color: theme.palette.grey_tone["400"],
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: `${theme.custom.pxToRem(14)}`,
      fontWeight: `400`,
      lineHeight: `${theme.custom.pxToRem(16.8)}`,
      letterSpacing: `${theme.custom.pxToRem(0.18)}`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(16)} `,
    },
    "& .clamp-lines__button": {
      padding: `0`,
      border: "none",
      background: "none",
      cursor: "pointer",
      fontFamily: "ProximaNovaSemibold",
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: `${theme.custom.pxToRem(16)} !important`,
      lineHeight: `${theme.custom.pxToRem(24)} !important`,
      letterSpacing: `${theme.custom.pxToRem(0.18)} !important`,
      color: theme.palette.grey_tone["400"],
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.custom.pxToRem(14)} !important`,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: `${theme.custom.pxToRem(12)} !important`,
      },
    },
    "& .clamp-lines__button:hover": {
      textDecoration: "underline",
    },
  },
  rightSide: {
    width: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: `${theme.custom.pxToRem(256)}`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `100%`,
      height: `${theme.custom.pxToRem(136)}`,
    },
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: `0 ${theme.custom.pxToRem(24)}`,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: `${theme.custom.pxToRem(8)}`,
    },
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(8)}`,
    },
  },
  icon: {
    width: `${theme.custom.pxToRem(32)}`,
    height: `${theme.custom.pxToRem(32)}`,
  },
  count: {
    marginTop: `${theme.custom.pxToRem(8)}`,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  label: {
    fontWeight: "bold",
    color: theme.palette.grey_tone["400"],
  },
  pictureWrapper: {
    flex: 1,
    position: "relative",
  },
  picture: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  wave: {
    position: "absolute",
    left: -10,
    top: 0,
    zIndex: "100",
    height: "100%",
  },
  grid: {
    width: `calc(100% - ${theme.spacing(9)}px) !important`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.custom.pxToRem(48)}) !important`,
      paddingTop: `${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.custom.pxToRem(8)}) !important`,
      paddingTop: `${theme.spacing(2)}px`,
    },
    margin: `auto`,
    paddingTop: `${theme.spacing(6)}px`,
  },
  gridItem: {
    marginBottom: `${theme.custom.pxToRem(40)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
      padding: `0 ${theme.custom.pxToRem(8)} !important`,
    },
  },
}));
