import { isMobileOnly, isTablet } from "react-device-detect";
import { getToken } from "api/api";
import userTypes from "./userTypes";

export const getDataByIpAddress = async () => {
  const request = await fetch(
    `https://ipinfo.io/json?token=${process.env.REACT_APP_IP_TOKEN}`
  ); //50k lookups
  let data = extract(await request.json(), "ip", "city", "country", "timezone");
  if (isMobileOnly) data.device = "mobile";
  else if (isTablet) data.device = "tablet";
  else data.device = "desktop";

  data.language = navigator.language;
  return data;
};

const getAnonymToken = async () => {
  const {
    data: { token },
  } = await getToken();
  return token;
};

const extract = (obj, ...keys) => {
  const newObject = Object.assign({});
  Object.keys(obj).forEach((key) => {
    if (keys.includes(key)) {
      newObject[key] = obj[key];
      delete obj[key];
    }
  });
  return newObject;
};

export const constructAnonymousUserObj = async () => {
  const data = await getDataByIpAddress();
  data.access_token = await getAnonymToken();
  data.token_type = userTypes.anonymous;
  return data;
};
