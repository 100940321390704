import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  documentsGrid: {
    width: `100%`,
    margin: 0,
    padding: `0 ${theme.custom.pxToRem(36)} !important`,
    borderTop: `1px solid ${theme.palette.background.border} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.custom.pxToRem(20)} !important`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(4)} !important`,
    },
  },
  documentsTitle: {
    paddingBottom: `${theme.spacing(4)}px !important`,
    paddingTop: `${theme.spacing(8)}px !important`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.spacing(4)}px !important`,
    },
  },
  documentItem: {
    marginBottom: `${theme.custom.pxToRem(16)} !important`,
  },
  text: {
    wordBreak: "break-word",
  },
}));
