import React from "react";
import Typography from "@material-ui/core/Typography";

import checkmark from "assets/icons/icons/checkmark.svg";
import roundedCheckmark from "assets/icons/icons/rounded_checkmark.svg";
import useStyles from "./styles";

const ShowSubSteps = (
  activeSection,
  activeStep,
  setActiveStep,
  classes,
  i,
  steps
) => {
  if (activeSection === i) {
    return (
      <div>
        {RenderSubSteps(
          steps,
          classes,
          activeStep,
          setActiveStep,
          activeSection
        )}
      </div>
    );
  }
};

const RenderSubSteps = (
  steps,
  classes,
  activeStep,
  setActiveStep,
  activeSection
) => {
  return steps[activeSection].substeps.map((subStep, i) => {
    if (activeStep > i) {
      // Render completed steps
      return (
        <div className={classes.subStepWrapper} key={i}>
          <Typography variant="body2" onClick={() => setActiveStep(i)}>
            {subStep.label}
          </Typography>
          <img src={checkmark} alt="checkmark" />
        </div>
      );
    } else if (activeStep === i) {
      // Render current step
      return (
        <div className={classes.subStepWrapper} key={i}>
          <Typography
            variant="body2"
            className={classes.subStepActive}
            onClick={() => setActiveStep(i)}
          >
            {subStep.label}
          </Typography>
        </div>
      );
    }
    // Render remaining steps
    return (
      <div className={classes.subStepWrapper} key={i}>
        <Typography variant="body2" onClick={() => setActiveStep(i)}>
          {subStep.label}
        </Typography>
      </div>
    );
  });
};

const RenderActiveSection = (activeSection, classes, i) => {
  if (activeSection === i) {
    return (
      <div className={classes.activeSectionWrapper}>
        <div className={classes.activeSection} />
      </div>
    );
  }
};

// TODO: wire checkmarks and rounded checkmarks images up to Redux, so it shows checkmarks based
//on the information you have filled in, instead of how far you are in the progression bar.
// the component should receive a number
export default function Stepper({
  activeStep,
  setActiveStep,
  activeSection,
  setActiveSection,
  steps,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {steps.map((section, i) => {
          return (
            <div key={section.sectionLabel}>
              {RenderActiveSection(activeSection, classes, i)}
              <div
                className={classes.stepperSection}
                onClick={() => {
                  setActiveSection(i);
                  setActiveStep(0);
                }}
              >
                {/* render section completed */}
                {activeSection > i ? (
                  <img src={roundedCheckmark} alt="rounded Checkmark" />
                ) : (
                  <div className={classes.circle}></div>
                )}
                <div className={classes.sectionLabel}>
                  <Typography variant="body2">
                    {section.sectionLabel}
                  </Typography>
                </div>
              </div>
              <div className={classes.connector}>
                <div>
                  {ShowSubSteps(
                    activeSection,
                    activeStep,
                    setActiveStep,
                    classes,
                    i,
                    steps
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
