import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${palette.grey_tone[200]}`,
    backgroundColor: "white",
    borderRadius: `${theme.custom.pxToRem(12)}`,
    height: `${theme.custom.pxToRem(120)}`,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      height: `${theme.custom.pxToRem(78)}`,
    },
  },
  fundImage: {
    objectFit: "cover",
    width: `${theme.custom.pxToRem(135)} `,
    //dont delete this is fixed pic problem
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: `${theme.custom.pxToRem(127)} `,
    },
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(60)} `,
    },
  },
  textWrapper: {
    padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(24)}`,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)}`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)}`,
    },
  },
  fundTitleLink: {
    color: `${theme.palette.primary.main}`,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underLine",
    },
  },
  cardButtonSection: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    marginRight: `${theme.custom.pxToRem(32)}`,
    gap: `${theme.custom.pxToRem(32)}`,
    [theme.breakpoints.down("md")]: {
      gap: `${theme.custom.pxToRem(16)}`,
      marginRight: `${theme.custom.pxToRem(16)}`,
    },
    [theme.breakpoints.down("xs")]: {
      gap: `${theme.custom.pxToRem(8)}`,
      marginRight: `${theme.custom.pxToRem(8)}`,
    },
  },
  cardFundInfo: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  button: {
    minWidth: `${theme.custom.pxToRem(120)}`,
    [theme.breakpoints.down("xs")]: {
      minWidth: `${theme.custom.pxToRem(95)}`,
    },
  },
  reoderLink: {
    textDecoration: "none",
    display: "inline-block",
    width: "100%",
  },
}));
