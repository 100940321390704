import ProximaNovaLightFont from "../assets/fonts/Proxima_Nova_Font/Proxima_Nova_Light.woff2";
import ProximaNovaRegularFont from "../assets/fonts/Proxima_Nova_Font/Proxima_Nova_Regular.woff2";
import ProximaNovaSemiboldFont from "../assets/fonts/Proxima_Nova_Font/Proxima_Nova_Semibold.woff2";

export const proximaNovaLight = {
  fontFamily: "ProximaNovaLight", //name how I want to use it in the CSS
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
   local('Proxima_Nova'),
   local('Proxima_Nova_Light'),
   url(${ProximaNovaLightFont}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const proximaNovaRegular = {
  fontFamily: "ProximaNovaRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
   local('Proxima_Nova'),
   local('Proxima_Nova_Regular'),
   url(${ProximaNovaRegularFont}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const proximaNovaSemibold = {
  fontFamily: "ProximaNovaSemibold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
   local('Proxima_Nova'),
   local('Proxima_Nova_Semibold'),
   url(${ProximaNovaSemiboldFont}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
