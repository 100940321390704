import React from "react";
import ArrowBackIcon from "assets/icons/icons/arrow_back.svg";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import DocumentIcon from "assets/icons/icons/document.svg";
import { useHistory } from "react-router-dom";
// import Switch from "shared_components/InputComponents/SwitchStyled/SwitchStyled"; put this back in later versions when we solve goelocation of users
import Loader from "shared_components/Loader/Loader";
import AssetManagerList from "../../components/AssetManagerList/AssetManagerList";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
//REDUX
import { useSelector } from "react-redux";

const WhereToInvest = () => {
  const classes = useStyles();
  const history = useHistory();
  const fund = useSelector((state) => state.data.fund);

  const handleForwardToPreviousPage = () => {
    history.goBack();
  };

  return (
    <div className={classes.backGround}>
      {fund.is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={handleForwardToPreviousPage}
          >
            <img src={ArrowBackIcon} alt="arrow back" />
          </IconButton>
          {/* display text when fund has not set up where to invest data   */}
          {fund.investment_documents === null &&
            fund.contacts === null &&
            fund.brokers === null && (
              <Typography
                variant="h4"
                className={classes.applicationsDocsTitle}
              >
                The fund has not setup any options to invest in them yet.
              </Typography>
            )}
          {/* dont display this section when we do not have investment documents */}
          {fund.investment_documents !== null &&
            fund.investment_documents.length > 0 && (
              <>
                <Typography
                  variant="h2"
                  className={classes.applicationsDocsTitle}
                >
                  PDF Investment Applications
                </Typography>
                <Grid container className={classes.grid} spacing={3}>
                  {fund.investment_documents.map((document, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      lg={3}
                      key={index}
                      className={classes.gridItem}
                    >
                      <Card className={classes.card} data-cy="investmentCard">
                        <div>
                          <img
                            src={DocumentIcon}
                            alt="document"
                            className={classes.documentIcon}
                          />
                          <Typography
                            variant="h3"
                            align="center"
                            className={classes.docName}
                          >
                            {document.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            align="center"
                            className={classes.docDescription}
                          >
                            {document.description}
                          </Typography>
                        </div>

                        <a
                          href={document.url}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.docButton}
                        >
                          <Button variant="cta" size="sm" fullWidth>
                            Download
                          </Button>
                        </a>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

          {/* dont display this section when we do not have asset managers */}
          {fund.contacts !== null && fund.contacts.length > 0 && (
            <>
              <Typography variant="h2" className={classes.sectionTitle}>
                Contact Asset Manager
              </Typography>

              <AssetManagerList />
            </>
          )}
          {/* dont display this section when we do not have brokers */}
          {fund.brokers !== null && fund.brokers.length > 0 && (
            <>
              <Typography variant="h2" className={classes.sectionTitle}>
                Brokers
              </Typography>
              {/* Make this work after we will tlak about this show brokers based on country of residence
        <div className={classes.switchContainer}>
          <Typography variant="h4">show for all countries</Typography>
          <div className={classes.switch}>
            <Switch />
          </div>
        </div> */}
              <Grid container className={classes.grid} spacing={3}>
                {fund.brokers.map((broker) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      lg={3}
                      key={broker.id}
                      className={classes.gridItem}
                    >
                      <Card className={classes.card}>
                        <img
                          src={broker.broker_pic}
                          alt="broker"
                          className={classes.logo}
                        />
                        <Typography
                          variant="h3"
                          align="center"
                          className={classes.text}
                        >
                          {broker.name}
                        </Typography>
                        <Typography
                          variant="h4"
                          align="center"
                          className={classes.country}
                        >
                          {broker.country}
                        </Typography>
                        <div className={classes.buttonWrapper}>
                          <a
                            href={`https://${broker.website_link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button variant="cta" size="sm">
                              Go&#160;to&#160;website
                            </Button>
                          </a>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WhereToInvest;
