import React from "react";
//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import EventCard from "shared_components/EventCard/EventCard";

const EventsSection = ({ title, events, isDisabled }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </div>

      <Grid container spacing={3} className={classes.grid}>
        {events.length ? (
          events.map((event) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.gridItem}
              key={event.event_guid}
            >
              <EventCard event={event} isDisabled={isDisabled} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.emptyStateWrapper}>
            <Typography variant="h4" data-cy="noDocumentsText">
              There are no events available
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EventsSection;
