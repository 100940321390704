import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  iconButton: {
    padding: `${theme.custom.pxToRem(8)} !important`,
    border: `1px solid ${theme.custom.palette.primary} !important`,
    "&:hover": {
      background: alpha(`${theme.custom.palette.primary}`, 0.2),
    },
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.7)",
      "&:hover": {
        background: `${theme.palette.background.default}`,
      },
    },
  },
  iconButtonActive: {
    padding: `${theme.custom.pxToRem(8)}!important`,
    border: `1px solid ${theme.custom.palette.primary} `,
    backgroundColor: `${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: `${theme.palette.error.main}`,
      border: `1px solid ${theme.palette.error.main} `,
    },
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.7)",
      // fixes issue with hover
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
        border: `1px solid ${theme.custom.palette.primary} `,
      },
    },
  },
  label: {
    ...theme.custom.label,
    color: `${theme.palette.text.palette}`,
    marginTop: `${theme.custom.pxToRem(4)} !important`,
  },
}));
