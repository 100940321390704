import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "./styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    marginRight: 0,
  },
});

const SwitchStyled = ({ handleSwitchClick, checked, disabled }) => {
  const classes = useStyles();
  return (
    <FormGroup onClick={handleSwitchClick} defaultChecked={true}>
      <FormControlLabel
        classes={{ root: classes.root }}
        control={<CustomSwitch name="checkedA" />}
        checked={checked}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default SwitchStyled;
