import React from "react";
import BackButton from "shared_components/BackButton/BackButton";

//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import Alert from "shared_components/Alerts/Alert/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//REDUX
import { setNewCategoriesOrder } from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery("(max-width:599px)");
  const orderedCategories = useSelector(
    (state) => state.auth.user.ordered_categories.data
  );
  //saved popup state
  const [open, setOpen] = React.useState(false);

  const handleSaveNewOrder = () => {
    dispatch(setNewCategoriesOrder(orderedCategories));
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <div className={classes.navbar}>
        <BackButton className={classes.iconButton} />
        {isPhone && (
          <Button variant="success" size="lg" onClick={handleSaveNewOrder}>
            Save
          </Button>
        )}
      </div>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        className={classes.grid}
      >
        <Grid item xs={12} md={10} lg={8}>
          <Typography variant="h2" className={classes.title} align="center">
            Reorder Categories
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        className={classes.grid}
      >
        <Grid item xs={12} md={10} lg={8} className={classes.text}>
          <Typography variant={isPhone ? "body2" : "body1"} align="center">
            Choose in which order the categories should be shown in the video
            browser. <b>Drag and drop</b> to reorder the categories.
          </Typography>
        </Grid>
      </Grid>
      {!isPhone && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className={classes.grid}
        >
          <Grid item xs={8} sm={4} md={2} className={classes.buttonWrapper}>
            <Button
              variant="success"
              size="lg"
              fullWidth
              onClick={handleSaveNewOrder}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
      <Alert
        open={open}
        handleClose={handleClose}
        text="Your changes have been saved"
        severity="success"
        variant="filled"
      />
    </>
  );
};

export default Header;
