import React from "react";
import Link from "shared_components/Link/Link";
import {
  USER_SETTINGS_PROFILE,
  USER_SETTINGS_NOTIFICATIONS,
  FOLLOWING,
  WATCH_LATER,
  ROOT,
} from "navigation/constants";
import { investorTypesArray } from "auth/investorTypes";
// icons
import profileIcon from "assets/icons/icons/profile.svg";
import followIcon from "assets/icons/icons/add_follow.svg";
import mediaIcon from "assets/icons/icons/media.svg";
import bellIcon from "assets/icons/icons/bell.svg";
import settingsIcon from "assets/icons/icons/settings.svg";
import logoutIcon from "assets/icons/icons/logout.svg";
// MUI
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";
import Avatar from "@material-ui/core/Avatar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { logout } from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

//checking for ios to fix swipe action going back in navigation instead of closing drawer
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function NavMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery("(max-width:599px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const picture_url = useSelector((state) => state.auth.user.picture_url);
  const investor_type = useSelector((state) => state.auth.user.investor_type);
  const first_name = useSelector((state) => state.auth.user.first_name);
  const last_name = useSelector((state) => state.auth.user.last_name);

  // event handlers
  const handleLogout = (e) => {
    dispatch(logout());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (event, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const listItems = [
    {
      link: USER_SETTINGS_PROFILE,
      icon: profileIcon,
      text: "Profile Settings",
      handleClick: () => {},
    },
    {
      link: FOLLOWING,
      icon: followIcon,
      text: "Browse Funds",
      handleClick: () => {},
    },
    {
      link: WATCH_LATER,
      icon: mediaIcon,
      text: "Watch Later",
      handleClick: () => {},
    },
    {
      link: ROOT,
      icon: logoutIcon,
      text: "Logout",
      handleClick: handleLogout,
    },
  ];

  //if item has subutems, set item link to null
  const drawerItems = [
    {
      link: null,
      icon: settingsIcon,
      text: "Profile Settings",
      handleClick: (e) => {},
      subItems: [
        {
          link: USER_SETTINGS_PROFILE,
          icon: profileIcon,
          text: "Edit profile",
          handleClick: (e) => {
            toggleDrawer(e, false);
          },
        },
        {
          link: USER_SETTINGS_NOTIFICATIONS,
          icon: bellIcon,
          text: "Set Notifications",
          handleClick: (e) => {
            toggleDrawer(e, false);
          },
        },
      ],
    },
    {
      link: FOLLOWING,
      icon: followIcon,
      text: "Browse Funds",
      handleClick: (e) => {},
      subItems: [],
    },
    {
      link: WATCH_LATER,
      icon: mediaIcon,
      text: "Watch Later List",
      handleClick: (e) => {},
      subItems: [],
    },
  ];

  return (
    <>
      {isPhone ? (
        <>
          <span className={classes.avatarBorder}>
            <Avatar
              src={picture_url}
              className={classes.avatar}
              onClick={(e) => toggleDrawer(e, true)}
            />
          </span>
          <SwipeableDrawer
            // this is necessary to change swipe to left as going going back to closing the drawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            anchor={"right"}
            open={isDrawerOpen}
            onClose={(e) => toggleDrawer(e, false)}
            onOpen={(e) => toggleDrawer(e, true)}
            elevation={0}
            className={classes.drawer}
            classes={{ paper: classes.drawer }}
          >
            <section>
              <div className={classes.header}>
                <Avatar src={picture_url} className={classes.headerAvatar} />
                <div>
                  <Typography variant="h3">{`${first_name} ${last_name}`}</Typography>
                  <Typography variant="body1">
                    {investorTypesArray[investor_type]}
                  </Typography>
                </div>
              </div>
              <div
                role="presentation"
                className={classes.drawerMenuList}
                // onClick={(e) => toggleDrawer(e, false)}
                // onKeyDown={(e) => toggleDrawer(e, false)}
              >
                {drawerItems.map((item, index) => (
                  <span key={index}>
                    {item.link === null ? (
                      <MenuItem classes={{ root: classes.drawerItem }}>
                        <ListItemIcon classes={{ root: classes.drawerIcon }}>
                          <img src={item.icon} alt="icon" />
                        </ListItemIcon>
                        <Typography
                          variant="h3"
                          className={classes.listItemText}
                        >
                          {item.text}
                        </Typography>
                      </MenuItem>
                    ) : (
                      <Link
                        to={item.link}
                        className={classes.link}
                        onClick={item.handleClick}
                      >
                        <MenuItem classes={{ root: classes.drawerItem }}>
                          <ListItemIcon classes={{ root: classes.drawerIcon }}>
                            <img src={item.icon} alt="icon" />
                          </ListItemIcon>
                          <Typography
                            variant="h3"
                            className={classes.listItemText}
                          >
                            {item.text}
                          </Typography>
                        </MenuItem>
                      </Link>
                    )}

                    {item.subItems.map((subItem, index) => (
                      <span key={index}>
                        <Link
                          to={subItem.link}
                          className={classes.link}
                          onClick={subItem.handleClick}
                        >
                          <MenuItem classes={{ root: classes.drawerSubItem }}>
                            <ListItemIcon
                              classes={{ root: classes.drawerSubItemIcon }}
                            >
                              <img src={subItem.icon} alt="icon" />
                            </ListItemIcon>
                            <Typography
                              variant="body2"
                              className={classes.listItemText}
                            >
                              {subItem.text}
                            </Typography>
                          </MenuItem>
                        </Link>
                      </span>
                    ))}
                  </span>
                ))}
              </div>
            </section>
            <div>
              <MenuItem
                classes={{ root: classes.drawerItem }}
                onClick={handleLogout}
              >
                <ListItemIcon classes={{ root: classes.drawerIcon }}>
                  <img src={logoutIcon} alt="icon" />
                </ListItemIcon>
                <Typography variant="h3" className={classes.listItemText}>
                  {"Log out"}
                </Typography>
              </MenuItem>
            </div>
          </SwipeableDrawer>
        </>
      ) : (
        <>
          <span className={classes.avatarBorder}>
            <Avatar
              src={picture_url}
              className={classes.avatar}
              onClick={handleClick}
            />
          </span>
          <div>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={anchorEl}
              onClose={handleClose}
              open={Boolean(anchorEl)}
              classes={{ paper: classes.menuPaper, list: classes.menuList }}
            >
              {listItems.map((item, index) => (
                <span key={index}>
                  <Link
                    to={item.link}
                    className={classes.link}
                    onClick={item.handleClick}
                  >
                    <MenuItem classes={{ root: classes.menuItem }}>
                      <ListItemIcon>
                        <img src={item.icon} alt="icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        className={classes.listItemText}
                      />
                    </MenuItem>
                  </Link>
                </span>
              ))}
            </Menu>
          </div>
        </>
      )}
    </>
  );
}
