import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "-webkit-box",
    "-webkit-line-clamp": ({ lines }) => {
      return `${lines}`;
    },
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    padding: 0,
    margin: 0,
    wordBreak: "break-word",
  },
}));
