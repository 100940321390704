const data = {
  is_loading: true,
  id: 4,
  guid: 4,
  name: "HedgeNordic",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.",
  channel_pic:
    "https://alphafmc.com/wp-content/uploads/2021/03/iStock-1176089458-scaled.jpg",
  channel_logo:
    "https://hedgenordic.com/wp-content/uploads/2012/06/HedgeNordic_smaller-background.jpg",
  followers_count: 19514,
  is_user_following: false,
  videos: {
    is_loading: false,
    paging: {
      total: 1,
      page: 0,
      size: 1,
    },
    data: [
      {
        snippet: {
          id: 192,
          video_guid: "2799c0b5-d95b-415c-8f87-dad1bd663c2c",
          clip_id: 625420162,
          thumbnail_pic:
            "https://i.ytimg.com/vi/8oHcTfdrXKk/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAkfkBgY99Dp8mKIeWW4ZyhQsSgkQ",
          duration: 2319,
          views_count: 153,
          title: "Adrigo´s Search for Truly Nordic Hidden Gems",
          time_stamp: "2021-10-07T14:37:03.960626+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 191,
          video_guid: "2c0e0713-92b2-4249-ade5-eb60925720d4",
          clip_id: 625411896,
          thumbnail_pic:
            "https://i.ytimg.com/vi/tni7dQvQrmo/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBgJcrJPZmZiuRtSL235cCKvCx0yQ",
          duration: 2248,
          views_count: 20,
          title:
            "Like the Taiga: Robust, Sustainable and Long-Term Oriented Investing",
          time_stamp: "2021-10-07T14:31:39.12572+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 190,
          video_guid: "cd6a7500-4d49-47ab-b3c8-12a54c96d302",
          clip_id: 625407897,
          thumbnail_pic:
            "https://i.ytimg.com/vi/juJ_V3ToyP8/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDbpY7qBKcFghg3x_CMpvmju7kIYQ",
          duration: 3813,
          views_count: 3,
          title: "Accendo’s Collaborative, Value-Enhancing Approach",
          time_stamp: "2021-10-07T14:22:51.412897+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/eok5oGEC34A/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCf6fkRFQpQ52dd4mCiGSnH1fvWyQ",
          duration: 1830,
          views_count: 82,
          title: "Sector Zen’s “Huge Opportunity in Japan”",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/miQ45tB1BmQ/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAsfCU0jRJRB3HrQS_IFQ7SmUeu2A",
          duration: 1830,
          views_count: 82,
          title: "Managed Futures During the Covid Crisis and Beyond",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/7scddCbfQDc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAfuSpP6m8o5-vIxbdZx2PeJOFIjQ",
          duration: 1830,
          views_count: 82,
          title: "Managed Futures Round Table (Highlights)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/fxcOygNlF5E/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDnJy7z_hFOZQkOJSA-FWWkPNr24A",
          duration: 1830,
          views_count: 82,
          title: "Managed Futures Round Table (Full Session)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/3DaZE_vo4Sw/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC-CylchJGYRwLvfuujO38lZ5pTcA",
          duration: 1830,
          views_count: 82,
          title: "2020 Nordic Hedge Award: Best Nordic Hedge Fund - Overall",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/mLPyljpVu-g/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDYOOVhN8nmL0IpeJSs0-5MLxg4-g",
          duration: 1830,
          views_count: 82,
          title: "2020 Nordic Hedge Award: Best Nordic CTA",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/Fd0NOA0eyoM/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAaR9igKcbwxONQlAozo47ODH81dA",
          duration: 1830,
          views_count: 82,
          title: "2020 Nordic Hedge Award: Best Nordic Fixed Income Hedge Fund",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/vX22eLfM3zw/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB_qGtfFe8T_Md0LsWvEb_y7VDKBQ",
          duration: 1830,
          views_count: 82,
          title:
            "2020 Nordic Hedge Award: Best Nordic Multi Strategy Hedge Fund",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/tFnUuegFzdI/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCXx9luPDofrVeCr-CZ_fcyrCDcNg",
          duration: 1830,
          views_count: 82,
          title: "2020 Nordic Hedge Award: Best Nordic Equity Hedge Fund",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "HedgeNordic",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
    ],
  },
  details: {
    address: "Stockholm, Sweden",
    external_website_link: "https://hedgenordic.com/",
    joined: "2021-10-07T14:11:55.08658+00:00",
  },
};

export default data;
