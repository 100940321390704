import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: `${palette.grey_tone[200]}`,
    bottom: 0,
    position: "fixed",
    zIndex: 100,
  },
  wrapper: {
    marginLeft: `${theme.custom.pxToRem(250)} `,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: `${theme.custom.pxToRem(200)} `,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: `0 `,
    },
  },
  grid: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: `${theme.custom.pxToRem(20)} ${theme.custom.pxToRem(24)} `,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(8)} `,
      justifyContent: "space-between",
      width: "100%",
    },
  },
}));
