import React, { useEffect } from "react";
import Player from "@vimeo/player";
let player;

const Vimeo = ({ url, className }) => {
  useEffect(() => {
    const options = {
      url,
    };
    if (player) {
      // console.log("destroy was triggered");
      player.destroy();
      player = null;
    }
    player = new Player("vimeoPlayer", options);
    // player.loadVideo(url);
  }, [url]);
  return <div id="vimeoPlayer" className={className}></div>;
};

export default Vimeo;
