import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "hidden",
    display: "flex",
    border: ({ selected, isPhone }) => {
      if (selected && !isPhone)
        return `1px solid ${theme.palette.primary.main}`;
      else return `1px solid ${theme.palette.background.border}`;
    },
    height: `118px !important`,
    marginBottom: `${theme.custom.pxToRem(8)} !important`,
    cursor: "pointer",
    boxShadow: ({ selected, isPhone }) => {
      if (selected && !isPhone) return `${theme.custom.palette.shadows.lvl1}`;
      else return `none`;
    },
    [theme.breakpoints.down("xs")]: {
      height: `88px !important`,
    },
  },
  thumbnail: {
    width: `100% !important`,
    height: `100% !important`,
    filter: `drop-shadow(${theme.shadows[2]})`,
    objectFit: "cover",
    "&:hover": {
      filter: `drop-shadow(${theme.custom.palette.shadows.lvl3})`,
    },
  },
  thumbnailWrapper: {
    position: "relative",
    flex: "1",
  },
  duration: {
    position: "absolute",
    bottom: `${theme.spacing(2)}px !important`,
    right: `${theme.spacing(2)}px !important`,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    padding: `${theme.custom.pxToRem(4)} ${theme.custom.pxToRem(8)} !important`,
    borderRadius: `${theme.custom.pxToRem(3)} !important`,
    [theme.breakpoints.down("xs")]: {
      bottom: `${theme.custom.pxToRem(6)} !important`,
      right: `${theme.custom.pxToRem(6)} !important`,
      padding: `${theme.custom.pxToRem(0.8)} ${theme.custom.pxToRem(
        3.2
      )} !important`,
    },
  },
  dataWrapper: {
    width: `calc(67% - 50px) !important`,
    background: `white`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)} 0 ${theme.custom.pxToRem(
        8
      )} ${theme.custom.pxToRem(8)} !important`,
      justifyContent: "center",
    },
    padding: `${theme.custom.pxToRem(24)} 0 ${theme.custom.pxToRem(
      22
    )} ${theme.custom.pxToRem(16)} !important`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    textDecoration: "none",
    color: "inherit",
  },
  infoWrapper: {
    marginTop: `${theme.custom.pxToRem(8)} !important`,
    display: "flex",
    alignItems: "center",
  },
  dot: {
    margin: `0 ${theme.custom.pxToRem(4)} !important`,
  },
  label: {
    ...theme.custom.label,
  },
  fundLink: {
    ...theme.custom.link,
    color: `${theme.palette.primary.main}`,
  },
  link: {
    ...theme.custom.link,
  },
  iconWrapper: {
    background: `white`,
    padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
      4
    )} 0 0 !important`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(12)} 0 0 0 !important`,
    },
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(6)} !important`,
    },
  },
  popover: {
    border: `1px solid ${theme.palette.background.border}`,
    filter: `drop-shadow(${theme.custom.palette.shadows.lvl3})`,
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "visible",
  },
  popoverBodyWrapper: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.custom.pxToRem(16)} !important`,
    background: `${theme.palette.background.default}`,
    position: "relative",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    cursor: "pointer",
  },
  popoverText: {
    height: "fit-content",
    marginLeft: `${theme.custom.pxToRem(16)} !important`,
  },
  square: {
    height: "16px",
    width: "16px",
    borderTop: `1px solid ${theme.palette.background.border}`,
    borderLeft: `1px solid ${theme.palette.background.border}`,
    position: "absolute",
    top: `-8px`,
    right: `18px`,
    background: `${theme.palette.background.default}`,
    transform: `rotate(45deg)`,
  },
}));
