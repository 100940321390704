import React from "react";
import MainSection from "./components/MainSection/MainSection";
import FooterSection from "./components/FooterSection/FooterSection";
import Navbar from "shared_components/Navbar/Navbar";

const Login = () => {
  return (
    <>
      {/*here can be wrapper to not allow endless stretching of the grid*/}
      <Navbar />
      <MainSection />
      <FooterSection />
    </>
  );
};

export default Login;
