import React from "react";
import { Switch, Route } from "react-router-dom";
import { COMPANY, ABOUT, FUNDS, VIDEOS, EVENTS } from "navigation/constants";
import Funds from "../../subpages/Funds/Funds";
import About from "../../subpages/About/About";
import Playlists from "../../subpages/Playlists/Playlists";
import Events from "../../subpages/Events/Events";

const Navigation = () => {
  return (
    <Switch>
      <Route exact path={`${COMPANY}/:companyGuid${FUNDS}`} component={Funds} />
      <Route exact path={`${COMPANY}/:companyGuid${ABOUT}`} component={About} />
      <Route
        exact
        path={`${COMPANY}/:companyGuid${EVENTS}`}
        component={Events}
      />
      <Route
        path={`${COMPANY}/:companyGuid${VIDEOS}/:playlistGuid`}
        component={Playlists}
      />
      <Route path={`${COMPANY}/:companyGuid${VIDEOS}`} component={Playlists} />
    </Switch>
  );
};

export default Navigation;
