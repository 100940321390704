import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  title: {
    margin: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(
      48
    )} ${theme.custom.pxToRem(8)}`,
    [theme.breakpoints.down("sm")]: {
      margin: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(
        32
      )} ${theme.custom.pxToRem(8)}`,
    },
    [theme.breakpoints.down("xs")]: {
      margin: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(
        16
      )} ${theme.custom.pxToRem(8)}`,
    },
  },
  wrapper: {
    borderBottom: `${theme.custom.pxToRem(1)} solid ${
      theme.palette.background.border
    }!important`,
  },
}));
