import Link from "shared_components/Link/Link";
import { FUND, VIDEOS } from "navigation/constants";
// MUI components
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Custom components
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
//REDUX
import { followFund, unfollowFund } from "redux/ducks/funds";

const FollowingCard = ({
  fundTitle,
  fundGuid,
  imgURL,
  description,
  isFollowing,
  cyId,
}) => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:959px)");
  const isPhone = useMediaQuery("(max-width:599px)");

  return (
    <div className={classes.cardContainer} data-cy={cyId}>
      <div className={classes.cardFundInfo}>
        {/* dont delete this it fixed image problem */}
        <div>
          <img src={imgURL} alt="fund" className={classes.fundImage} />
        </div>
        <div className={classes.textWrapper}>
          <Link
            to={`${FUND}/${fundGuid}${VIDEOS}`}
            className={classes.fundTitleLink}
          >
            <Typography variant="h3" component="div">
              <CustomEllipsis lines={isPhone ? 1 : 2} text={fundTitle} />
            </Typography>
          </Link>
          <Typography variant="body2" component="div">
            <CustomEllipsis lines={isPhone ? 2 : 3} text={description} />
          </Typography>
        </div>
      </div>
      <div className={classes.cardButtonSection}>
        {/* only display if we have bigger screens than tablet */}
        {!isTablet && (
          <>
            <Divider orientation="vertical" flexItem />
            <div className={classes.button}>
              <Link
                to={`${FUND}/${fundGuid}${VIDEOS}`}
                className={classes.reoderLink}
              >
                <ButtonStyled variant="cta" size="sm" fullWidth>
                  <nobr>See Fund</nobr>
                </ButtonStyled>
              </Link>
            </div>
          </>
        )}

        <Divider orientation="vertical" flexItem />
        <div className={classes.button}>
          <FollowButton
            guid={fundGuid}
            isFollowing={isFollowing}
            onFollow={followFund}
            onUnfollow={unfollowFund}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowingCard;
