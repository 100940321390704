import { createTheme } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import {
  proximaNovaLight,
  proximaNovaSemibold,
  proximaNovaRegular,
} from "./fonts";
import palette from "./palette";

//constants
const VERTICAL_GRADIENT = `linear-gradient(to top, ${palette.primary}, ${palette.secondary})`;
const HORIZONTAL_GRADIENT = `linear-gradient(to left, ${palette.primary}, ${palette.secondary})`;
const DEFAULT_THEME = createTheme(); //needed for the refernce of the breakpoints
//functions
const pxToRem = (size, context = 16) => `${size / context}rem`;

export default createTheme({
  overrides: {
    //for overwritting the global MUI class properties
    MuiCssBaseline: {
      "@global": {
        html: {
          minHeight: "100vh",
          backgroundColor: palette.grey_tone["100"],
        }, //can be used to update with css something globally
        "@font-face": [
          proximaNovaRegular,
          proximaNovaLight,
          proximaNovaSemibold,
        ],
      },
    },
    //Divder
    MuiDivider: {
      root: {
        backgroundColor: `${palette.grey_tone["200"]}`,
      },
    },
    //Buttons
    MuiButton: {
      text: {
        color: `${palette.text_bright}`,
      },
      root: {
        borderRadius: 100,
        height: pxToRem(40),
        "&:hover": {
          filter: `drop-shadow(${palette.shadows.lvl2})`,
        },
        "&:disabled": {
          background: `${palette.grey_tone["300"]}`,
          color: `${palette.text_bright}`,
        },
      },
    },

    //menu pop-up
    MuiMenu: {
      paper: {
        boxShadow: `${palette.shadows.lvl3}`,
        border: `1px solid ${palette.grey_tone[300]}`,
        borderRadius: 12,
      },
    },
    //forms
    MuiOutlinedInput: {
      root: {
        borderRadius: 12,
        backgroundColor: palette.grey_tone[0],
        "&$focused $notchedOutline": {
          borderColor: palette.primary,
          borderWidth: 1,
        },
        "&$disabled": {
          backgroundColor: palette.grey_tone[200],
        },
      },
      notchedOutline: {
        borderWidth: 1,
        borderColor: palette.grey_tone[400],
      },
      input: {
        padding: 14,
      },
    },
    MuiInputLabel: {
      root: {
        color: palette.font,
        "&$focused": {
          color: palette.font,
          fontFamily: "ProximaNovaSemibold",
          fontWeight: 600,
        },
      },
      shrink: { lineHeight: "100%" },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: `${palette.grey_tone[0]}`,
          borderRadius: 12,
        },
      },
    },
    MuiAutocomplete: {
      paper: {
        boxShadow: `${palette.shadows.lvl3}`,
        borderRadius: 12,
        border: `1px solid ${palette.grey_tone[300]}`,
      },
    },
    MuiGrid: {
      item: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
    },

    MuiCard: {
      root: {
        borderRadius: `${pxToRem(12)} !important`,
        filter: `drop-shadow(${palette.shadows.lvl1})`,
      },
    },
    //fixes elevation problem
    MuiPaper: {
      elevation0: {},
      elevation8: {},
      elevation24: {},
    },

    MuiStepLabel: {
      label: {
        color: `${palette.text_dark}`,
      },
      completed: {
        color: `${palette.success} !important`,
      },
    },

    MuiStepIcon: {
      completed: {
        color: `${palette.success} !important`,
      },
    },

    MuiChip: {
      root: {
        height: pxToRem(24),
        backgroundColor: `${palette.grey_tone[200]}`,
        color: `${palette.text_dark}`,
        lineHeight: pxToRem(16),
        fontWeight: 600,
        fontSize: pxToRem(16),
        letterSpacing: pxToRem(0.25),
        fontFamily: "ProximaNovaSemiBold",
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8),
      },
      label: {
        paddingLeft: pxToRem(0),
        paddingRight: pxToRem(0),
      },
    },

    MuiTypography: {
      h1: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(48),
          lineHeight: pxToRem(56),
          letterSpacing: pxToRem(0.6),
        },
      },
      h2: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(30),
          lineHeight: pxToRem(40),
          letterSpacing: pxToRem(0.6),
        },
      },
      h3: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(20),
          lineHeight: pxToRem(24),
          letterSpacing: pxToRem(0.33),
        },
      },
      h4: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          letterSpacing: pxToRem(0.33),
        },
      },
      body1: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          letterSpacing: pxToRem(0.18),
        },
      },
      body2: {
        [DEFAULT_THEME.breakpoints.up("md")]: {
          fontSize: pxToRem(12),
          lineHeight: pxToRem(16),
          letterSpacing: pxToRem(0.36),
        },
      },
    },
  },
  //for overwritting the global MUI theme
  palette: {
    primary: {
      main: `${palette.primary}`,
      transparent: alpha(`${palette.primary}`, 0.2),
    },
    secondary: {
      main: `${palette.secondary}`,
    },
    accent: {
      main: `${palette.accent}`,
    },
    error: {
      main: `${palette.error}`,
      light: alpha("#FF0000", 0.2),
    },
    success: {
      main: `${palette.success}`,
    },
    highlight: {
      main: `${palette.highlight}`,
    },
    grey_tone: { ...palette.grey_tone },
    background: {
      default: `${palette.text_bright}`,
      secondary: `${palette.grey_tone["100"]}`,
      border: `${palette.grey_tone["200"]}`,
      vertical_gradient: VERTICAL_GRADIENT,
      horizontal_gradient: HORIZONTAL_GRADIENT,
    },
    text: {
      primary: `${palette.text_dark}`,
      secondary: `${palette.text_bright}`,
    },
  },
  typography: {
    fontFamily:
      "ProximaNovaRegular,ProximaNovaLight,ProximaNovaSemibold, Arial",
    button: {
      textTransform: "none",
    },
    //styles here are applyed as mobile-first design
    h1: {
      fontFamily: "ProximaNovaSemibold",
      fontWeight: 600,
      fontSize: pxToRem(30),
      lineHeight: pxToRem(40),
      letterSpacing: pxToRem(0.6),
    },
    h2: {
      fontFamily: "ProximaNovaSemibold",
      fontWeight: 600,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.33),
    },
    h3: {
      fontFamily: "ProximaNovaSemibold",
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.33),
    },
    h4: {
      fontFamily: "ProximaNovaSemibold",
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(19.6),
      letterSpacing: pxToRem(0.18),
    },
    body1: {
      fontFamily: "ProximaNovaLight",
      fontWeight: 300,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.18),
    },
    body2: {
      fontFamily: "ProximaNovaRegular",
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16.8),
      letterSpacing: pxToRem(0.18),
    },
    h5: {
      fontFamily: "ProximaNovaSemibold",
      fontWeight: 600,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16.8),
      letterSpacing: pxToRem(0.18),
    },
  },
  shadows: [
    "none",
    palette.shadows.lvl0,
    palette.shadows.lvl1,
    palette.shadows.lvl2,
    palette.shadows.lvl3,
  ],
  // the custom is spread in components to provide united behaviour
  custom: {
    smallButton: {
      padding: `${pxToRem(4)} ${pxToRem(8)} !important`,
      fontSize: `${pxToRem(16)} !important`,
      fontStyle: "normal !important",
      fontFamily: "ProximaNovaSemibold",
      lineHeight: `${pxToRem(19)} !important`,
      letterSpacing: `${pxToRem(0.58)} !important`,
      borderRadius: `${pxToRem(100)} !important`,
      height: `${pxToRem(32)} !important`,
    },
    largeButton: {
      padding: `${pxToRem(8)} ${pxToRem(26.5)} !important`,
      fontSize: `${pxToRem(16)} !important`,
      fontStyle: "normal !important",
      fontFamily: "ProximaNovaSemibold",
      lineHeight: `${pxToRem(24)} !important`,
      letterSpacing: `${pxToRem(0.33)} !important`,
      borderRadius: `${pxToRem(100)} !important`,
      height: `${pxToRem(40)} !important`,
    },
    pxToRem,
    link: {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    label: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: `${pxToRem(12)} !important`,
      lineHeight: `${pxToRem(16)} !important`,
      letterSpacing: `${pxToRem(0.250909)} !important`,
      color: `${palette.grey_tone["400"]} !important`,
    },
    palette,
  },
});
