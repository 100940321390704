import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    backgroundColor: `${theme.palette.background.default}`,
    paddingTop: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(48)} !important`,
    },
    minHeight: "100vh",
  },
  wrapper: {
    width: `calc(100% - ${theme.spacing(12)}px) !important`,
    margin: "auto",
  },
  grid: {
    width: "100% !important",
  },
  pageTitleWrapper: {
    paddingLeft: `${theme.spacing(6)}px !important`,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: `${theme.spacing(2)}px !important`,
    },
    borderBottom: `1px solid ${theme.palette.background.border}`,
  },
  pageTitle: {
    padding: `${theme.custom.pxToRem(48)} 0  !important`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(16)} 0  !important`,
    },
  },
  section: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
}));
