import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  thumbnail: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    filter: `drop-shadow(${theme.shadows[2]})`,
    objectFit: "cover",
    "&:hover": {
      filter: `drop-shadow(${theme.custom.palette.shadows.lvl3})`,
    },
  },
  thumbnailWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
  },
  duration: {
    position: "absolute",
    bottom: `${theme.spacing(2)}px !important`,
    right: `${theme.spacing(2)}px !important`,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    padding: `${theme.custom.pxToRem(4)} ${theme.custom.pxToRem(8)} !important`,
    borderRadius: `${theme.custom.pxToRem(3)} !important`,
    [theme.breakpoints.down("sm")]: {
      bottom: `${theme.custom.pxToRem(6)} !important`,
      right: `${theme.custom.pxToRem(6)} !important`,
      padding: `${theme.custom.pxToRem(0.8)} ${theme.custom.pxToRem(
        3.2
      )} !important`,
    },
  },
  title: {
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    [theme.breakpoints.down("xs")]: {
      marginTop: `${theme.custom.pxToRem(8)} !important`,
    },
  },
  infoWrapper: {
    margin: `${theme.custom.pxToRem(4)} 0 !important`,
    display: "flex",
    alignItems: "center",
  },
  dot: {
    margin: `0 ${theme.custom.pxToRem(4)} !important`,
  },
  label: {
    ...theme.custom.label,
  },
  fundLink: {
    ...theme.custom.link,
    color: `${theme.palette.primary.main}`,
  },
  link: {
    ...theme.custom.link,
  },
}));
