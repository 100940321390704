import React, { useEffect } from "react";
import useStyles from "./styles";
// Assets
import profileBadge from "assets/icons/investorOnboarding/profileBadge.svg";
import countryList from "assets/data/countryList";
// MUI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const CountryPage = ({ country, handleCountryChange }) => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");

  useEffect(() => {
    if (country !== null) {
    }
  }, [country]);

  return (
    <div>
      <img src={profileBadge} alt="icon badge" className={classes.badge} />
      <Grid container>
        <Grid item xs={12} md={8} lg={6}>
          <Typography
            variant={isPhone ? "h3" : "h2"}
            className={classes.header}
          >
            Country
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.text}>
        <Grid item xs={12} md={8} lg={6}>
          <Typography variant="body1">
            Select your country of residence. This information is used to
            customize your experience.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={4} lg={4} className={classes.form}>
          {/* Insert forms here */}
          <Autocomplete
            id="country-select"
            autoHighlight
            value={country}
            onChange={(e, value) => handleCountryChange(value)}
            options={countryList}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoComplete="new-password"
                  variant="outlined"
                  label="Country of Residence"
                  id="input"
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CountryPage;
