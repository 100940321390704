import React from "react";
import clsx from "clsx"; //added for combinating classes

//MUI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import SwitchStyled from "shared_components/InputComponents/SwitchStyled/SwitchStyled";
import useStyles from "./styles";

const Test = () => {
  const classes = useStyles();
  return (
    <>
      <ButtonStyled variant="success" size="lg">
        Button Label
      </ButtonStyled>
      <Button className={clsx(classes.smallButton)}>Button&#160;Label</Button>
      <Button className={clsx(classes.largeButton)}>Button&#160;Label</Button>
      <Typography variant={"h1"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <Typography variant={"h2"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <Typography variant={"h3"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <Typography variant={"h4"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <Typography variant={"body1"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <Typography variant={"body2"} gutterBottom>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae
        obcaecati unde optio quis. Provident id eaque, totam nemo reiciendis aut
        eum aliquam neque quae placeat debitis quasi quos culpa exercitationem!
      </Typography>
      <SwitchStyled></SwitchStyled>
      <img
        className={classes.image}
        src="https://explorer.coherra.com:5023/companies/17/10/40009e72-b20c-4962-9e70-953b0e19182c.png"
      ></img>
      <img
        className={classes.image}
        src="https://explorer.coherra.com:5023/companies/9/10/63e817d5-cc89-4bda-b7d7-32a4602b9e9a.png"
      ></img>
      <img
        className={classes.image}
        src="https://explorer.coherra.com:5023/companies/10/10/45f4e699-d55f-427b-8e5f-f9347e7f53f6.png"
      ></img>
      <img
        className={classes.image}
        src="https://explorer.coherra.com:5023/companies/16/10/f3eaf28d-0ede-4023-a227-43cc446492f6.png"
      ></img>
      <img
        className={classes.image}
        src="https://explorer.coherra.com:5023/companies/25/10/da78d4ee-b87c-44db-a260-556fb21e0750.jpg"
      ></img>
    </>
  );
};

export default Test;

//template for accessing Vimeo SDK manually
// import Player from "@vimeo/player";
//
// videoPlayer = new Player(videoPlayerRef.current, {
//   //vimeo options
//   //use either id or url
//   id: "169599296",
//   url: "https://vimeo.com/519590797/d4601029fa",
// });
