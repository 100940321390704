export const investorTypes = {
  RETAIL_INVESTOR: 1,
  QUALIFIED_INVESTOR: 2,
  INSTITUTIONAL_INVESTOR: 3,
};

export const investorTypesArray = [
  "",
  "Retail Investor",
  "Qualified Investor",
  "Institutional Investor",
];
