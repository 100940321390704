import React from "react";
import Loader from "shared_components/Loader/Loader";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import AssetManagerList from "../../components/AssetManagerList/AssetManagerList";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
//REDUX
import { useSelector } from "react-redux";

const Contact = () => {
  const classes = useStyles();
  const is_loading = useSelector((state) => state.data.fund.is_loading);
  const contacts = useSelector((state) => state.data.fund.contacts);

  return (
    <div className={classes.backGround}>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <section className={classes.section}>
            <Typography variant="h2" className={classes.title}>
              General Info
            </Typography>
          </section>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} md={5}>
              <DetailsCard />
            </Grid>
          </Grid>
          {contacts !== null && contacts.length !== 0 && (
            <>
              <section className={classes.section}>
                <Typography variant="h2" className={classes.title}>
                  Contact Asset Manager
                </Typography>
              </section>
            </>
          )}

          <AssetManagerList />
        </>
      )}
    </div>
  );
};

export default Contact;
