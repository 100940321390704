import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";
import realVisionData from "../mocks/video_channels/realVision";
import asharqData from "../mocks/video_channels/asharq";
import topGainData from "../mocks/video_channels/topgain";
import hedgeNordicData from "../mocks/video_channels/hedgenordic";

//action types
const SET_VIDEO_CHANNEL = "videoChannel/SET_VIDEO_CHANNEL";
const SET_VIDEO_CHANNEL_VIDEOS = "videoChannel/SET_VIDEO_CHANNEL_VIDEOS";
const SET_MORE_VIDEO_CHANNEL_VIDEOS =
  "videoChannel/SET_MORE_VIDEO_CHANNEL_VIDEOS";
const START_LOADING_VIDEO_CHANNEL = "videoChannel/START_LOADING_VIDEO_CHANNEL";
const STOP_LOADING_VIDEO_CHANNEL = "videoChannel/STOP_LOADING_VIDEO_CHANNEL";
const START_LOADING_VIDEOS = "videoChannel/START_LOADING_VIDEOS";
const STOP_LOADING_VIDEOS = "videoChannel/STOP_LOADING_VIDEOS";
export const FOLLOW_VIDEO_CHANNEL = "videoChannel/FOLLOW_VIDEO_CHANNEL";
export const UNFOLLOW_VIDEO_CHANNEL = "videoChannel/UNFOLLOW_VIDEO_CHANNEL";

const initialState = {
  is_loading: true,
  id: null,
  guid: null,
  name: null,
  channel_pic: null,
  channel_logo: null,
  followers_count: null,
  is_user_following: false,
  videos: {
    is_loading: true,
    paging: {},
    data: [],
  },
  details: {
    address: null,
    external_website_link: null,
    joined: null,
  },
};

//TODO:rename functions names and errors and the api calls

export const getVideoChannel = (video_channel_guid) => async (dispatch) => {
  try {
    dispatch(startLoadingVideoChannel());
    // let { data } = await api.getFund(fund_guid);
    //test data
    let data;
    switch (video_channel_guid) {
      case "1": {
        data = realVisionData;
        break;
      }
      case "2": {
        data = asharqData;
        break;
      }
      case "3": {
        data = topGainData;
        break;
      }
      case "4": {
        data = hedgeNordicData;
        break;
      }
      default: {
        break;
      }
    }
    dispatch({ type: SET_VIDEO_CHANNEL, payload: data });
    dispatch(stopLoadingVideoChannel());
    // const PAGE = 0;
    // const SIZE = 12;
    // dispatch(getFundVideos(data.fund_guid, PAGE, SIZE));
  } catch (error) {
    constructAndSendError(
      error.message,
      "video_channel/getVideoChannel",
      "/fund2",
      [`video_channel_guid:${video_channel_guid}`]
    );
    console.log(error);
  }
};

export const getFundVideos = (fund_guid, page, size) => async (dispatch) => {
  try {
    dispatch(startLoadingVideos());
    const { data } = await api.getFundVideos(fund_guid, page, size);
    dispatch({ type: SET_VIDEO_CHANNEL_VIDEOS, payload: data });
    dispatch(stopLoadingVideos());
  } catch (error) {
    constructAndSendError(
      error.message,
      "fund/getFundVideos",
      "/fund2/videos",
      [`fund_guid:${fund_guid}`, `page:${page}`, `size:${size}`]
    );
    console.log(error);
  }
};

export const getMoreFundVideos =
  (fund_guid, page, size) => async (dispatch) => {
    try {
      const { data } = await api.getFundVideos(fund_guid, page, size);
      dispatch({ type: SET_MORE_VIDEO_CHANNEL_VIDEOS, payload: data });
    } catch (error) {
      constructAndSendError(
        error.message,
        "fund/getMoreFundVideos",
        "/fund2/videos",
        [`fund_guid:${fund_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const followVideoChannel = (video_channel_guid) => async (dispatch) => {
  try {
    // await api.followFund(fund_guid);
    dispatch({ type: FOLLOW_VIDEO_CHANNEL });
    //necessary to keep redux > followed_VIDEO_CHANNELs in sink
    // dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(error.message, "fund/followFund", "/fund2/follow", [
      `video_channel_guid:${video_channel_guid}`,
    ]);
    console.log(error);
  }
};

export const unfollowVideoChannel =
  (video_channel_guid) => async (dispatch) => {
    try {
      // await api.unfollowFund(fund_guid);
      dispatch({ type: UNFOLLOW_VIDEO_CHANNEL });
      //necessary to keep redux > followed_VIDEO_CHANNELs in sink
      // dispatch(getFollowedFunds());
    } catch (error) {
      constructAndSendError(
        error.message,
        "fund/unfollowFund",
        "/fund2/unfollow",
        [`video_channel_guid:${video_channel_guid}`]
      );
      console.log(error);
    }
  };

export const startLoadingVideoChannel = () => (dispatch) => {
  dispatch({ type: START_LOADING_VIDEO_CHANNEL });
};

export const stopLoadingVideoChannel = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_VIDEO_CHANNEL });
};

export const startLoadingVideos = () => (dispatch) => {
  dispatch({ type: START_LOADING_VIDEOS });
};

export const stopLoadingVideos = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_VIDEOS });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_CHANNEL: {
      return {
        ...action.payload,
      };
    }
    case SET_VIDEO_CHANNEL_VIDEOS: {
      return {
        ...state,
        videos: {
          paging: action.payload.paging,
          data: action.payload.videos,
          is_loading: state.videos.is_loading,
        },
      };
    }
    case SET_MORE_VIDEO_CHANNEL_VIDEOS: {
      return {
        ...state,
        videos: {
          paging: action.payload.paging,
          data: [...state.videos.data, ...action.payload.videos],
          is_loading: state.videos.is_loading,
        },
      };
    }
    case FOLLOW_VIDEO_CHANNEL: {
      return { ...state, is_user_following: true };
    }
    case UNFOLLOW_VIDEO_CHANNEL: {
      return { ...state, is_user_following: false };
    }
    case START_LOADING_VIDEO_CHANNEL: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING_VIDEO_CHANNEL: {
      return { ...state, is_loading: false };
    }
    case START_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: true } };
    }
    case STOP_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: false } };
    }
    default:
      return state;
  }
};

export default reducer;
