import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  options: {
    borderBottom: `${theme.custom.pxToRem(1)} solid ${theme.palette.background.border
      }!important`,
    borderTop: `${theme.custom.pxToRem(1)} solid ${theme.palette.background.border
      }!important`,
  },
  wrapper: {
    width: `calc(100% - ${theme.spacing(12)}px) !important`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.custom.pxToRem(32)} ${theme.custom.pxToRem(0)} `,
    margin: "auto !important",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(8)}px)  !important`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.spacing(4)}px)  !important`,
      padding: `${theme.custom.pxToRem(12)} 0 `,
    },
  },
  grid: {
    padding: `${theme.custom.pxToRem(0)} !important`,
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchText: {
    paddingLeft: 16,
  },
  reoderLink: {
    textDecoration: "none",
  },
  rearrangeButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "fit-content",
    marginLeft: "auto",
    color: theme.palette.text.primary,
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(8)} !important`,
  },
  iconButtonDisabled: {
    opacity: "0.6",
  },
  exploreFundsWrapper: {
    marginTop: `${theme.custom.pxToRem(105)} !important`,
    paddingBottom: `${theme.custom.pxToRem(105)} !important`,
    display: "flex",
    justifyContent: "center",
  },
  itemsWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  exploreFundsText: {
    marginTop: `${theme.custom.pxToRem(32)} !important`,
  },
  exploreFundsButton: {
    marginTop: `${theme.custom.pxToRem(24)} !important`,
  },
}));
