import React from "react";
//MUI
import useStyles from "./styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const Alert = ({
  open,
  handleClose,
  text,
  severity,
  variant,
  autoHideDuration,
  children,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 5000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={3}
        variant={variant}
        onClose={handleClose}
        severity={severity}
        className={classes.alert}
      >
        <Typography variant="body1">{text}</Typography>
        {children}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
