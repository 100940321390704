import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette.js";

export default makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    position: "relative",
  },
  activeSectionWrapper: {
    position: "relative",
  },
  activeSection: {
    backgroundColor: `${palette.grey_tone[200]}`,
    position: "absolute",
    zIndex: -1,
    height: 56,
    width: `${theme.custom.pxToRem(250)} `,
    marginLeft: -48,
    marginTop: -16,
    [theme.breakpoints.down("sm")]: {
      width: `${theme.custom.pxToRem(216)} `,
    },
  },
  container: {
    position: "relative",
    marginLeft: `${theme.custom.pxToRem(48)}`,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: `${theme.custom.pxToRem(32)}`,
    },
  },
  stepperSection: {
    display: "flex",
    flexDirection: "row",
  },
  sectionLabel: {
    marginTop: 8,
    marginLeft: 8,
    height: 16,
  },
  circle: {
    border: `1px solid ${palette.primary}`,
    borderRadius: 100,
    height: 24,
    width: 24,
  },
  connector: {
    borderLeft: `1px solid ${palette.grey_tone[300]}`,
    marginLeft: 11,
    paddingTop: 28,
  },
  subStepWrapper: {
    marginLeft: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
  },
  subStepActive: {
    color: "blue",
    fontFamily: "ProximaNovaSemibold",
    fontWeight: 600,
  },
}));
