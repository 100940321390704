import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    marginTop: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(48)} !important`,
    },
  },
  gridWrapper: {
    width: `calc(100% - ${theme.spacing(12)}px) !important`,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.spacing(4)}px) !important`,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: `calc(100% - ${theme.spacing(8)}px) !important`,
    },
    margin: `${theme.custom.pxToRem(40)} auto 0 !important`,
    "& .MuiGrid-root": {
      padding: `0 !important`,
    },
  },

  line: {
    width: "100%",
    margin: `${theme.custom.pxToRem(48)} 0 ${theme.custom.pxToRem(64)} `,
    [theme.breakpoints.down("xs")]: {
      margin: `${theme.custom.pxToRem(8)} 0`,
    },
  },
}));
