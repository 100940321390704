import React, { useEffect } from "react";
import Card from "./Card/Card";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import Loader from "shared_components/Loader/Loader";

//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
//REDUX
import { getPreferedCategories, setPreferedCategories } from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CategoriesDnD = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: categories, is_loading } = useSelector(
    (state) => state.auth.user.ordered_categories
  );
  const { user_guid } = useSelector((state) => state.auth.user);

  useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      dispatch(getPreferedCategories());
    }
  }, [dispatch, user_guid]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const new_items = reorder(
      categories,
      result.source.index,
      result.destination.index
    );
    dispatch(setPreferedCategories(new_items));
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
  });

  return (
    <>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Grid
                container
                justifyContent={"center"}
                className={classes.dnd}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {categories.map((category, index) => (
                  <Card key={category.id} index={index} category={category} />
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default CategoriesDnD;
