import React from "react";
import Link from "shared_components/Link/Link";
import { ROOT } from "navigation/constants";
import useStyles from "./styles";
import Navbar from "shared_components/Navbar/Navbar";
import Typography from "@material-ui/core/Typography";
// assets
import error404Icon from "assets/icons/error-pages/404-error.svg";
// shared components
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled.jsx";

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Navbar />
      <div className={classes.pageContent}>
        <img src={error404Icon} alt="Sort" className={classes.icon} />
        <Typography className={classes.header} variant="h1">
          Oops! Page is missing
        </Typography>
        <Typography className={classes.bodyText} variant="h4">
          Don’t worry there are many other things to explore!
        </Typography>
        <Link to={ROOT} className={classes.link}>
          <ButtonStyled size="lg"> Keep exploring</ButtonStyled>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
