import React from "react";
import { getCountryNameByCode } from "shared_functions/shared_functions";
import Loader from "shared_components/Loader/Loader";
import ProfileBadge from "assets/icons/process/profileBadge.svg";
import { investorTypes } from "auth/investorTypes";
import Modal from "../../components/Modal/Modal";
//MUI
import useStyles from "./styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import Alert from "shared_components/Alerts/Alert/Alert";
//REDUX
import {
  updateUserPreferences,
  setNewInvestorType,
  setNewInvestorCountry,
} from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";
// assets
import countryList from "assets/data/countryList";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const country = {
    name: getCountryNameByCode(countryList, user.country),
    code: user.country,
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const countryInputRef = React.useRef();

  //event handlers
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenAlert(true);
    const params = {
      country: country.code,
      investor_type: user.investor_type,
    };
    dispatch(updateUserPreferences(params));
  };

  const handleInvestorChange = (e) => {
    //open modal when qualified or institutional investor is selected
    e.target.value > investorTypes.RETAIL_INVESTOR && setOpenModal(true);
    dispatch(setNewInvestorType(e.target.value));
  };

  const handleCountryChange = (country) => {
    if (country === null) return;
    else dispatch(setNewInvestorCountry(country.code));
  };

  const handleClick = () => {
    countryInputRef.current.scrollIntoView(true);
    // var element = document.getElementById("targetElement");
    // var headerOffset = 45;
    // var elementPosition = element.getBoundingClientRect().top;
    // var offsetPosition = elementPosition - headerOffset;

    // window.scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth",
    // });
    // window.scrollTo({
    //   top: document.body.scrollHeight,
    //   left: 0,
    //   behavior: "smooth",
    // });
  };

  return (
    <>
      <div className={classes.headerBagde}>
        <img className={classes.badge} src={ProfileBadge} alt="notification" />
        <Typography variant="h2" className={classes.bagdeText}>
          Edit Profile
        </Typography>
      </div>
      {user.is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <Avatar className={classes.avatar} src={user.picture_url} />
          <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.formField}
                  value={user.first_name}
                  variant="outlined"
                  placeholder="Type First Name"
                  fullWidth
                  disabled
                  label="First Name"
                  classes={{ root: classes.root }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.formField}
                  value={user.last_name}
                  variant="outlined"
                  placeholder="Type Last Name"
                  fullWidth
                  disabled
                  label="Last Name"
                  classes={{ root: classes.root }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.formField}
                  value={user.usedOAuth}
                  variant="outlined"
                  fullWidth
                  disabled
                  label="Social Account Connected"
                  classes={{ root: classes.root }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.formField}
                  value={user.email}
                  variant="outlined"
                  placeholder="Type Email Address"
                  fullWidth
                  disabled
                  label="Email"
                  classes={{ root: classes.root }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  value={
                    user.investor_type === null
                      ? investorTypes.RETAIL_INVESTOR
                      : user.investor_type
                  }
                  variant="outlined"
                  fullWidth
                  onChange={handleInvestorChange}
                  className={classes.formField}
                  elevation={0}
                >
                  <MenuItem value={investorTypes.RETAIL_INVESTOR} elevation={0}>
                    Retail Investor
                  </MenuItem>
                  <MenuItem
                    value={investorTypes.QUALIFIED_INVESTOR}
                    elevation={0}
                  >
                    Qualified Investor
                  </MenuItem>
                  <MenuItem
                    value={investorTypes.INSTITUTIONAL_INVESTOR}
                    elevation={0}
                  >
                    Institutional Investor
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  autoComplete={false}
                  className={classes.formField}
                  classes={{ input: classes.autoCompleteInput }}
                  autoHighlight
                  value={country.code === null ? countryList[0] : country}
                  onChange={(e, value) => handleCountryChange(value)}
                  options={countryList}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.code === value.code
                  }
                  onOpen={handleClick}
                  ref={countryInputRef}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Country of Residence"
                        id="input"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                <ButtonStyled
                  variant="success"
                  size="lg"
                  type="submit"
                  fullWidth
                >
                  Save
                </ButtonStyled>
              </Grid>
              <Modal open={openModal} handleClose={setOpenModal} />
              <Alert
                open={openAlert}
                handleClose={handleClose}
                text="Your changes have been saved"
                severity="success"
                variant="filled"
              />
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default Profile;
