import React from "react";
import FacebookLoginButton from "../Buttons/FacebookLoginButton";
import GoogleLoginButton from "../Buttons/GoogleLoginButton";
import LinkedInLoginButton from "../Buttons/LinkedInLoginButton";
import Line from "assets/decoration/line_horizontal_login.svg";

//MUI
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const SocialLoginCard = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2">
        Continue with <br></br> Social Logins
      </Typography>
      <img src={Line} alt="line" className={classes.line} />
      <GoogleLoginButton />
      <FacebookLoginButton />
      <LinkedInLoginButton />
    </>
  );
};

export default SocialLoginCard;
