import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "unset",
  },
  card: {
    display: "flex",
    border: `1px solid ${theme.palette.grey_tone["200"]}`,
    boxShadow: `${theme.shadows[2]}`,
    height: `${theme.custom.pxToRem(104)}`,
    borderRadius: `${theme.custom.pxToRem(12)}`,
    overflow: "hidden",
  },
  thumbnailWrapper: {
    width: `${theme.custom.pxToRem(116)}`,
    position: "relative",
  },
  picture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  duration: {
    position: "absolute",
    bottom: `${theme.spacing(2)}px !important`,
    right: `${theme.spacing(2)}px !important`,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    padding: `${theme.custom.pxToRem(4)} ${theme.custom.pxToRem(8)} !important`,
    borderRadius: `${theme.custom.pxToRem(3)} !important`,
    [theme.breakpoints.down("sm")]: {
      bottom: `${theme.custom.pxToRem(6)} !important`,
      right: `${theme.custom.pxToRem(6)} !important`,
      padding: `${theme.custom.pxToRem(0.8)} ${theme.custom.pxToRem(
        3.2
      )} !important`,
    },
  },
  informationWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: `${theme.custom.pxToRem(8)}`,
    gap: `${theme.custom.pxToRem(8)}`,
  },
  labelsWrapper: {
    display: "flex",
    gap: `${theme.custom.pxToRem(4)}`,
    color: theme.palette.grey_tone["400"],
  },
  count: {
    color: theme.palette.primary.main,
  },
}));
