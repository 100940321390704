import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  banner: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]} !important`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  information: {
    width: "30vw",
    paddingLeft: `${theme.spacing(6)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      paddingLeft: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`,
      alignItems: "flex-start",
    },
  },
  channelLogoWrapper: {
    marginBottom: `${theme.custom.pxToRem(16)}`,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(12)}`,
      marginBottom: 0,
      transform: "translateY(-40%)",
      zIndex: 20,
      borderRadius: `${theme.custom.pxToRem(5)}`,
      // filter: `drop-shadow(${theme.custom.palette.shadows.lvl2})`,
      border: `1px solid ${theme.palette.background.border}`,
    },
  },
  channelLogo: {
    width: `${theme.custom.pxToRem(155)}`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(100)}`,
    },
  },
  channelName: {
    marginBottom: `${theme.custom.pxToRem(8)}`,
  },
  followersCount: {
    color: theme.palette.primary.main,
  },
  followersLabel: {
    color: theme.palette.grey_tone["400"],
  },
  button: {
    width: `${theme.custom.pxToRem(120)}`,
    position: "absolute",
    top: 16,
    right: 16,
  },
  pictureWrapper: {
    width: "70vw",
    height: `${theme.custom.pxToRem(320)}`,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: `${theme.custom.pxToRem(156)} !important`,
    },
  },
  fundPic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  wave: {
    position: "absolute",
    left: -36,
    top: 0,
    zIndex: "100",
    height: "100%",
  },
}));
