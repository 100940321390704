import React from "react";
import Card from "./Card/Card";
import MemberIcon from "assets/icons/process/member.svg";
import PlayIcon from "assets/icons/process/play.svg";
import ConversationIcon from "assets/icons/process/conversation.png";
import clsx from "clsx";
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination]);

const CARDS = [
  {
    title: "Get updated",
    content:
      "COHERRA allows you to keep track and get updated on the funds you have invested in, but also get updated on the market.",
    icon: MemberIcon,
  },
  {
    title: "Video-based",
    content:
      "Updates from asset managers are made through compelling videoes. You no longer have to fret long dusty quarterley reports.",
    icon: PlayIcon,
  },
  {
    title: "Engage with Asset Managers",
    content:
      "You can leave comments or ask questions on updates from the asset manager. Coherra helps you keep taps on the lastest news.",
    icon: ConversationIcon,
  },
];

const FooterSection = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:959px)");
  return (
    <section className={classes.bottomSection}>
      <svg
        width="100%"
        viewBox="0 0 1902 275"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.wave}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1902 32.264C1444.61 131.688 1234.38 167.019 915.05 122.915C595.721 78.8108 348.902 -34.1497 8.7846e-06 66.6746C-1.09807e-05 232.016 8.7846e-06 274 8.7846e-06 274H1902C1902 274 1902 188.033 1902 32.264Z"
          fill="#8A9CE9"
        />
      </svg>
      {isPhone ? (
        <Swiper
          pagination={true}
          loop={true}
          slidesPerView={1}
          spaceBetween={0}
          className={clsx(classes.swiper, "mySwiper")}
        >
          {CARDS.map((card, index) => (
            <SwiperSlide className={classes.swiperSlide} key={index}>
              <Card
                title={card.title}
                content={card.content}
                icon={card.icon}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Grid
          container
          spacing={3}
          justifyContent={"space-around"}
          className={classes.footerGrid}
        >
          {CARDS.map((card, index) => (
            <Card
              title={card.title}
              content={card.content}
              icon={card.icon}
              key={index}
            />
          ))}
        </Grid>
      )}
    </section>
  );
};

export default FooterSection;
