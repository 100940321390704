import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import carouselReducer from "./ducks/carousel";
import categoriesReducer from "./ducks/categories";
import followedFundsReducer from "./ducks/followed_funds";
import fundReducer from "./ducks/fund";
import fundsReducer from "./ducks/funds";
import userReducer from "./ducks/user";
import videoReducer from "./ducks/video";
import watchLaterReducer from "./ducks/watch_later";
import uiReducer from "./ducks/ui";
import videoChannelReducer from "./ducks/video_channel";
import companyReducer from "./ducks/company";
import { RESET_REDUX } from "./ducks/user";

const INITIAL_STATE = {};

const middleware = [thunk];

//main structure of the redux store
const reducers = combineReducers({
  auth: combineReducers({
    user: userReducer,
  }),
  data: combineReducers({
    followed_funds: followedFundsReducer,
    watch_later: watchLaterReducer,
    carousel: carouselReducer,
    categories: categoriesReducer,
    video: videoReducer,
    funds: fundsReducer,
    fund: fundReducer,
    video_channel: videoChannelReducer,
    company: companyReducer,
  }),
  ui: uiReducer,
});

//this will reset all reducers to the initial state. Its used when users logs in and when they logout
const rootReducer = (state, action) => {
  if (action.type === RESET_REDUX) {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const store = createStore(
  rootReducer,
  INITIAL_STATE,
  compose(
    applyMiddleware(...middleware),
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined" ||
      window.coherra_env.environment === "PROD"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
