import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    backgroundColor: `${theme.palette.background.default}`,
  },
  wrapper: {
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${theme.spacing(4)}px) !important`,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: `calc(100% - ${theme.spacing(8)}px) !important`,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${theme.spacing(12)}px) !important`,
    },

    margin: "auto",
  },
}));
