import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  logoWrapper: {
    padding: `0 ${theme.custom.pxToRem(48)} `,
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]}`,
    display: "flex",
    height: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(48)} !important`,
      padding: `0 ${theme.custom.pxToRem(32)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(16)} `,
    },
    alignItems: "center",
  },
  logo: {
    height: `${theme.custom.pxToRem(16)} !important`,
  },
  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 80px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 48px)",
    },
  },
  informationWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    margin: `${theme.custom.pxToRem(24)} 0 ${theme.custom.pxToRem(40)}`,
  },
  button: {
    width: `${theme.custom.pxToRem(163)}`,
  },
}));
