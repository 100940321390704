import React, { useState, useEffect } from "react";
import useStyles from "./styles";
// MUI components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
// Custom components
import RadioButtonStyled from "shared_components/InputComponents/RadioButton/RadioButtonStyled";
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled.jsx";
// Graphical elements
import DeleteAccountBagde from "assets/icons/process/delete-account-bagde.svg";

const DeleteAccount = () => {
  const classes = useStyles();
  const [reason, setReason] = useState("Not enough interesting content");
  const [elaboration, setElaboration] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    console.log(reason);
    if (reason === "Other") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [reason]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleRadio = (e) => {
    setReason(e.target.value);
  };

  return (
    <div className={classes.page}>
      <div className={classes.headerBagde}>
        <img
          className={classes.badge}
          src={DeleteAccountBagde}
          alt="Delete Account"
        />
        <h1 className={classes.bagdeText}>Delete Account</h1>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Typography variant="h4">Reason for leaving*</Typography>
        <RadioGroup
          className={classes.radioGroup}
          value={reason || ""}
          onChange={(e) => setReason(e.target.value)}
          onClick={handleRadio}
        >
          <RadioButtonStyled
            value="Not enough interesting content"
            label="Not enough interesting content"
          />
          <RadioButtonStyled
            value="I have another Coherra account "
            label="I have another Coherra account "
          />
          <RadioButtonStyled
            value="I don’t understand how to use Coherra"
            label="I don’t understand how to use Coherra"
          />
          <RadioButtonStyled
            value="This is temporary. I’ll be back"
            label="This is temporary. I’ll be back"
          />
          <RadioButtonStyled
            value="Privacy Concerns"
            label="Privacy Concerns"
          />
          <RadioButtonStyled
            value="I don’t trust the content"
            label="I don’t trust the content"
          />
          <RadioButtonStyled value="Other" label="Other" />
        </RadioGroup>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <TextField
              className={classes.form}
              value={elaboration}
              onChange={(e) => setElaboration(e.target.value)}
              variant="outlined"
              multiline={true}
              label="Details"
              placeholder="Please elaborate here"
              fullWidth
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} md={3} lg={2}>
            <div className={classes.btn}>
              <ButtonStyled variant="warning" type="submit" size="lg">
                Delete
              </ButtonStyled>
            </div>
          </Grid>
        </Grid>
        <Typography variant="body2">
          Your account will be deleted permanently. Personal information will be
          removed from our database.
        </Typography>
      </form>
    </div>
  );
};

export default DeleteAccount;
