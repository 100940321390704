const palette = {
  primary: "#5C69DE",
  primary_transparent: "#5C69DE33", //20% opacity
  secondary: "#4A90E2",
  highlight: "#8A9CE9",
  font: "#4A4A4A",
  grey_tone: {
    0: "#FFFFFF",
    100: "#FAFAFA",
    200: "#F1F1F1",
    300: "#DADADA",
    400: "#9B9B9B",
  },
  grey_tone_transparent: {
    400: "#9B9B9B33",
  },
  accent: "#F5A623",
  error: "#ED3131",
  success: "#75C857",
  unread: "#F7FCFF",
  text_dark: "#4A4A4A",
  text_bright: "#fff",
  shadows: {
    lvl0: "0px 4px 8px rgba(102, 126, 234, 0.1)",
    lvl1: "1px 2px 25px rgba(102, 126, 234, 0.2)",
    lvl2: "0px 2px 12px rgba(74, 144, 226, 0.35)",
    lvl3: "0px 2px 12px rgba(102, 126, 234, 0.35)",
    white: "0px 2px 12px rgba(102, 126, 234, 0.35)",
  },
};

export default palette;
