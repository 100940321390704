import React from "react";
import emptyVideosStateIcon from "assets/icons/icons/emptyVideosState.svg";
import Loader from "shared_components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import FundSnippet from "shared_components/FundSnippet/FundSnippet";
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
//REDUX
import { useSelector, useDispatch } from "react-redux";
import { getMoreCompanyFunds } from "redux/ducks/company";

const Funds = () => {
  const classes = useStyles();
  const { companyGuid } = useParams();
  const funds = useSelector((state) => state.data.company.funds.data);
  const paging = useSelector((state) => state.data.company.funds.paging);
  const is_loading = useSelector(
    (state) => state.data.company.funds.is_loading
  );
  const VIDEOS_SIZE = 12;
  const dispatch = useDispatch();

  const fetchMoreFunds = () => {
    dispatch(getMoreCompanyFunds(companyGuid, paging.page + 1, VIDEOS_SIZE));
  };

  return (
    <>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <div className={classes.backGround}>
          {funds !== null && funds.length > 0 ? (
            <InfiniteScroll
              dataLength={funds.length}
              next={fetchMoreFunds}
              hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
              loader={<Loader />}
            >
              <Grid container spacing={3} className={classes.grid}>
                {funds.map((fund, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className={classes.gridItem}
                      key={index}
                    >
                      <FundSnippet fund={fund} />
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          ) : (
            <section className={classes.emptyStateWrapper}>
              <div className={classes.itemsWrapper}>
                <img
                  src={emptyVideosStateIcon}
                  alt="icon"
                  className={classes.emptyStateIcon}
                />
                <Typography
                  className={classes.emptyStateTitle}
                  variant="h2"
                  align="center"
                >
                  There are no funds in this company
                </Typography>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default Funds;
