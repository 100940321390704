import React from "react";
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const Card = ({ icon, title, content }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={10} sm={6} md={3} className={classes.card}>
        <img src={icon} alt="icon" />
        <Typography
          variant="h3"
          color="textSecondary"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {content}
        </Typography>
      </Grid>
    </>
  );
};

export default Card;
