import React from "react";
import VideoCard from "./VideoCard/VideoCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "shared_components/Loader/Loader";
//MUI
import useStyles from "./styles";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { getMoreWatchLaterVideos } from "redux/ducks/watch_later";

const ListSection = ({ handleNewSelection, videos, activeIndex }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const paging = useSelector((state) => state.data.watch_later.paging);
  const VIDEOS_SIZE = 10;

  const fetchMoreVideos = () => {
    dispatch(getMoreWatchLaterVideos(paging.page + 1, VIDEOS_SIZE));
  };

  return (
    <div className={classes.videosWrapper}>
      <InfiniteScroll
        dataLength={videos.length}
        next={fetchMoreVideos}
        hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
        loader={<Loader />}
      >
        {videos.map((video, index) => (
          <VideoCard
            snippet={video.snippet}
            key={index}
            index={index}
            selected={index === activeIndex}
            handleNewSelection={handleNewSelection}
            paging={paging}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ListSection;
