import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dialog: {
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    borderColor: theme.palette.background.secondary,
  },
  wrapper: {
    padding: `${theme.custom.pxToRem(32)}`,
    display: "flex",
    flexDirection: "column",
    gap: `${theme.custom.pxToRem(32)}`,
    alignItems: "center",
  },
  button: {
    width: `${theme.custom.pxToRem(200)}`,
  },
}));
