import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => {
  return {
    listItem: {
      display: "flex",
      alignItems: "center",
      borderTop: `1px solid ${palette.grey_tone[300]}`,
      borderBottom: `1px solid ${palette.grey_tone[300]}`,
      justifyContent: "space-between",
      marginBottom: -1,
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: 8,
    },
  };
});
