import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  pageHeader: {
    backgroundColor: "white",
    borderBottom: `1px solid ${palette.grey_tone[200]}`,
    position: "relative",
  },
  text: {
    padding: `${theme.custom.pxToRem(48)} `,
    display: "flex",
    flexDirection: "column",
    gap: 4,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(32)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(16)} `,
    },
  },
}));
