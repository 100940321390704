import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  banner: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]} !important`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  information: {
    width: "50vw",
    paddingLeft: `${theme.spacing(6)}px`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${theme.spacing(4)}px`,
      //this fixes video proportion porblem, do not delete
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      paddingLeft: `0`,
    },
    display: "flex",
    alignItems: "flex-end",
  },
  titleWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
      paddingLeft: `${theme.custom.pxToRem(16)} !important`,
      marginTop: `${theme.custom.pxToRem(-30)} !important`,
      position: "relative",
      zIndex: 300,
    },
  },
  typeLabel: {
    color: `${theme.palette.grey_tone["400"]} !important`,
  },
  byCompanyLabel: {
    color: theme.palette.grey_tone["400"],
    [theme.breakpoints.down("xs")]: {
      margin: `${theme.custom.pxToRem(8)} 0`,
    },
  },
  byCompanyName: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    width: `${theme.custom.pxToRem(105)}`,
    margin: `${theme.custom.pxToRem(16)} 0`,
  },
  chipsOverflowWrapper: {
    overflowX: "scroll",
    // hide scroll bar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]} !important`,
  },
  chipsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: `${theme.custom.pxToRem(16)}`,
    gap: `${theme.custom.pxToRem(8)}`,
    [theme.breakpoints.down("sm")]: {
      margin: `0`,
      padding: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(32)}`,
      flexWrap: "nowrap",
      width: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(16)}`,
    },
  },
  chip: {
    [theme.breakpoints.down("sm")]: {
      fontSize: `${theme.custom.pxToRem(12)}`,
    },
  },
  actionButtonsWrapper: {
    display: "flex",
    borderTop: `1px solid ${theme.palette.grey_tone["200"]} !important`,
    padding: `${theme.custom.pxToRem(16)} 0 !important`,
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(16)} !important`,
      justifyContent: "space-around",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: `${theme.custom.pxToRem(8)} !important`,
      padding: `${theme.custom.pxToRem(8)} 0 !important`,
    },
  },
  actionButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: `${theme.custom.pxToRem(66)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginRight: `0 !important`,
    },
    "&:last-child": {
      marginRight: `0 !important`,
    },
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(8)} !important`,
    border: `1px solid ${theme.custom.palette.primary}`,
    "&:hover": {
      [theme.breakpoints.up("sm")]: {
        background: alpha(`${theme.custom.palette.primary}`, 0.2),
      },
      [theme.breakpoints.down("sm")]: {
        background: `transparent`,
      },
    },
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.7)",
    },
  },
  iconButtonDisabled: {
    border: `1px solid ${theme.custom.palette.grey_tone[400]} !important`,
  },
  investIconButton: {
    padding: `${theme.custom.pxToRem(8)} !important`,
    border: `1px solid ${theme.custom.palette.accent} !important`,
    "&:hover": {
      background: alpha(`${theme.custom.palette.accent}`, 0.2),
    },
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.7)",
      "&:hover": {
        background: theme.palette.background.default,
      },
    },
  },
  label: {
    ...theme.custom.label,
    color: `${theme.palette.text.palette}`,
    marginTop: `${theme.custom.pxToRem(4)} !important`,
  },
  pictureWrapper: {
    width: "50vw",
    height: `${theme.custom.pxToRem(360)}`,
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(196)}`,
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: `${theme.custom.pxToRem(156)} !important`,
    },
  },
  fundPic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  videoWrapper: {
    width: "50vw",
    position: "relative",
    maxWidth: `${theme.custom.pxToRem(720)}`,
    [theme.breakpoints.down("sm")]: {
      //important to keep this to fix the problem with aspect ratio
      width: "66vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  fundIntroVideo: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    "& iframe": {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
    },
  },
  wave: {
    position: "absolute",
    left: -2,
    top: 0,
    zIndex: "100",
    height: "100%",
  },
  horizontalWave: {
    position: "absolute",
    bottom: -2,
    left: 0,
    zIndex: "100",
    width: "100%",
  },
}));
