import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  aside: {
    padding: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(16)} `,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)} `,
    },
  },
  cardAsideText: {
    margin: `${theme.custom.pxToRem(8)} 0 !important`,
  },
  carouselCard: {
    filter: `drop-shadow(${theme.shadows[4]})`,
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: `${theme.custom.pxToRem(600)} !important`,
    },
    [theme.breakpoints.down("sm")]: {
      width: `${theme.custom.pxToRem(396)} !important`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(250)} !important`,
      flexDirection: "column",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    padding: `0 ${theme.custom.pxToRem(48)} `,
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.custom.pxToRem(32)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0  `,
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  arrowButtonLeft: {
    marginRight: `${theme.custom.pxToRem(17)} !important`,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      bottom: -8,
      left: "calc(50% - 100px)",
      zIndex: "500",
    },
  },
  arrowButtonRight: {
    "&:hover": {
      cursor: "pointer",
    },
    marginLeft: `${theme.custom.pxToRem(17)} !important`,
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      bottom: -8,
      right: "calc(50% - 100px)",
      zIndex: "500",
    },
  },
  thumbnailWrapper: {
    width: "100%",
    position: "relative",
    paddingBottom: "56.25%",
  },
  thumbnail: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  title: {
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  description: {
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  link: {
    textDecoration: "none",
    color: `${theme.palette.primary.main} !important`,

    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkTextWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  companyLogo: {
    height: `${theme.custom.pxToRem(32)} !important`,
    width: `${theme.custom.pxToRem(32)} !important`,
    background: `${theme.palette.background.vertical_gradient} !important`,
    padding: `${theme.custom.pxToRem(1)} !important`,
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: `${theme.spacing(1)}px !important`,
  },
  companyLogoImg: {
    height: "100%",
    width: "100%",
  },
  label: {
    ...theme.custom.label,
    color: `${theme.palette.primary.main} !important`,
    fontFamily: "ProximaNovaSemibold",
    height: "fit-content",
  },
}));
