import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.custom.pxToRem(15)} 0  !important`,
    height: ({ height }) => height,
  },
  spinner: {
    maxWidth: `${theme.custom.pxToRem(350)} !important`,
    maxHeight: `${theme.custom.pxToRem(350)} !important`,
  },
}));
