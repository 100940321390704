import React, { useState } from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
//REDUX
import { useDispatch } from "react-redux";

const FollowButton = ({
  guid,
  isFollowing,
  onFollow,
  onUnfollow,
  className,
}) => {
  const active = isFollowing;
  const [isHover, setIsHover] = useState(false);
  const dispatch = useDispatch();

  const handleFollow = (e) => {
    dispatch(onFollow(guid));
  };

  const handleUnfollow = (e) => {
    dispatch(onUnfollow(guid));
  };

  const handleMouseEnter = (e) => {
    setIsHover(true);
  };

  const handleMouseLeave = (e) => {
    setIsHover(false);
  };

  const classes = useStyles({ active });
  return (
    <>
      {active ? (
        <Button
          className={clsx(classes.outlined, className)}
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleUnfollow}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          data-cy="followingButton"
        >
          {isHover ? "Unfollow" : "Following"}
        </Button>
      ) : (
        <Button
          className={clsx(classes.outlined, className)}
          color="primary"
          variant="outlined"
          onClick={handleFollow}
          fullWidth
          data-cy="followButton"
        >
          Follow
        </Button>
      )}
    </>
  );
};

export default FollowButton;
