import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    marginTop: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(48)} !important`,
    },
  },
}));
