import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_FOLLOWED_FUNDS = "followed_funds/SET_FOLLOWED_FUNDS";
const START_LOADING = "followed_funds/START_LOADING";
const STOP_LOADING = "followed_funds/STOP_LOADING";

const initialState = {
  is_loading: true,
  data: [],
};

export const getFollowedFunds = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let {
      data: { followed_funds: data },
    } = await api.getFollowedFunds();
    //if there are none videos in the database assosiated with followed videos fill the videos with empty array to make displaying easier
    if (data === null) data = [];
    dispatch({ type: SET_FOLLOWED_FUNDS, payload: data });
    dispatch(stopLoading());
  } catch (error) {
    constructAndSendError(
      error.message,
      "followed_funds/getFollowedFunds",
      "/data/followedfunds/videos"
    );
    console.log(error);
  }
};

export const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FOLLOWED_FUNDS: {
      return { ...state, data: action.payload };
    }
    case START_LOADING: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING: {
      return { ...state, is_loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
