import React from "react";
import { ABOUT, VIDEOS, CONTACT, EVENTS } from "navigation/constants";
import { ReactComponent as PlayIcon } from "assets/icons/icons/media.svg";
import { ReactComponent as BrochureIcon } from "assets/icons/icons/brochure.svg";
import { ReactComponent as EmailIcon } from "assets/icons/icons/email.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/icons/calendar.svg";
import TabBarItem from "./TabBarItem/TabBarItem";
//MUI
import useStyles from "./styles";

const tabBarItems = [
  {
    icon: PlayIcon,
    text: "Videos",
    path: VIDEOS,
    svgIssue: false,
  },
  {
    icon: BrochureIcon,
    text: "About",
    path: ABOUT,
    svgIssue: false,
  },
  {
    icon: EmailIcon,
    text: "Contact",
    path: CONTACT,
    svgIssue: false,
  },
  {
    icon: CalendarIcon,
    text: "Events",
    path: EVENTS,
    svgIssue: true,
  },
];

const TabBar = () => {
  const classes = useStyles();
  const path = window.location.pathname;

  return (
    <section className={classes.navigationWrapper} data-cy="tabbar">
      <div className={classes.navigation}>
        {tabBarItems.map((item, index) => (
          <TabBarItem
            Icon={item.icon}
            text={item.text}
            isActive={path.includes(item.path)}
            key={index}
            link={item.path}
            svgIssue={item.svgIssue}
          />
        ))}
      </div>
    </section>
  );
};

export default TabBar;
