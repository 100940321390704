import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  comment: {
    marginBottom: `${theme.custom.pxToRem(32)} !important`,
    display: "flex",
    justifyContent: "space-between",
  },
  commentWrapper: {
    position: "relative",
    borderLeft: `2px solid ${theme.palette.grey_tone["300"]} !important`,
    marginLeft: `${theme.custom.pxToRem(32)} !important`,
    paddingLeft: `${theme.custom.pxToRem(48)} !important`,
    maxWidth: `calc(100% - 56px)`,
    wordBreak: `break-all`,
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `${theme.custom.pxToRem(20)} !important`,
      paddingLeft: `${theme.custom.pxToRem(38)} !important`,
    },
  },
  avatarWrapper: {
    width: `${theme.custom.pxToRem(64)} !important`,
    height: `${theme.custom.pxToRem(72)} !important`,
    position: "absolute",
    left: `-${theme.custom.pxToRem(32)} !important`,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: `fit-content !important`,
      height: `${theme.custom.pxToRem(44)} !important`,
      left: `-${theme.custom.pxToRem(20)} !important`,
    },
  },
  avatar: {
    width: `${theme.custom.pxToRem(64)} !important`,
    height: `${theme.custom.pxToRem(64)} !important`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(40)} !important`,
      height: `${theme.custom.pxToRem(40)} !important`,
    },
  },
  timestamp: {
    color: `${theme.palette.grey_tone["400"]} !important`,
  },
  body: {
    "& .clamp-lines__button": {
      padding: 0,
      border: "none",
      background: "none",
      cursor: "pointer",
      fontFamily: "ProximaNovaSemibold",
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: `${theme.custom.pxToRem(16)} !important`,
      lineHeight: `${theme.custom.pxToRem(24)} !important`,
      letterSpacing: `${theme.custom.pxToRem(0.18)} !important`,
    },
    "& .clamp-lines__button:hover": {
      textDecoration: "underline",
    },
  },
  actionButtonsWrapper: {
    display: "flex",
    marginLeft: `-${theme.custom.pxToRem(12)} !important`,
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginRight: `${theme.custom.pxToRem(4)} !important`,
    },
  },
  form: {
    flexGrow: 1,
    marginBottom: 0,
  },
  textField: {
    borderRadius: `${theme.custom.pxToRem(25)} !important`,
    padding: `${theme.custom.pxToRem(10.5)} ${theme.custom.pxToRem(
      16
    )} !important`,
    minHeight: `0 !important`,
    "& input": {
      padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
        16
      )} !important`,
    },
    "&:hover fieldset": {
      borderColor: `${theme.custom.palette.primary} !important`,
    },
  },
  submitButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    gap: `${theme.custom.pxToRem(10)} !important`,
  },
  submitButton: {
    minWidth: `${theme.custom.pxToRem(170)} `,
    [theme.breakpoints.down("xs")]: {
      minWidth: "0",
      width: `${theme.custom.pxToRem(120)} !important`,
    },
  },
  //popover
  iconWrapper: {
    background: `white`,
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(6)} !important`,
    },
  },
  popover: {
    border: `1px solid ${theme.palette.background.border}`,
    filter: `drop-shadow(${theme.custom.palette.shadows.lvl3})`,
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "visible",
  },
  popoverBodyWrapper: {
    padding: `${theme.custom.pxToRem(16)} 0 !important`,
    background: `${theme.palette.background.default}`,
    position: "relative",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    cursor: "pointer",
  },
  popoverItemWrapper: {
    padding: `0 ${theme.custom.pxToRem(16)} !important`,
    display: "flex",
    alignItems: "center",
    marginBottom: `${theme.custom.pxToRem(8)} !important`,
    "&:last-child": {
      marginBottom: `0 !important`,
    },
    "&:hover": {
      backgroundColor: `#0000000a`,
    },
  },
  popoverText: {
    height: "fit-content",
    marginLeft: `${theme.custom.pxToRem(16)} !important`,
  },
  square: {
    height: "16px",
    width: "16px",
    borderTop: `1px solid ${theme.palette.background.border}`,
    borderLeft: `1px solid ${theme.palette.background.border}`,
    position: "absolute",
    top: `-8px`,
    right: `18px`,
    background: `${theme.palette.background.default}`,
    transform: `rotate(45deg)`,
  },
}));
