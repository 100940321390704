import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  avatar: {
    width: `${theme.custom.pxToRem(30)} !important`,
    height: `${theme.custom.pxToRem(30)} !important`,
  },
  avatarBorder: {
    width: `${theme.custom.pxToRem(32)} !important`,
    height: `${theme.custom.pxToRem(32)} !important`,
    borderRadius: "50%",
    background: `${theme.palette.background.vertical_gradient}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },
  menuPaper: {
    borderRadius: 12,
    border: "1px solid #d3d4d5",
    boxShadow: theme.shadows[4],
    overflow: "hidden",
    width: `${theme.custom.pxToRem(250)}`,
  },
  menuList: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    marginBottom: -1,
  },
  menuItem: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.grey_tone[300]}`,
    "& .MuiTouchRipple-root": {
      color: theme.palette.text.primary,
    },
  },
  listItem: {
    minWidth: 32,
    paddingRight: 16,
  },
  listItemText: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  drawer: {
    width: `${theme.custom.pxToRem(270)}`,
    justifyContent: "space-between",
  },
  header: {
    padding: `${theme.custom.pxToRem(16)}`,
    display: "flex",
    gap: `${theme.custom.pxToRem(12)}`,
    marginBottom: `${theme.custom.pxToRem(8)}`,
  },
  headerAvatar: {
    height: `${theme.custom.pxToRem(56)} !important`,
    width: `${theme.custom.pxToRem(56)} !important`,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  drawerMenuList: {
    "& > span:last-child > a > li": {
      borderBottom: `1px solid ${theme.palette.grey_tone[300]}`,
    },
  },
  drawerItem: {
    height: 56,
    borderTop: `1px solid ${theme.palette.grey_tone[300]}`,
    "& .MuiTouchRipple-root": {
      color: theme.palette.text.primary,
    },
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    padding: `${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(
      8
    )} ${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(16)}`,
  },
  drawerIcon: {
    justifyContent: "flex-end",
  },
  drawerSubItem: {
    height: 56,
    borderTop: `1px solid ${theme.palette.grey_tone[300]}`,
    paddingLeft: `${theme.custom.pxToRem(24)}`,
    gap: `${theme.custom.pxToRem(8)}`,
    "& .MuiTouchRipple-root": {
      color: theme.palette.text.primary,
    },
    "& img": {
      height: `${theme.custom.pxToRem(24)}`,
      width: `${theme.custom.pxToRem(24)}`,
    },
  },
  drawerSubItemIcon: {
    minWidth: "0",
  },
}));
