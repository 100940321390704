import React from "react";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Navbar from "shared_components/Navbar/Navbar";
import Navigation from "./components/Navigation/Navigation";
import FinishedRegCheck from "auth/FinishedRegCheck";

const UserSettings = () => {
  return (
    <>
      <FinishedRegCheck />
      <Navbar />
      <SideDrawer />
      <Navigation />
    </>
  );
};

export default UserSettings;
