import React, { useEffect } from "react";
import Link from "shared_components/Link/Link";
import Category from "../Category/Category";
import { FOLLOWING, REORDER_CATEGORIES } from "navigation/constants";
import followingNoFundsIcon from "assets/icons/icons/followingNoFunds.svg";
import rearrangeCircleIcon from "assets/icons/icons/RearrangeCircleIcon.svg";
import clsx from "clsx";
import Loader from "shared_components/Loader/Loader";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";

// Shared Components
import SwitchStyled from "shared_components/InputComponents/SwitchStyled/SwitchStyled";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import LoginTooltip from "shared_components/LoginTooltip/LoginTooltip";
//REDUX
import { getCategories } from "redux/ducks/categories";
import { getFollowedFunds } from "redux/ducks/followed_funds";
import { useDispatch, useSelector } from "react-redux";
import { setShowFollowedFunds } from "redux/ducks/ui";

const BrowsingContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery("(max-width:599px)");
  const categories = useSelector((state) => state.data.categories.data);
  const is_categories_loading = useSelector(
    (state) => state.data.categories.is_loading
  );
  const followedFunds = useSelector((state) => state.data.followed_funds.data);
  const is_followedFunds_loading = useSelector(
    (state) => state.data.followed_funds.is_loading
  );
  const user_guid = useSelector((state) => state.auth.user.user_guid);
  const is_anonymous = useSelector((state) => state.auth.user.is_anonymous);
  const showFollowedFunds = useSelector((state) => state.ui.showFollowedFunds);

  useEffect(() => {
    //prevents fetching before we have guid in redux
    if (user_guid !== null) {
      //fetch appropriate data based on the switch and only if we do not have them already in the redux
      if (showFollowedFunds) {
        if (followedFunds.length === 0) dispatch(getFollowedFunds());
      } else {
        if (categories.length === 0) dispatch(getCategories());
      }
    }
  }, [dispatch, showFollowedFunds, user_guid]);

  const handleSwitchClick = () => {
    dispatch(setShowFollowedFunds(!showFollowedFunds));
  };

  return (
    <>
      <section className={classes.options}>
        <Grid container className={classes.wrapper} spacing={3}>
          <Grid item xs={6} sm={8} md={9} lg={10} className={classes.grid}>
            {/* disable toggle when user is not logged in */}
            {is_anonymous ? (
              <div className={classes.switch}>
                <LoginTooltip
                  title="Want to see videos from funds your are following?"
                  description="Login or sign-up to see the videos"
                  placement="top"
                >
                  <div data-cy="showFollowedFundSwitch">
                    <SwitchStyled disabled />
                  </div>
                </LoginTooltip>
                <Typography variant="h4" className={classes.switchText}>
                  Show Followed Funds
                </Typography>
              </div>
            ) : (
              <div className={classes.switch}>
                <SwitchStyled
                  handleSwitchClick={handleSwitchClick}
                  checked={showFollowedFunds}
                />
                <Typography variant="h4" className={classes.switchText}>
                  Show Followed Funds
                </Typography>
              </div>
            )}
          </Grid>
          {(!showFollowedFunds || is_anonymous) && (
            <Grid item xs={3} sm={4} md={3} lg={2} className={classes.grid}>
              {/* disable button when user is not logged in */}
              {is_anonymous ? (
                <LoginTooltip
                  title="Want to rearrange categories?"
                  description="Login or sign-up to rearrange the categories"
                  placement="top"
                >
                  <div>
                    {isPhone ? (
                      <div className={classes.rearrangeButtonWrapper}>
                        <IconButton
                          classes={{
                            root: clsx(
                              classes.iconButton,
                              classes.iconButtonDisabled
                            ),
                          }}
                          disabled
                        >
                          <img src={rearrangeCircleIcon} alt="rearrange" />
                        </IconButton>
                        <Typography variant="h4" align="center">
                          Rearrange
                        </Typography>
                      </div>
                    ) : (
                      <Button size="lg" fullWidth disabled>
                        <Typography variant="h4">
                          Rearrange&#160;Categories
                        </Typography>
                      </Button>
                    )}
                  </div>
                </LoginTooltip>
              ) : (
                <Link to={REORDER_CATEGORIES} className={classes.reoderLink}>
                  {isPhone ? (
                    <div className={classes.rearrangeButtonWrapper}>
                      <IconButton classes={{ root: classes.iconButton }}>
                        <img src={rearrangeCircleIcon} alt="rearrange" />
                      </IconButton>
                      <Typography variant="h4" align="center">
                        Rearrange
                      </Typography>
                    </div>
                  ) : (
                    <Button size="lg" fullWidth>
                      <Typography variant="h4">
                        Rearrange&#160;Categories
                      </Typography>
                    </Button>
                  )}
                </Link>
              )}
            </Grid>
          )}
        </Grid>
      </section>
      {/* check which content should be display in the main browsing page and make sure user is not anonymous to see followed funds*/}
      {showFollowedFunds && !is_anonymous ? (
        // followed fund
        <>
          {is_followedFunds_loading ? (
            <Loader size={40} />
          ) : (
            <section>
              {followedFunds.length > 0 ? (
                followedFunds.map((category, index) => {
                  return (
                    <Category
                      category={category}
                      key={index}
                      showFundSwiperslide={showFollowedFunds && !is_anonymous}
                    />
                  );
                })
              ) : (
                <section className={classes.exploreFundsWrapper}>
                  <div className={classes.itemsWrapper}>
                    <img src={followingNoFundsIcon} alt="icon" />
                    <Typography
                      className={classes.exploreFundsText}
                      variant="h3"
                    >
                      You are currently not following any funds
                    </Typography>
                    <Link className={classes.reoderLink} to={FOLLOWING}>
                      <Button size="lg" className={classes.exploreFundsButton}>
                        Find Funds
                      </Button>
                    </Link>
                  </div>
                </section>
              )}
            </section>
          )}
        </>
      ) : (
        // categories
        <>
          {is_categories_loading ? (
            <Loader size={40} />
          ) : (
            <section>
              {categories.length
                ? categories.map((category, index) => {
                    return (
                      <Category
                        key={index}
                        category={category}
                        showFundSwiperslide={showFollowedFunds && !is_anonymous}
                      />
                    );
                  })
                : null}
            </section>
          )}
        </>
      )}
    </>
  );
};

export default BrowsingContent;
