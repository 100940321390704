import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  swiperSlide: {
    overflow: "hidden",
    position: "relative",
    height: "auto",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    borderRadius: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
      12
    )} 0 0  !important`,
    padding: `0 ${theme.custom.pxToRem(12)}`,
    marginRight: "0 !important",
    "&:first-child": {
      paddingLeft: `${theme.custom.pxToRem(48)}`,
    },
    "&:last-child": {
      paddingRight: `${theme.custom.pxToRem(48)}`,
    },
  },
  firstSlide: {
    marginLeft: `${theme.custom.pxToRem(48)}`,
    marginRight: `${theme.custom.pxToRem(12)} !important`,
    paddingLeft: `0 !important`,
  },
  swiper: {
    margin: `0 auto ${theme.custom.pxToRem(24)} !important`,
    padding: "0 !important",
    width: `100%  !important`,
    [theme.breakpoints.down("xs")]: {
      margin: `0 auto ${theme.custom.pxToRem(16)} !important`,
    },
  },
  fundPicture: {
    zIndex: "100",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
    left: 0,
    top: 0,
    background: "black",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: "200",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
  },
  fundlink: {
    position: "absolute",
    zIndex: "300",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: `${theme.palette.text.secondary} !important`,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

// {"categories_order":[{"id": 4,"is_hidden": false},{"id": 5,"is_hidden": false},{"id": 6,"is_hidden": false},{"id": 7,"is_hidden": false},{"id": 3,"is_hidden": false},{"id": 9,"is_hidden": false},{"id": 8,"is_hidden": false},{"id": 10,"is_hidden": false},{"id": 11,"is_hidden": false},{"id": 1,"is_hidden": false},{"id": 12,"is_hidden": false },{"id": 2,"is_hidden": false},{ "id": 13,"is_hidden": false}]}

// {"categories_order":[{"id": 4,"is_hidden": false},{"id": 5,"is_hidden": false},{"id": 6,"is_hidden": false},{"id": 7,"is_hidden": false}]}
