//link for the developer page: https://developers.facebook.com/apps/

//https://github.com/keppelen/react-facebook-login/issues/257 issue link

// Turns out Safari+Chrome will block popups if they are triggered outside of a synchronous
// user click event. This fixes that by retrieving the login status before user clicks "Login With Facebook".

//disableMobileRedirect={true} it will open new window tab and close it once the user has been logged in
//isMobile={false} bcs otherwise it is detected by browser

//issue can be that iOS blocks to display pop ups and does not even give u an option to change it as in browsers.
// enabling redirect to true can work around this problem

import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useHistory } from "react-router-dom";
import FacebookIcon from "assets/icons/socials/facebook.svg";
import { getFirstnameAndLastname } from "shared_functions/shared_functions";
import userTypes from "auth/userTypes";
import { ONBOARDING, LOADER } from "navigation/constants";
import { constructAndSendError } from "shared_functions/shared_functions";

//mui
import clsx from "clsx";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
//REDUX
import { authorize, setLastPageUrl } from "redux/ducks/user";
import { useDispatch } from "react-redux";

const FacebookLoginButton = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  //need the undefined check bcs OAuth refresh destroyes the history state and then it overwrites history.location.state
  history.location.state !== undefined &&
    localStorage.setItem("redirectUrl", history.location.state);

  const facebookSuccess = async (res) => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    localStorage.removeItem("redirectUrl");
    localStorage.setItem("lastUrl", "/login");
    history.push(LOADER);
    const userAuthObj = {};
    const { firstName, lastName } = getFirstnameAndLastname(res.name);
    userAuthObj.app_id = process.env.REACT_APP_APP_ID;
    userAuthObj.email = res.email;
    userAuthObj.first_name = firstName;
    userAuthObj.last_name = lastName;
    userAuthObj.picture_url = res.picture.data.url;
    userAuthObj.access_token = res.id;
    userAuthObj.token_type = userTypes.facebook;
    const { user } = await dispatch(authorize(userAuthObj));
    //check if investor went through registration process
    if (user[0].investor_type === 0) {
      //persist this to know where to forward user after onboarding
      dispatch(setLastPageUrl(redirectUrl));
      history.push(ONBOARDING);
    } else history.push(redirectUrl);
  };

  const facebookFailure = (error) => {
    constructAndSendError(error, "facebookFalure", "fbOauth2");
    console.log(error);
  };

  return (
    <FacebookLogin
      appId={`${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`}
      fields="name,email,picture"
      render={(renderProps) => (
        <Button
          className={clsx(classes.largeButton, classes.facebook)}
          onClick={renderProps.onClick}
          //overwrittes MuiButton-label class
          classes={{
            label: classes.label,
          }}
        >
          <img
            src={FacebookIcon}
            alt="facebook logo"
            className={classes.icon}
          />
          Continue&#160;with&#160;Facebook
        </Button>
      )}
      callback={facebookSuccess}
      onFailure={facebookFailure}
      redirectUri={window.location.href}
      disableMobileRedirect={true}
    />
  );
};

export default FacebookLoginButton;
