import React from "react";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import moment from "moment";
import Dot from "assets/decoration/Dot.svg";
import Link from "shared_components/Link/Link";
import { VIDEO, FUND, VIDEOS } from "navigation/constants";
import clsx from "clsx";
import time from "video-time";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const VideoSnippet = ({
  snippet: {
    thumbnail_pic,
    title,
    video_guid,
    duration,
    views_count,
    time_stamp,
    primary_fund_guid,
    primary_fund_name,
    primary_fund_visible,
  },
  children,
}) => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  return (
    <>
      {/* div only used for flex box */}
      <div>
        <Link to={`${VIDEO}/${video_guid}`}>
          <div className={classes.thumbnailWrapper}>
            <img
              src={thumbnail_pic}
              alt="thumbnail"
              className={classes.thumbnail}
            />
            <div className={classes.duration}>{time(duration)}</div>
          </div>
        </Link>
        <Link to={`${VIDEO}/${video_guid}`} className={classes.link}>
          <Typography variant="h4" className={classes.title}>
            <CustomEllipsis lines={2} text={title} />
          </Typography>
        </Link>
      </div>
      {/* div only used for flex box */}
      <div>
        <div className={clsx(classes.infoWrapper, classes.label)}>
          {/* TODO: put this back if we decide to display views count 
          <span>{views_count} Views</span> */}
          {!primary_fund_visible ? null : isPhone ? null : (
            <>
              {/* TODO: put this back if we decide to display views count 
              <img src={Dot} alt="dot" className={classes.dot} /> */}
              <Link
                to={`${FUND}/${primary_fund_guid}${VIDEOS}`}
                className={classes.fundLink}
              >
                {primary_fund_name}
              </Link>
            </>
          )}
        </div>
        <div className={classes.label}>
          {" "}
          From {moment(time_stamp).fromNow()}
        </div>
        {children}
      </div>
    </>
  );
};

export default VideoSnippet;
