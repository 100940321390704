//link for the developer page: https://console.cloud.google.com/apis/credentials/domainverification?authuser=1&project=coexporer&supportedpurview=project
import React from "react";
import { GoogleLogin } from "react-google-login";
import { useHistory } from "react-router-dom";
import GoogleIcon from "assets/icons/socials/google.svg";
import userTypes from "auth/userTypes";
import { ONBOARDING, LOADER } from "navigation/constants";
import { constructAndSendError } from "shared_functions/shared_functions";
//mui
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStyles from "./styles";
//REDUX
import { authorize, setLastPageUrl } from "redux/ducks/user";
import { useDispatch } from "react-redux";

const GoogleLoginButton = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirectUrl = history.location.state;

  const googleSuccess = async (res) => {
    localStorage.setItem("lastUrl", "/login");
    history.push(LOADER);
    const userAuthObj = {};
    const { profileObj } = res;
    userAuthObj.app_id = process.env.REACT_APP_APP_ID;
    userAuthObj.email = profileObj.email;
    userAuthObj.first_name = profileObj.givenName;
    userAuthObj.last_name = profileObj.familyName;
    userAuthObj.picture_url = profileObj.imageUrl;
    userAuthObj.access_token = res.googleId;
    userAuthObj.token_type = userTypes.google;
    const { user } = await dispatch(authorize(userAuthObj));
    //remove redirectUrl stored by facebook button
    localStorage.removeItem("redirectUrl");
    //check if investor went through registration process
    if (user[0].investor_type === 0) {
      //persist this to know where to forward user after onboarding
      dispatch(setLastPageUrl(redirectUrl));
      history.push(ONBOARDING);
    } else history.push(redirectUrl);
  };
  const googleFailure = ({ error }) => {
    if (error === "popup_closed_by_user") return;
    constructAndSendError(error, "googleFailure", "GoogleOAuth2");
    console.log(error);
  };

  return (
    <GoogleLogin
      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      render={(renderProps) => (
        <Button
          className={clsx(classes.largeButton, classes.google)}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          //overwrittes MuiButton-label class
          classes={{
            label: classes.label,
          }}
        >
          <img src={GoogleIcon} alt="google logo" className={classes.icon} />
          Continue&#160;with&#160;Google
        </Button>
      )}
      onSuccess={googleSuccess}
      onFailure={googleFailure}
      cookiePolicy="single_host_origin"
    />
  );
};

export default GoogleLoginButton;
