import React from "react";
import { Link, useLocation } from "react-router-dom";

//this is workaround for the linkn bug in the react router
const CustomLink = (props) => {
  const location = useLocation();
  return (
    <Link {...props} replace={location.pathname === props.to}>
      {props.children}
    </Link>
  );
};

export default CustomLink;
