import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  linkTab: {
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    padding: `${theme.custom.pxToRem(18)} 0 ${theme.custom.pxToRem(14)} `,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(12)} 0 `,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
    marginRight: `${theme.custom.pxToRem(31)} `,
    textDecoration: "none",
    "&:hover a": {
      textDecoration: "underline",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    gap: `${theme.custom.pxToRem(8)}`,
  },
  activeLink: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    gap: `${theme.custom.pxToRem(8)}`,
    textDecoration: "none !important",
  },
  activeTab: {
    color: theme.palette.primary.main,
    "& path": {
      stroke: ({ svgIssue }) => {
        if (svgIssue) return "none";
        else return theme.palette.primary.main;
      },
      fill: ({ svgIssue }) => {
        if (svgIssue) return theme.palette.primary.main;
        else return "none";
      },
    },
    "& circle": {
      stroke: theme.palette.primary.main,
    },
  },
  underLine: {
    position: "absolute",
    bottom: `-${theme.custom.pxToRem(1)} !important`,
    left: 0,
    width: "100%",
    height: `${theme.custom.pxToRem(4)} !important`,
    borderRadius: `${theme.custom.pxToRem(10)} !important`,
    background: `${theme.palette.background.vertical_gradient} !important`,
  },
}));
