import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";

const Loader = ({ size = 40, thickness = 3, height = "auto" }) => {
  const classes = useStyles({ height });
  return (
    <div className={classes.wrapper}>
      <CircularProgress
        size={size}
        thickness={thickness}
        className={classes.spinner}
      />
    </div>
  );
};

export default Loader;
