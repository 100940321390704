import axios from "axios";

const URL =
    window.coherra_env.environment === "PROD"
        ? "https://expapi-prodproxy.coherra.com/"
        : "https://expapi-stageproxy.coherra.com/";

const DEFAULT_VIDEOS_LENGHT = 12;
const DEFAULT_NOTIFICATIONS_LENGHT = 10;
const DEFAULT_PAGE = 0;

const API = axios.create({
  baseURL: URL,
  auth: {
    username: process.env.REACT_APP_AUTH_USERNAME,
    password: process.env.REACT_APP_AUTH_PASSWORD,
  },
});

//add user guid to all reques, make if dynamic from the local storage later on
API.interceptors.request.use((req) => {
  if (localStorage.getItem("user_guid") !== null) {
    req.params = {
      ...req.params,
      user_guid: localStorage.getItem("user_guid"),
    };
  }
  return req;
});

//starts with /data
export const getCarouselVideos = (size = DEFAULT_VIDEOS_LENGHT) =>
  API.get(`/data/carousel`, { params: { size } });

export const getCategories = (size = DEFAULT_VIDEOS_LENGHT) =>
  API.get(`/data/categories/videos`, { params: { size } });

export const getMoreCategoryVideos = (
  category,
  page = DEFAULT_PAGE,
  size = DEFAULT_VIDEOS_LENGHT
) => API.get(`/data/category/videos`, { params: { category, page, size } });

export const getFollowedFunds = (size = DEFAULT_VIDEOS_LENGHT) =>
  API.get(`/data/followedfunds/videos`, { params: { size } });

export const getWatchLaterVideos = (
  page = DEFAULT_PAGE,
  size = DEFAULT_VIDEOS_LENGHT
) => API.get(`/data/watchlater/videos`, { params: { size, page } });

export const getFunds = () => API.get(`/data/funds`);

//starts with /video
export const getVideo = (video_guid) =>
  API.get(`/video`, { params: { video_guid } });

export const likeVideo = (video_guid) =>
  API.post(`/video/like`, null, { params: { video_guid } });

export const unlikeVideo = (video_guid) =>
  API.delete(`/video/unlike`, { params: { video_guid } });

export const addToWatchLater = (video_guid) =>
  API.post(`/video/watchlater/add`, null, { params: { video_guid } });

export const removeFromWatchLater = (video_guid) =>
  API.delete(`/video/watchlater/remove`, { params: { video_guid } });

export const markVideoViewed = (video_guid) =>
  API.post(`/video/watched`, null, { params: { video_guid } });

export const getVideoComments = (
  video_guid,
  page,
  size = DEFAULT_VIDEOS_LENGHT
) => API.get(`/video/comments`, { params: { video_guid, page, size } });

export const submitComment = (video_guid, comment_body) =>
  API.post(`/video/comment`, { comment_body }, { params: { video_guid } });

export const deleteComment = (comment_id) =>
  API.delete(`/video/comment`, { params: { comment_id } });

export const editComment = (comment_id, comment_body) =>
  API.patch(`/video/comment`, { comment_body }, { params: { comment_id } });

export const likeComment = (comment_id) =>
  API.post(`/video/comment/like`, null, { params: { comment_id } });

export const unlikeComment = (comment_id) =>
  API.delete(`/video/comment/unlike`, { params: { comment_id } });

//starts with /fund
export const getFund = (fund_guid) =>
  API.get(`/fund2`, { params: { fund_guid } });

export const getFundVideos = (fund_guid, page, size = DEFAULT_VIDEOS_LENGHT) =>
  API.get(`/fund2/videos`, { params: { fund_guid, page, size } });

export const followFund = (fund_guid) =>
  API.post(`/fund2/follow`, null, { params: { fund_guid } });

export const unfollowFund = (fund_guid) =>
  API.delete(`/fund2/unfollow`, { params: { fund_guid } });

//starts with /user
export const getPreferedCategories = () =>
  API.get(`/user/preferences/categories`);

export const setNewCategoriesOrder = (orderedCategories) =>
  API.put(`/user/preferences/categories`, {
    categories_order: orderedCategories,
  });

export const getToken = () => API.get(`/user/token`);

export const authorize = (data) =>
  API.post(`/user/authorize`, null, { params: data });

export const getUserNotificationsSettings = () =>
  API.get(`/user/preferences/notifications`);

export const updateUserNotificationSetting = (preference_id, is_turn_on) =>
  API.patch(`/user/preferences/notifications`, null, {
    params: {
      preference_id,
      is_turn_on,
    },
  });

export const updateUserPreferences = (params) =>
  API.patch(`/user/preferences`, null, {
    params,
  });

export const getNotifications = (
  page = DEFAULT_PAGE,
  size = DEFAULT_NOTIFICATIONS_LENGHT
) => API.get(`/user/notifications`, { params: { page, size } });

export const markNotificationsRead = (notifications_ids) =>
  API.patch(`/user/notifications/markread`, { notifications_ids }, null);

export const createErrorLog = (text_body, logservice = 6, loglevel = 2) =>
  API.post(`/user/logs`, { text_body }, { params: { logservice, loglevel } });

//starts with /company
export const getCompany = (company_guid) =>
  API.get(`/company`, { params: { company_guid } });

export const getCompanyFunds = (
  company_guid,
  page = DEFAULT_PAGE,
  size = DEFAULT_VIDEOS_LENGHT
) => API.get(`/company/funds`, { params: { company_guid, page, size } });

export const getCompanyFundsVideos = (
  company_guid,
  page = DEFAULT_PAGE,
  size = DEFAULT_VIDEOS_LENGHT
) => API.get(`/company/funds/videos`, { params: { company_guid, page, size } });

export const getCompanyPlaylistVideos = (
  playlist_guid,
  page = DEFAULT_PAGE,
  size = DEFAULT_VIDEOS_LENGHT
) =>
  API.get(`/company/playlist/videos`, {
    params: { playlist_guid, page, size },
  });

export const followCompany = (company_guid) =>
  API.post(`/company/follow`, null, { params: { company_guid } });

export const unfollowCompany = (company_guid) =>
  API.delete(`/company/unfollow`, { params: { company_guid } });
