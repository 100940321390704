import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  image: {
    height: `${theme.custom.pxToRem(40)}`,
    width: `${theme.custom.pxToRem(150)}`,
    objectFit: "contain",
    imageRendering: "-webkit-optimize-contrast",
  },
}));
