import Banner from "assets/fallbacks/ARK_banner.png";
import RearrangeIcon from "assets/icons/icons/rearrangeCategories.svg";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Card = ({ category, index }) => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");

  const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "white" : "white",
    marginBottom: `0 !important`,
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable draggableId={category.id + ""} index={index}>
      {(provided, snapshot) => (
        <Grid item xs={12} md={10} lg={8}>
          <div
            className={classes.card}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className={classes.dragIconWrapper}>
              <img
                src={RearrangeIcon}
                alt="drag icon"
                className={classes.dragIcon}
              />
            </div>
            <div className={classes.infoWrapper}>
              <img
                src={category.image_url || Banner}
                alt="illustration"
                className={classes.picture}
              />
              <div className={classes.overlay}></div>
              <div className={classes.textWrapper}>
                <Typography variant="h2" align="center">
                  {category.title}
                </Typography>
                {!isPhone && (
                  <Typography
                    variant={isPhone ? "body2" : "body1"}
                    align="center"
                  >
                    {category.description}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Grid>
      )}
    </Draggable>
  );
};

export default Card;
