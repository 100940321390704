import { createErrorLog } from "api/api";

//used for extracting last part of the url
export const getLastUrlItem = (path) => {
  if (path[path.length - 1] === "/") return "/";
  else return path.substring(path.lastIndexOf("/") + 1);
};

//returns country name based on the country code from the country list
export const getCountryNameByCode = (arr, code) => {
  let country = {};
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i].code === code) {
      country = arr[i];
      break;
    }
  }
  return country.name;
};

export const getFirstnameAndLastname = (name) => {
  let firstName = "";
  let lastName = "";
  let wasSpace = false;

  for (let i = 0; i < name.length; i++) {
    if (wasSpace) lastName += name[i];
    else {
      if (name[i] === " ") wasSpace = true;
      else firstName += name[i];
    }
  }

  return { firstName, lastName };
};

export const isStagingOrDevelopment = () => {
  const url = window.location.origin;
  const searchedPattern = new RegExp(`localhost|dev|stage|staging`, "gi");
  return searchedPattern.test(url);
};

export const trim = (text) => {
  //removes all empty spaces, lines and new lines from beginning and the end of the string
  return text.replace(/(^\s*\n*\t*)|(\n*\s*\t*$)/gm, "");
};

export const constructAndSendError = (
  error = "",
  functionName = "",
  endpointName = "",
  params = [],
  body = "",
  logservice,
  loglevel
) => {
  //add user guid from LS, app verion from .env
  const errorMessage = `Server response message: ${error}, App version: ${
    process.env.REACT_APP_APP_ID
  }, App url: ${
    window.location
  }, Endpoint: ${endpointName}, User guid: ${localStorage.getItem(
    "user_guid"
  )}, Params: ${params.toString()}, Body: ${body} Function calling endpoint: ${functionName}`;
  createErrorLog(errorMessage, logservice, loglevel);
};

export const numberWithCommas = (number = 0) => {
  if (number === null || number === undefined) number = 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const numFormatter = (num) => {
  if (num === null || num === undefined) return num;
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const splitEventsByTime = (events = []) => {
  if (events === null) events = [];
  const upcomingEvents = [];
  const pastEvents = [];
  const currentTime = Date.now();
  events.forEach((event) => {
    //need to convert time from db to Date
    const eventTimeInEpoch = Date.parse(event.event_time);
    //upcoming event
    if (eventTimeInEpoch >= currentTime) upcomingEvents.push(event);
    //past events
    else pastEvents.push(event);
  });

  return [upcomingEvents, pastEvents];
};

export const getLocalDDMMMhhmmFromUTC = (timeInUTC) => {
  if (timeInUTC === null) return;
  const unixEpochTime = Date.parse(timeInUTC);
  const localDateTime = new Date();
  localDateTime.setTime(unixEpochTime);
  const day = localDateTime.toLocaleString("default", { day: "2-digit" });
  const month = localDateTime.toLocaleString("default", { month: "short" });
  const hours = localDateTime.getHours();
  const minutes = localDateTime.getMinutes();
  //example return 14 Jan - 16:00
  return `${day} ${month} - ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};
