import React from "react";
import PlaylistDetails from "./subpages/PlaylistDetails/PlaylistDetails";
import PlaylistsDashboard from "./subpages/PlaylistsDasboard/PlaylistDashboard";
import { Switch, Route } from "react-router-dom";
import { COMPANY, VIDEOS } from "navigation/constants";

const Playlists = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${COMPANY}/:companyGuid${VIDEOS}`}
        component={PlaylistsDashboard}
      />
      <Route
        exact
        path={`${COMPANY}/:companyGuid${VIDEOS}/:playlistGuid`}
        component={PlaylistDetails}
      />
    </Switch>
  );
};

export default Playlists;
