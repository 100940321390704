import React from "react";
import { Switch, Route } from "react-router-dom";
import { VIDEO_CHANNEL, ABOUT, VIDEOS } from "navigation/constants";
import Videos from "../../subpages/Videos/Videos";
import About from "../../subpages/About/About";

const Navigation = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${VIDEO_CHANNEL}/:guid${VIDEOS}`}
        component={Videos}
      />
      <Route exact path={`${VIDEO_CHANNEL}/:guid${ABOUT}`} component={About} />
    </Switch>
  );
};

export default Navigation;
