import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  page: {
    paddingTop: `${theme.custom.pxToRem(80)} !important`,
    backgroundColor: theme.palette.background.secondary,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(48)} !important`,
    },
  },
}));
