import React from "react";
import { ABOUT, VIDEOS } from "navigation/constants";
import { ReactComponent as PlayIcon } from "assets/icons/icons/media.svg";
import { ReactComponent as BrochureIcon } from "assets/icons/icons/brochure.svg";
import TabBarItem from "./TabBarItem/TabBarItem";
import { getLastUrlItem } from "shared_functions/shared_functions";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";
import { useParams } from "react-router";
//MUI
import useStyles from "./styles";
//REDUX
import {
  followVideoChannel,
  unfollowVideoChannel,
} from "redux/ducks/video_channel.js";
import { useSelector } from "react-redux";

const tabBarItems = [
  {
    icon: PlayIcon,
    text: "Videos",
    path: VIDEOS,
  },
  {
    icon: BrochureIcon,
    text: "About us",
    path: ABOUT,
  },
];

const TabBar = () => {
  const classes = useStyles();
  const path = window.location.pathname;
  const { guid: videoChannelGuid } = useParams();
  const isFollowing = useSelector(
    (state) => state.data.video_channel.is_user_following
  );

  return (
    <section className={classes.navigation} data-cy="tabbar">
      <div className={classes.tabsWrapper}>
        {tabBarItems.map((item, index) => (
          <TabBarItem
            Icon={item.icon}
            text={item.text}
            isActive={
              `/${getLastUrlItem(path)}` === `${item.path}` ||
              `/${getLastUrlItem(path)}` === `${item.path}/`
            }
            key={index}
            link={item.path}
          />
        ))}
      </div>

      <FollowButton
        className={classes.button}
        guid={videoChannelGuid}
        onFollow={followVideoChannel}
        onUnfollow={unfollowVideoChannel}
        isFollowing={isFollowing}
      />
    </section>
  );
};

export default TabBar;
