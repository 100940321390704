import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  stepper: {
    padding: `${theme.custom.pxToRem(16)} !important`,
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]}`,
  },
  logoWrapper: {
    padding: `0 ${theme.custom.pxToRem(48)} `,
    borderBottom: `1px solid ${theme.palette.grey_tone["200"]}`,
    display: "flex",
    height: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(48)} !important`,
      padding: `0 ${theme.custom.pxToRem(32)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(16)} `,
    },
    alignItems: "center",
  },
  logo: {
    height: `${theme.custom.pxToRem(16)} !important`,
  },
  pageTitle: {
    padding: `${theme.custom.pxToRem(16)} `,
    color: theme.custom.palette.primary,
  },
}));
