import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  smallButton: {
    ...theme.custom.largeButton,
    width: "100% !important",
  },
  main: {
    paddingTop: 80,
    backgroundColor: `${theme.palette.background.secondary}`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(48)} !important`,
    },
  },
}));
