import React from "react";
// MUI
import useStyles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

//note that this is just a page, all logic and redirecting is handled from the async functiuon on login page
const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
