import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  labelWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: `${theme.custom.pxToRem(8)} !important`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: `${theme.custom.pxToRem(0)} !important`,
    },
  },
  infoLabel: {
    color: `${theme.palette.grey_tone["400"]}`,
    display: "flex",
    alignItems: "center",
  },
  actionButtonsWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      marginTop: `${theme.custom.pxToRem(8)} !important`,
    },
  },
  alertText: {
    fontWeight: "300 !important",
    "&:hover a": {
      fontWeight: "bold !important",
    },
  },
  alertLink: {
    color: theme.palette.text.secondary,
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginRight: `${theme.custom.pxToRem(4)} !important`,
    },
    "& p": {
      fontWeight: "bold",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(6)} !important`,
    },
  },
  videoDescription: {
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    "& .clamp-lines__button": {
      padding: `${theme.custom.pxToRem(10)} 0 0 0`,
      border: "none",
      background: "none",
      cursor: "pointer",
      fontFamily: "ProximaNovaSemibold",
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: `${theme.custom.pxToRem(16)} !important`,
      lineHeight: `${theme.custom.pxToRem(24)} !important`,
      letterSpacing: `${theme.custom.pxToRem(0.18)} !important`,
      color: theme.palette.grey_tone["400"],
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.custom.pxToRem(14)} !important`,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: `${theme.custom.pxToRem(12)} !important`,
      },
    },
    "& .clamp-lines__button:hover": {
      textDecoration: "underline",
    },
  },
}));
