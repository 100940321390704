import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  headerBagde: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 40,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  badge: {
    filter: `drop-shadow(1px 2px 25px rgba(102, 126, 234, 0.2))`,
  },
  bagdeText: {
    paddingLeft: 16,
  },
  radioGroup: {
    marginBottom: 0,
  },
  btn: {
    marginBottom: 16,
  },
  form: {
    marginBottom: 16,
  },
}));
