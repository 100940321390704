import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainSection: {
    width: "100% !important",
    minHeight: "75vh",
    margin: "0 !important",
    background: `${theme.palette.background.vertical_gradient}`,
    display: "flex",
  },
  socialModal: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)} 0px !important`,
      margin: `${theme.custom.pxToRem(70)} 0`,
    },
    padding: `${theme.custom.pxToRem(48)} 0px !important`,
    borderRadius: `${theme.custom.pxToRem(12)}!important`,
    filter: `drop-shadow(${theme.shadows[2]}) !important`,
    margin: `${theme.custom.pxToRem(100)} 0 `,
  },
  mainGrid: {
    width: "90vw",
    height: "100%",
    margin: "auto",
  },
}));
