import React from "react";
import { constructAnonymousUserObj } from "./functions";
import userTypes from "./userTypes";
//REDUX
import { useDispatch } from "react-redux";
import { authorize } from "redux/ducks/user";

//local storage returns NULL if key can not be found
//this component renders only once when the platform is first time loaded
const Auth = () => {
  const dispatch = useDispatch();
  //case we have investors token in the localStorage
  if (localStorage.getItem("investor_token") !== null) {
    // console.log("path:logged user token is present");
    const data = {};
    data.access_token = localStorage.getItem("investor_token");
    data.token_type = localStorage.getItem("token_type");
    dispatch(authorize(data));
  }
  //case we have anonymous user token in the localStorage
  else if (localStorage.getItem("anonym_token") !== null) {
    // console.log("path:anonymous user token is present");
    const data = {};
    data.access_token = localStorage.getItem("anonym_token");
    localStorage.setItem("token_type", userTypes.anonymous);
    data.token_type = userTypes.anonymous;
    dispatch(authorize(data));
  }
  //case user is completly uknown
  else {
    // console.log("path:completly unknown user");
    constructAnonymousUserObj().then((result) => {
      localStorage.setItem("anonym_token", result.access_token);
      localStorage.setItem("token_type", userTypes.anonymous);
      dispatch(authorize(result));
    });
  }

  return <></>;
};

export default Auth;
