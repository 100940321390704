import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  // wrapper: {
  //   maxWidth: theme.custom.pxToRem(800),
  //   marginLeft: "auto",
  //   marginRight: "auto",
  //   padding: `0 ${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(
  //     48
  //   )} ${theme.custom.pxToRem(16)}`,
  // },
  headerBagde: {
    display: "flex",
    flexDirection: "row",
    marginBottom: `${theme.custom.pxToRem(48)}`,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  badge: {
    filter: `drop-shadow(1px 2px 25px rgba(102, 126, 234, 0.2))`,
  },
  bagdeText: {
    paddingLeft: 16,
  },
  avatar: {
    marginBottom: theme.custom.pxToRem(40),
    height: theme.custom.pxToRem(96),
    width: theme.custom.pxToRem(96),
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("xs")]: {
      margin: `${theme.custom.pxToRem(48)} auto ${theme.custom.pxToRem(24)}`,
    },
  },
  form: {
    maxWidth: `${theme.custom.pxToRem(900)}`,
    paddingBottom: `2rem`,
    "& > div": {
      [theme.breakpoints.down("xs")]: {
        gap: theme.custom.pxToRem(16),
      },
    },
  },
  formField: {
    marginBottom: `${theme.custom.pxToRem(16)} !important`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0 !important",
    },
  },
  root: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: `${theme.custom.palette.text_dark} !important`,
    },
  },
  autoCompleteInput: {
    padding: `${theme.custom.pxToRem(5)} 0 !important`,
  },
}));
