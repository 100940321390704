import React from "react";
import { Switch, Route } from "react-router-dom";
import Profile from "../../subpages/Profile/Profile";
import Notification from "../../subpages/Notifications/Notifications";
import DeleteAccount from "../../subpages/DeleteAccount/DeleteAccount";
import {
  USER_SETTINGS_NOTIFICATIONS,
  USER_SETTINGS_DELETE_ACCOUNT,
  USER_SETTINGS_PROFILE,
} from "navigation/constants";
// MUI
import useStyles from "./styles";

const Navigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentLayout}>
      <Switch>
        <Route exact path={`${USER_SETTINGS_PROFILE}`}>
          <Profile />
        </Route>
        <Route exact path={`${USER_SETTINGS_NOTIFICATIONS}`}>
          <Notification />
        </Route>
        <Route exact path={`${USER_SETTINGS_DELETE_ACCOUNT}`}>
          <DeleteAccount />
        </Route>
      </Switch>
    </div>
  );
};

export default Navigation;
