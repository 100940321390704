import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import VideoSnippet from "shared_components/VideoSnippet/VideoSnippet";
import Link from "shared_components/Link/Link";
import { FUND, VIDEOS } from "navigation/constants";
import clsx from "clsx";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { getMoreCategoryVideos } from "redux/ducks/categories";
import { useDispatch } from "react-redux";

const DEFAULT_SNIPPET = {
  id: 170,
  video_guid: "b1e34276-91ec-424c-80af-04e259ee9e7e",
  clip_id: 612636051,
  thumbnail_pic:
    "https://i.vimeocdn.com/video/1250108569-bddd93f2a70ed56de476b5be330d59502782d948dcdc082f55101e1ead952f9c-d_1920x1080",
  duration: 518,
  views_count: 1,
  title:
    "These countries bought, and sold, the most gold last year; Jeff Christian explains",
  time_stamp: "2021-08-31T16:09:02.480307+00:00",
  primary_fund_name: "UNIT TEST FUND - DO NOT DELETE",
  primary_fund_id: 1,
  primary_fund_guid: 1,
  primary_fund_pic:
    "https://api-staging.coherra.com:5023/funds/1/1/702135b3-4933-455c-bf7a-96b886d7f194.jpg",
  primary_fund_visible: true,
  company_pic:
    "https://api-staging.coherra.com:5023/funds/1/6/5b1bd7e5-2339-4338-96ca-c03cf678ea1d.png",
};

const Slider = ({
  showFundSwiperslide,
  category: { videos, id, paging, fund_pic, fund_guid },
}) => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const isTablet = useMediaQuery("(max-width:959px)");
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const VIDEOS_SIZE = 10;
  let slides =
    videos !== null
      ? videos.map((video) => {
          const { snippet } = video;
          return (
            <SwiperSlide
              key={snippet.video_guid}
              tag="li"
              className={classes.swiperSlide}
            >
              <VideoSnippet snippet={snippet} />
            </SwiperSlide>
          );
        })
      : [];

  //insert first swiperslide as link to fund if followed funds are displayed
  showFundSwiperslide &&
    slides.unshift(
      <SwiperSlide
        tag="li"
        className={clsx(classes.swiperSlide, classes.firstSlide)}
        key={-1}
      >
        {/* need to define some default snippet in order to define size of the snippet */}
        <VideoSnippet snippet={DEFAULT_SNIPPET}></VideoSnippet>
        <img src={fund_pic} alt="fund" className={classes.fundPicture} />
        <div className={classes.overlay}></div>
        <Link to={`${FUND}/${fund_guid}${VIDEOS}`} className={classes.fundlink}>
          <Typography variant="h2" color="textSecondary">
            View Fund
          </Typography>
        </Link>
      </SwiperSlide>
    );

  const fetchMoreVideos = async () => {
    //return if there are no more videos in category
    if (paging.total <= paging.page * VIDEOS_SIZE + VIDEOS_SIZE) return;
    dispatch(getMoreCategoryVideos(id, paging.page + 1, VIDEOS_SIZE));
  };
  return (
    <>
      {/* TODO: check for null properties and in that case do not display the swiper*/}

      <Swiper
        id="main"
        ref={swiperRef}
        spaceBetween={24}
        slidesPerView={4.25}
        // disable paging on followed funds slider
        onReachEnd={showFundSwiperslide ? () => {} : fetchMoreVideos}
        grabCursor={true}
        className={classes.swiper}
        breakpoints={{
          0: {
            slidesPerView: 1.35,
          },
          450: {
            slidesPerView: 2.35,
          },
          750: {
            slidesPerView: 3.35,
          },
          1200: {
            slidesPerView: 4.35,
          },
        }}
        slidesOffsetBefore={isPhone ? -32 : isTablet ? -16 : 0}
        slidesOffsetAfter={isPhone ? 64 : isTablet ? 80 : 96}
      >
        {slides}
      </Swiper>
    </>
  );
};

export default Slider;
