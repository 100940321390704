import React from "react";
import Loader from "shared_components/Loader/Loader";
import ListSection from "../ListSection/ListSection";
import VideoSection from "../VideoSection/VideoSection";
import EmptyState from "../EmptyState/EmptyState";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
//REDUX
import { getWatchLaterVideos } from "redux/ducks/watch_later";
import { useDispatch, useSelector } from "react-redux";

const MainContent = () => {
  const dispatch = useDispatch();
  const { data: videos, is_loading } = useSelector(
    (state) => state.data.watch_later
  );
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const activeVideo = videos[activeIndex];
  const { user_guid } = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      dispatch(getWatchLaterVideos());
    }
  }, [dispatch, user_guid]);

  //events handlers
  const handleNewSelection = (index) => {
    setActiveIndex(index);
  };

  return (
    <main className={classes.main}>
      <div className={classes.pageTitleWrapper}>
        <Typography variant="h1" className={classes.pageTitle}>
          Watch Later
        </Typography>
      </div>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          {videos.length > 0 ? (
            <section className={classes.section}>
              <ListSection
                handleNewSelection={handleNewSelection}
                activeIndex={activeIndex}
                videos={videos}
              />
              <VideoSection activeVideo={activeVideo} />
            </section>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </main>
  );
};

export default MainContent;
