import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_VIDEOS = "carousel/SET_VIDEOS";
const START_LOADING = "carousel/START_LOADING";
const STOP_LOADING = "carousel/STOP_LOADING";

const initialState = {
  is_loading: true,
  videos: [],
};

export const getVideos = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const {
      data: { carousel: data },
    } = await api.getCarouselVideos();
    dispatch({ type: SET_VIDEOS, payload: data });
    dispatch(stopLoading());
  } catch (error) {
    constructAndSendError(
      error.message,
      "carousel/getVideos",
      "/data/carousel"
    );
    console.log(error);
  }
};

export const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEOS: {
      return { ...state, videos: action.payload };
    }
    case START_LOADING: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING: {
      return { ...state, is_loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
