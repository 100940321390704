import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: `${theme.custom.pxToRem(12)}`,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
    position: "relative",
  },
  disabledOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "white",
    opacity: "0.3",
  },
  picture: {
    height: `${theme.custom.pxToRem(120)}`,
    width: "100%",
    objectFit: "cover",
  },
  timeWrapper: {
    padding: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(16)}`,
  },
  time: {
    ...theme.custom.label,
    color: theme.palette.primary.main,
    marginBottom: `${theme.custom.pxToRem(8)}`,
    fontFamily: "ProximaNovaSemibold",
  },
  buttonWrapper: {
    display: "flex",
    padding: `${theme.custom.pxToRem(16)} 0`,
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    color: "unset",
    textDecoration: "none",
  },
  button: {
    width: `${theme.custom.pxToRem(144)}`,
  },
}));
