import React from "react";
import Wave from "assets/decoration/WaveVertical.svg";
import { useParams } from "react-router-dom";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";

//MUI
import Loader from "shared_components/Loader/Loader";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles";

//REDUX
import {
  getVideoChannel,
  followVideoChannel,
  unfollowVideoChannel,
} from "redux/ducks/video_channel";
import { useDispatch, useSelector } from "react-redux";

const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const Banner = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const dispatch = useDispatch();
  const videoChannel = useSelector((state) => state.data.video_channel);
  const { user_guid } = useSelector((state) => state.auth.user);
  const isFollowing = useSelector(
    (state) => state.data.video_channel.is_user_following
  );
  const { guid: videoChannelGuid } = useParams();

  React.useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      //check if video channel is in redux, if not get the data
      if (videoChannelGuid !== videoChannel.guid) {
        dispatch(getVideoChannel(videoChannelGuid));
      }
    }
  }, [user_guid]);

  //events

  return (
    <>
      {videoChannel.is_loading ? (
        <Loader
          size={40}
          height={
            // isPhone ? "18.9375rem" : isTablet ? "12.1875rem" : "22.4375rem"
            isPhone ? "18.375rem" : "20rem"
          }
        />
      ) : (
        <div className={classes.banner}>
          <div className={classes.information}>
            <div className={classes.channelLogoWrapper}>
              <img
                src={videoChannel.channel_logo}
                alt="logo"
                className={classes.channelLogo}
              />
            </div>
            <Typography
              variant="h2"
              align="center"
              className={classes.channelName}
            >
              {videoChannel.name}
            </Typography>
            <Typography variant="h4">
              <span className={classes.followersCount}>
                {numberWithCommas(videoChannel.followers_count)}
              </span>{" "}
              <span className={classes.followersLabel}>Followers</span>
            </Typography>
            {isPhone && (
              <FollowButton
                className={classes.button}
                guid={videoChannelGuid}
                onFollow={followVideoChannel}
                onUnfollow={unfollowVideoChannel}
                isFollowing={isFollowing}
              />
            )}
          </div>
          <div className={classes.pictureWrapper}>
            <img
              src={videoChannel.channel_pic}
              alt="channel banner"
              className={classes.fundPic}
            />
            {!isPhone && <img src={Wave} alt="wave" className={classes.wave} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
