import React from "react";
import Navbar from "shared_components/Navbar/Navbar";
import MainContent from "./components/MainContent/MainContent";
import FinishedRegCheck from "auth/FinishedRegCheck";

const Video = (props) => {
  return (
    <>
      <FinishedRegCheck />
      <Navbar />
      <MainContent {...props} />
    </>
  );
};

export default Video;
