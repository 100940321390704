export const ROOT = "/";
export const LOGIN = "/login";
export const TEST = "/test";
export const LINKEDIN = "/linkedin";
export const SETTINGS = "/settings";
export const USER_SETTINGS_PROFILE = "/settings/profile";
export const USER_SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const USER_SETTINGS_DELETE_ACCOUNT = "/settings/delete-acocunt";
export const FOLLOWING = "/following";
export const ONBOARDING = "/onboarding";
export const ONBOARDING_SUCCESS = "/onboarding/success";
export const VIDEO = "/video";
export const FUND = "/fund";
export const ABOUT = "/about"; //subpage of fund page
export const EVENTS = "/events";
export const CONTACT = "/contact"; //subpage of fund page
export const WHERE_TO_INVEST = "/where-to-invest"; //subpage of fund page
export const VIDEOS = "/videos"; //subpage of fund page
export const REORDER_CATEGORIES = "/reorder-categories";
export const WATCH_LATER = "/watch-later";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const LOADER = "/loader";
export const VIDEO_CHANNEL = "/video-channel";
export const COMPANY = "/company";
export const FUNDS = "/funds";
export const PLAYLISTS = "/playlists";
