import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  FUND,
  ABOUT,
  WHERE_TO_INVEST,
  VIDEOS,
  CONTACT,
  EVENTS,
} from "navigation/constants";
import Videos from "../../subpages/Videos/Videos";
import About from "../../subpages/About/About";
import Contact from "../../subpages/Contact/Contact";
import Events from "../../subpages/Events/Events";
import WhereToInvest from "../../subpages/WhereToInvest/WhereToInvest";

const Navigation = () => {
  return (
    <Switch>
      <Route exact path={`${FUND}/:guid${VIDEOS}`} component={Videos} />
      <Route exact path={`${FUND}/:guid${ABOUT}`} component={About} />
      <Route exact path={`${FUND}/:guid${CONTACT}`} component={Contact} />
      <Route exact path={`${FUND}/:guid${EVENTS}`} component={Events} />
      <Route
        exact
        path={`${FUND}/:guid${WHERE_TO_INVEST}`}
        component={WhereToInvest}
      />
    </Switch>
  );
};

export default Navigation;
