import React from "react";
import clsx from "clsx";
//MUI
import useStyles from "./styles";

const CustomEllipsis = ({ lines = 1, component = "p", text, className }) => {
  const style = { lines };
  const classes = useStyles(style);
  const Tag = component;
  return <Tag className={clsx(classes.root, className)}>{text}</Tag>;
};

export default CustomEllipsis;
