import React from "react";
import Link from "shared_components/Link/Link";
import palette from "styles/palette.js";
import useStyles from "./styles";
import { LOGIN } from "navigation/constants";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: palette.grey_tone[0],
    color: palette.font,
    maxWidth: 400,
    padding: 16,
    border: palette.grey_tone[200],
    borderRadius: 12,
    boxShadow: palette.shadows.lvl2,
  },
}))(Tooltip);

export default function LoginTooltip({
  children,
  title,
  description,
  placement,
}) {
  const classes = useStyles();

  return (
    <div>
      <StyledTooltip
        placement={placement}
        interactive
        title={
          <React.Fragment>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
            <Divider orientation="horizontal" className={classes.divider} />
            <Link
              to={(location) => {
                return {
                  ...location,
                  pathname: LOGIN,
                  state: location.pathname,
                };
              }}
              className={classes.link}
            >
              <Typography variant="body1" className={classes.textButton}>
                Login / Sign-up
              </Typography>
            </Link>
          </React.Fragment>
        }
      >
        {children}
      </StyledTooltip>
    </div>
  );
}
