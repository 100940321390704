import React from "react";
import Link from "shared_components/Link/Link";
import { ROOT } from "navigation/constants";
import emptyVideosStateIcon from "assets/icons/icons/emptyVideosState.svg";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "shared_components/InputComponents/Button/ButtonStyled";

const EmptyState = () => {
  const classes = useStyles();
  return (
    <section className={classes.emptyStateWrapper}>
      <div className={classes.itemsWrapper}>
        <img src={emptyVideosStateIcon} alt="icon" />
        <Typography
          className={classes.emptyStateTitle}
          variant="h2"
          align="center"
        >
          You have no videos on your watch list
        </Typography>
        <Typography className={classes.emptyStateText} variant="h3">
          You can add videos to watch later
        </Typography>
        <Link className={classes.reoderLink} to={ROOT}>
          <Button size="lg" className={classes.emptyStateButton}>
            Explore videos
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default EmptyState;
