import React from "react";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import Dot from "assets/decoration/Dot.svg";
import Link from "shared_components/Link/Link";
import { FUND, VIDEO, VIDEOS } from "navigation/constants";
import clsx from "clsx";
import time from "video-time";
import MoreOptions from "assets/icons/icons/more_options.svg";
import RedClose from "assets/icons/icons/red_close_circle.svg";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { removeFromWatchLater } from "redux/ducks/watch_later";
import { useDispatch } from "react-redux";

const VideoCard = ({
  snippet: {
    thumbnail_pic,
    title,
    video_guid,
    duration,
    views_count,
    primary_fund_guid,
    primary_fund_name,
  },
  selected,
  index,
  handleNewSelection,
  paging,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isViewportUnder400 = useMediaQuery("(max-width:400px)");
  const isPhone = useMediaQuery("(max-width:599px)");
  const style = {
    selected,
    isPhone,
  };
  const classes = useStyles(style);

  //events handlers
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveFromWatchlater = (e) => {
    //this is necessary to fix propagation bug
    e.stopPropagation();
    handleClose();
    handleNewSelection(0);
    dispatch(removeFromWatchLater(video_guid, paging.page + 1));
  };
  //mui stuff to make popover working
  const open = Boolean(anchorEl);
  const type = open ? "simple-popover" : undefined;

  return (
    <div className={classes.wrapper} onClick={() => handleNewSelection(index)}>
      <div className={classes.thumbnailWrapper}>
        {/* done to support card as a link when on phone and to support selection when on bigger devices */}
        {isPhone ? (
          <Link to={`${VIDEO}/${video_guid}`}>
            <img
              src={thumbnail_pic}
              alt="thumbnail"
              className={classes.thumbnail}
            />
            <div className={classes.duration}>{time(duration)}</div>
          </Link>
        ) : (
          <>
            <img
              src={thumbnail_pic}
              alt="thumbnail"
              className={classes.thumbnail}
            />
            <div className={classes.duration}>{time(duration)}</div>
          </>
        )}
      </div>
      <div className={classes.dataWrapper}>
        {/* done to support card as a link when on phone and to support selection when on bigger devices */}
        {isPhone ? (
          <Link to={`${VIDEO}/${video_guid}`} className={classes.title}>
            <Typography variant="h4">
              <CustomEllipsis lines={2} text={title} component="div" />
            </Typography>
          </Link>
        ) : (
          <Typography variant="h4">
            <CustomEllipsis lines={2} text={title} component="div" />
          </Typography>
        )}

        <div className={clsx(classes.infoWrapper, classes.label)}>
          {isViewportUnder400 ? null : (
            <>
              <span>{views_count} Views</span>
              <img src={Dot} alt="dot" className={classes.dot} />
            </>
          )}

          <Link
            to={`${FUND}/${primary_fund_guid}${VIDEOS}`}
            className={classes.fundLink}
          >
            {primary_fund_name}
          </Link>
        </div>
      </div>
      <div className={classes.iconWrapper}>
        <IconButton
          onClick={handleOpen}
          aria-describedby={type}
          classes={{ root: classes.icon }}
        >
          <img src={MoreOptions} alt="more options" />
        </IconButton>
        <Popover
          // this fixes error with elevation
          elevation={0}
          id={type}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.popover,
          }}
        >
          <div
            className={classes.popoverBodyWrapper}
            onClick={handleRemoveFromWatchlater}
          >
            <img src={RedClose} alt="remove" />
            <Typography varaint="h4" className={classes.popoverText}>
              Remove from Watch List
            </Typography>
            <div className={classes.square}></div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default VideoCard;
