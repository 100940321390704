import React from "react";
import Loader from "shared_components/Loader/Loader";
//MUI
import useStyles from "./styles";
//REDUX
import { useSelector } from "react-redux";
import EventsSection from "shared_components/EventsSection/EventsSection";

const Events = () => {
  const classes = useStyles();
  const company = useSelector((state) => state.data.company);

  return (
    <div className={classes.backGround}>
      {company.is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <EventsSection
            title="Upcoming Events"
            events={company.upcoming_events}
            isDisabled={false}
          />
          <EventsSection
            title="Past Events"
            events={company.past_events}
            isDisabled={true}
          />
        </>
      )}
    </div>
  );
};

export default Events;
