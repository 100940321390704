import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  backGround: {
    backgroundColor: `${theme.palette.background.secondary}`,
  },
  section: {
    paddingTop: `${theme.custom.pxToRem(48)}`,
    paddingLeft: `${theme.custom.pxToRem(48)}`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(32)}`,
      paddingLeft: `${theme.custom.pxToRem(32)}`,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: `${theme.custom.pxToRem(16)}`,
      paddingLeft: `${theme.custom.pxToRem(16)}`,
    },
  },
  title: {
    marginBottom: `${theme.custom.pxToRem(24)}`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)}`,
    },
  },
  grid: {
    width: `calc(100% - ${theme.spacing(9)}px) !important`,
    margin: `auto`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.custom.pxToRem(40)}) !important`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.custom.pxToRem(8)}) !important`,
    },
  },
}));
