import React from "react";
import PinMapIcon from "assets/icons/icons/pin_map.svg";
import LinkIcon from "assets/icons/icons/link_black.svg";
import CalendarIcon from "assets/icons/icons/calendar.svg";
import { trim } from "lodash";
import moment from "moment";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
//REDUX
import { useSelector } from "react-redux";

const DetailsCard = () => {
  const classes = useStyles();
  const joined = useSelector(
    (state) => state.data.video_channel.details.joined
  );
  const address = useSelector(
    (state) => state.data.video_channel.details.address
  );
  const externalWebsiteLink = useSelector(
    (state) => state.data.video_channel.details.external_website_link
  );

  return (
    <Card className={classes.card} data-cy="detailsCard">
      <div className={classes.cardWrapper}>
        <Typography variant="h3" className={classes.detailsTitle}>
          Details
        </Typography>

        <div className={classes.detailWrapper} data-cy="fundJoined">
          <img src={CalendarIcon} alt="calendar" />
          <Typography variant="body1">
            Joined {moment(joined).format("MMM YYYY") || "N/A"}
          </Typography>
        </div>

        <div className={classes.detailWrapper} data-cy="fundAddress">
          <img src={PinMapIcon} alt="pin map" />
          <Typography variant="body1">{trim(address) || "N/A"}</Typography>
        </div>
        <div className={classes.detailWrapper} data-cy="fundExternalLink">
          <img src={LinkIcon} alt="link" />
          <Typography variant="body1">
            <a
              href={externalWebsiteLink}
              target="_blank"
              rel="noreferrer"
              className={classes.externalLink}
            >
              {trim(externalWebsiteLink) || "N/A"}
            </a>
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default DetailsCard;
