import React from "react";
import Header from "./components/Header/Header";
import CategoriesDnD from "./components/CategoriesDnD/CategoriesDnD";
import FinishedRegCheck from "auth/FinishedRegCheck";
//MUI
import useStyles from "./styles";

const ReorderCategories = () => {
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <FinishedRegCheck />
      <div className={classes.wrapper}>
        <Header />
        <CategoriesDnD />
      </div>
    </main>
  );
};

export default ReorderCategories;
