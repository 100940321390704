import React from "react";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const Following = ({ title, bodyText }) => {
  const classes = useStyles();
  return (
    <div className={classes.pageHeader}>
      <div className={classes.text}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="body1">{bodyText}</Typography>
      </div>
    </div>
  );
};

export default Following;
