import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cardGrid: {
    width: "100%",
  },
  card: {
    display: "flex",
    filter: `drop-shadow(${theme.shadows[3]})`,
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "hidden",
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  dragIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
  },
  dragIcon: {
    // padding: `${theme.custom.pxToRem(19)} !important`,
    padding: `${theme.custom.pxToRem(20)}`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(20)} ${theme.custom.pxToRem(8)}`,
    },

    cursor: "grab",
  },
  infoWrapper: {
    height: `${theme.custom.pxToRem(152)}`,
    position: "relative",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      height: `${theme.custom.pxToRem(72)}`,
    },
  },
  picture: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.6)",
  },
  textWrapper: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("md")]: {
      width: `80%`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `95%`,
    },
    width: "70%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    margin: "auto",
  },
}));
