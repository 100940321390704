import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BellIcon from "assets/icons/icons/bell.svg";
import Loader from "shared_components/Loader/Loader";
//MUI
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import NotificationListItem from "./NotifcationListItem/NotificationListItem";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import useStyles from "./styles";
//REDUX
import {
  markNotificationsRead,
  updateNotificationsRead,
} from "redux/ducks/user";
import { getMoreNotifications } from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";

export default function NotificationPopOver() {
  const classes = useStyles();
  const NOTIFICATIONS_SIZE = 10;
  const dispatch = useDispatch();
  const [notificationAnchor, setNotifcationAnchor] = React.useState(null);
  const unread = useSelector((state) => state.auth.user.notifications.unread);
  const notifications = useSelector(
    (state) => state.auth.user.notifications.data
  );
  const paging = useSelector((state) => state.auth.user.notifications.paging);

  const fetchMoreNotifications = () => {
    dispatch(getMoreNotifications(paging.page + 1, NOTIFICATIONS_SIZE));
  };

  const handleClose = () => {
    setNotifcationAnchor(null);
    //return if we have no notifications
    if (notifications === null || notifications.length === 0) return;
    dispatch(updateNotificationsRead());
  };

  const handleClick = (event) => {
    setNotifcationAnchor(event.currentTarget);
    //return if we have no notifications
    if (notifications === null || notifications.length === 0) return;
    //filter out ids of unread notifications and if there are some unread, mark them as read
    const notificationsIds = notifications
      .filter((notification) => !notification.was_read)
      .map((notification) => notification.id);
    if (notificationsIds.length > 0)
      dispatch(markNotificationsRead(notificationsIds));
  };

  return (
    <div className={classes.wrapper}>
      <Badge
        badgeContent={unread || 0}
        max={99}
        color="error"
        classes={{ badge: classes.badge }}
        onClick={handleClick}
      >
        <img src={BellIcon} alt="notification icon" />
      </Badge>
      <Menu
        id="customized-menu"
        anchorEl={notificationAnchor}
        open={Boolean(notificationAnchor)}
        onClose={handleClose}
        autoFocus={false}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.paper, list: classes.list }}
      >
        <div>
          <Typography variant="h3" className={classes.header}>
            Notifications
          </Typography>
          <Divider variant="fullWidth" />
          {notifications !== null && notifications.length !== 0 ? (
            <InfiniteScroll
              dataLength={notifications.length}
              next={fetchMoreNotifications}
              hasMore={
                paging.total >
                paging.page * NOTIFICATIONS_SIZE + NOTIFICATIONS_SIZE
              }
              loader={<Loader size={30} />}
              height={400}
            >
              {notifications.map((notification, i) => {
                return (
                  <NotificationListItem
                    key={i}
                    fundImg={notification.author_pic}
                    title={notification.author_name}
                    bodyText={notification.body_text}
                    timestamp={notification.time_stamp}
                    wasRead={notification.was_read}
                    type={notification.type}
                    eventId={notification.event_link}
                    handleClose={handleClose}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Typography variant="body1" className={classes.text}>
              You have no notifications
            </Typography>
          )}
        </div>
      </Menu>
    </div>
  );
}
