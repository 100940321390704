import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  children: {
    padding: `${theme.custom.pxToRem(16)} !important`,
  },
  actionIcons: {
    margin: `${theme.custom.pxToRem(4)} 0 !important`,
    padding: `0 ${theme.custom.pxToRem(11)} !important`,
    borderLeft: `1px solid ${theme.palette.background.border} !important`,
    display: "flex",
    alignItems: "center",
  },
  actionIcon: {
    height: "fit-content",
    // "&:last-child": {
    //   borderTop: `1px solid ${theme.palette.background.border} !important`,
    // },
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(4)} !important`,
  },
}));
