import * as api from "api/api";
import {
  FOLLOW_FUND as FUND_FOLLOW_FUND,
  UNFOLLOW_FUND as FUND_UNFOLLOW_FUND,
} from "./fund";
import { getFollowedFunds } from "./followed_funds";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_FUNDS = "funds/SET_FUNDS";
const START_LOADING = "funds/START_LOADING";
const STOP_LOADING = "funds/STOP_LOADING";
const FOLLOW_FUND = "funds/FOLLOW_FUND";
const UNFOLLOW_FUND = "funds/UNFOLLOW_FUND";

const initialState = {
  is_loading: true,
  data: [],
};

//ACTIONS
export const getFunds = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const {
      data: { funds: data },
    } = await api.getFunds();
    dispatch({ type: SET_FUNDS, payload: data });
    dispatch(stopLoading());
  } catch (error) {
    constructAndSendError(error.message, "funds/getFunds", "/data/funds");
    console.log(error);
  }
};

//explicit follow fund function is needed bcs this reducer needs to operate on the array
export const followFund = (fund_guid) => async (dispatch) => {
  try {
    await api.followFund(fund_guid);
    dispatch({ type: FOLLOW_FUND, payload: fund_guid });
    dispatch({ type: FUND_FOLLOW_FUND, payload: fund_guid });
    dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(error.message, "funds/followFund", "/fund2/follow", [
      `fund_guid:${fund_guid}`,
    ]);
    console.log(error);
  }
};

export const unfollowFund = (fund_guid) => async (dispatch) => {
  try {
    await api.unfollowFund(fund_guid);
    dispatch({ type: UNFOLLOW_FUND, payload: fund_guid });
    dispatch({ type: FUND_UNFOLLOW_FUND, payload: fund_guid });
    dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(
      error.message,
      "funds/unfollowFund",
      "/fund2/unfollow",
      [`fund_guid:${fund_guid}`]
    );
    console.log(error);
  }
};

export const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FUNDS: {
      return { ...state, data: action.payload };
    }
    case FOLLOW_FUND: {
      return {
        ...state,
        data: state.data.map((fund) => {
          if (fund.fund_guid === action.payload)
            return { ...fund, is_user_following: true };
          else return fund;
        }),
      };
    }
    case UNFOLLOW_FUND: {
      return {
        ...state,
        data: state.data.map((fund) => {
          if (fund.fund_guid === action.payload)
            return { ...fund, is_user_following: false };
          else return fund;
        }),
      };
    }
    case START_LOADING: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING: {
      return { ...state, is_loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
