import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//REDUX
import { useSelector } from "react-redux";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const scrollOffset = JSON.parse(sessionStorage.getItem("scrollOffset"));
  const wasLastMainPage = JSON.parse(sessionStorage.getItem("wasLastPageMain"));
  const is_loading = useSelector((state) => state.data.categories.is_loading);
  pathname === "/"
    ? sessionStorage.setItem("wasLastPageMain", true)
    : sessionStorage.setItem("wasLastPageMain", false);
  wasLastMainPage && sessionStorage.setItem("scrollOffset", window.scrollY);

  useEffect(() => {
    if (pathname === "/") {
      window.scrollTo(0, scrollOffset); //X,Y coordinates
    } else {
      window.scrollTo(0, 0); //X,Y coordinates
    }
  }, [pathname, is_loading, scrollOffset]);

  return null;
}
