import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  sort: {
    display: "flex",
    alignItems: "center",
  },
  filter: {
    color: `${theme.custom.palette.secondary}`,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(6)} !important`,
    },
  },
}));
