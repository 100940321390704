import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  ...theme.custom,
  google: {
    width: `90% !important`,
    maxWidth: `${theme.custom.pxToRem(280)} !important`,
    height: `${theme.custom.pxToRem(40)} !important`,
    background: "#C55547 !important",
    backgroundColor: "#C55547 !important",
    marginBottom: `${theme.spacing(2)}px !important`,
    padding: "0 !important",
  },
  facebook: {
    width: `90% !important`,
    maxWidth: `${theme.custom.pxToRem(280)} !important`,
    height: `${theme.custom.pxToRem(40)} !important`,
    background: "#475892 !important",
    backgroundColor: "#475892 !important",
    marginBottom: `${theme.spacing(2)}px !important`,
    padding: "0 !important",
  },
  linkedIn: {
    width: `90% !important`,
    maxWidth: `${theme.custom.pxToRem(280)} !important`,
    height: `${theme.custom.pxToRem(40)} !important`,
    background: "#327BB1 !important",
    backgroundColor: "#327BB1 !important",
    padding: "0 !important",
  },
  label: {
    width: "100% !important",
    justifyContent: "flex-start !important",
  },
  icon: {
    margin: `0px ${theme.custom.pxToRem(16)} !important`,
  },
}));
