import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    marginBottom: `${theme.custom.pxToRem(16)} !important`,
  },
  cardWrapper: {
    padding: `${theme.custom.pxToRem(24)} ${theme.custom.pxToRem(
      16
    )} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  detailsTitle: {
    marginBottom: `${theme.custom.pxToRem(16)} `,
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(8)} `,
    },
  },
  detailWrapper: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: `${theme.custom.pxToRem(9)} !important`,
    },
    marginBottom: `${theme.custom.pxToRem(8)} !important`,
    "&:last-child": {
      marginBottom: `0 !important`,
    },
  },
  externalLink: {
    color: `${theme.palette.primary.main} !important`,
    wordBreak: "break-all",
  },
}));
