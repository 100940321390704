import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette.js";
export default makeStyles((theme) => ({
  smallButton: {
    ...theme.custom.smallButton,
    "&:hover": {
      background: `${palette.grey_tone_transparent[400]}`,
    },
  },
  largeButton: {
    color: `${palette.grey_tone[400]}`,
    ...theme.custom.largeButton,
    "&:hover": {
      background: `${palette.grey_tone_transparent[400]}`,
    },
  },
}));
