import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navbar: {
    height: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(48)} !important`,
    },
    backgroundColor: `${theme.palette.background.default}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: `${theme.custom.pxToRem(1)} solid ${
      theme.palette.background.border
    }!important`,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: "1300 !important",
  },
  logo: {
    height: `${theme.custom.pxToRem(16)} !important`,
  },
  channelLogo: {
    height: `${theme.custom.pxToRem(40)}`,
    imageRendering: "-webkit-optimize-contrast",
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(5)}`,
    },
  },
  companyLogoLink: {
    display: "flex",
  },
  companyLogo: {
    height: `${theme.custom.pxToRem(60)}`,
    maxWidth: `${theme.custom.pxToRem(180)}`,
    objectFit: "contain",
    imageRendering: "-webkit-optimize-contrast",
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(40)} !important`,
      maxWidth: `${theme.custom.pxToRem(150)}`,

      padding: `0 ${theme.custom.pxToRem(5)}`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.custom.pxToRem(5)}`,
    },
  },
  wrapper: {
    width: `calc(100% - ${theme.spacing(12)}px)`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },

  loggosWrapper: {
    display: "flex",
    alignItems: "center",
    transform: `translate(-${theme.custom.pxToRem(8)})`,
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      gap: `0px`,
    },
  },
  backButton: {
    "& img": {
      height: `${theme.custom.pxToRem(16)}`,
      width: `${theme.custom.pxToRem(23)}`,
    },
    padding: `${theme.custom.pxToRem(11)} ${theme.custom.pxToRem(8)}`,
  },
  logoLink: {
    display: "flex",
    padding: `${theme.custom.pxToRem(8)} !important`,
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  actionsDivider: {
    height: `${theme.custom.pxToRem(16)} !important`,
    margin: `0 ${theme.custom.pxToRem(8)} !important`,
  },
  whereToInvestLink: {
    textDecoration: "none",
  },
}));
