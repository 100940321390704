import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  USER_SETTINGS_NOTIFICATIONS,
  USER_SETTINGS_PROFILE,
} from "navigation/constants";
//MUI
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";
// REDUX
import { useSelector } from "react-redux";
//Icons
import bellIcon from "assets/icons/icons/bell.svg";
import profileIcon from "assets/icons/icons/profile.svg";
// import deleteAccountIcon from "assets/icons/icons/profile-cross.svg"; TODO: put this back if we decide to have delete acount page

export default function SideDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const menuItems = [
    {
      text: "Edit profile",
      icon: <img src={profileIcon} alt="edit profile" />,
      path: USER_SETTINGS_PROFILE,
    },
    {
      text: "Set Notifications",
      icon: <img src={bellIcon} alt="set notfications" />,
      path: USER_SETTINGS_NOTIFICATIONS,
    },
    // {
    //   text: "Delete Account",
    //   icon: <img src={deleteAccountIcon} alt="delete account" />,
    //   path: `${url}/delete-account`,
    // },
  ];
  const { first_name, last_name } = useSelector((state) => state.auth.user);

  return (
    <Drawer
      className={classes.draw}
      variant="permanent"
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
    >
      <div>
        <Typography
          className={classes.subTitle}
        >{`${first_name} ${last_name}`}</Typography>
        <Typography className={classes.title}>Settings</Typography>
      </div>
      {/* List */}
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            button
            onClick={() => history.push(item.path)}
            className={
              location.pathname === item.path ||
              location.pathname === `${item.path}/`
                ? classes.listItemActive
                : classes.listItem
            }
          >
            <div
              className={
                location.pathname === item.path ||
                location.pathname === `${item.path}/`
                  ? classes.itemSelectorActive
                  : classes.itemSelectorDisable
              }
            ></div>
            <ListItemIcon className={classes.listIcon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
