import React from "react";
import LinkedinCircle from "assets/icons/icons/LinkedinButtonCircle.svg";
import EmailCircle from "assets/icons/icons/EmailButtonCircle.svg";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
//REDUX
import { useSelector } from "react-redux";

const AssetManagerList = () => {
  const classes = useStyles();
  const contacts = useSelector((state) => state.data.fund.contacts);
  return (
    <Grid container className={classes.grid} spacing={3}>
      {contacts !== null &&
        contacts.map((contact, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            lg={3}
            key={index}
            className={classes.gridItem}
          >
            <Card className={classes.card}>
              <div>
                <span className={classes.avatarBorder}>
                  <Avatar
                    src={contact.picture_url}
                    className={classes.avatar}
                  />
                </span>
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.managerName}
                >
                  {contact.name}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.managerPosition}
                >
                  {contact.position}
                </Typography>
              </div>

              <div className={classes.linksButtonWrapper}>
                {contact.linked_in_link !== "" &&
                  contact.linked_in_link !== null && (
                    <a
                      href={contact.linked_in_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={LinkedinCircle}
                        alt="linkedin link"
                        className={classes.linkButton}
                      />
                    </a>
                  )}
                {contact.email !== "" && contact.email !== null && (
                  <a
                    href={`mailto:${contact.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={EmailCircle}
                      alt="email link"
                      className={classes.linkButton}
                    />
                  </a>
                )}
              </div>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default AssetManagerList;
