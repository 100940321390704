import React from "react";
import ClampLines from "react-clamp-lines";
import { trim } from "lodash";
import moment from "moment";
import Loader from "shared_components/Loader/Loader";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import DocumentsSection from "../../components/DocumentsSection/DocumentsSection";
import { useLocation } from "react-router-dom";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DocumentCard from "pages/Fund/components/DocumentCard/DocumentCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { useSelector } from "react-redux";

const About = () => {
  const classes = useStyles();
  const location = useLocation();
  const isPhone = useMediaQuery("(max-width:599px)");
  const fund = useSelector((state) => state.data.fund);
  const newsLettersSection = React.useRef(null);

  React.useEffect(() => {
    if (
      newsLettersSection.current !== null &&
      location.hash === "#newsletters"
    ) {
      newsLettersSection.current.scrollIntoView(true);
    }
  }, [fund.is_loading, location.hash]);

  return (
    <div className={classes.backGround}>
      {fund.is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <Grid
            container
            spacing={3}
            className={classes.grid}
            justifyContent="space-between"
            direction={isPhone ? "column-reverse" : "row"}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                component="div"
                className={classes.description}
                data-cy="fundDescription"
              >
                <ClampLines
                  text={
                    fund.description !== null && trim(fund.description) !== ""
                      ? fund.description
                      : "The fund does not have about"
                  }
                  lines={14}
                  ellipsis={"..."}
                  buttons={true}
                  moreText="Read&#160;More"
                  lessText="Read&#160;Less"
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <DetailsCard />
              {fund.factsheet_documents !== null &&
              fund.factsheet_documents.length > 0 ? (
                <DocumentCard docLink={fund.factsheet_documents[0].url}>
                  <Typography variant="h4" data-cy="factSheetTitle">
                    Fact sheet
                  </Typography>
                  <Typography variant="body2">
                    <span className={classes.updateText}>Last uploaded:</span>{" "}
                    <span className={classes.updateTime}>
                      {fund.factsheet_documents[0].modify_stamp !== null
                        ? moment(
                            fund.factsheet_documents[0].modify_stamp
                          ).format("Do MMMM YYYY")
                        : ""}
                    </span>
                  </Typography>
                </DocumentCard>
              ) : null}
            </Grid>
          </Grid>
          <DocumentsSection title="Documents" reduxSelector="other_documents" />
          <div className={classes.bottomPagePadding} />
          <DocumentsSection
            title="Newsletters"
            reduxSelector="newsletter_documents"
            sectionId="newsletters"
            innerRef={newsLettersSection}
          />
          <div className={classes.bottomPagePadding} />
        </>
      )}
    </div>
  );
};

export default About;
