import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    drawer: {
      width: `${theme.custom.pxToRem(250)}`,
      position: "fixed",
    },
    drawerPaper: {
      width: `${theme.custom.pxToRem(250)}`,
      borderRight: `1px solid ${theme.palette.background.border}`,
      [theme.breakpoints.down("sm")]: {
        width: `${theme.custom.pxToRem(200)}`,
      },
      [theme.breakpoints.down("xs")]: {
        display: `none`,
      },
    },
    listItemActive: {
      background: `${theme.palette.grey_tone[300]} !important`,
      padding: 0,
      height: `${theme.custom.pxToRem(48)}`,
    },
    listItem: {
      padding: 0,
      height: `${theme.custom.pxToRem(48)}`,
    },
    listIcon: {
      marginLeft: 36,
      minWidth: 8,
      width: 32,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 18,
      },
    },
    listItemText: {
      ...theme.typography.body2,
      marginLeft: 8,
    },
    itemSelectorActive: {
      width: 12,
      height: `${theme.custom.pxToRem(48)}`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    itemSelectorDisable: {
      width: 12,
      height: `${theme.custom.pxToRem(48)}`,
    },
    title: {
      marginLeft: `${theme.custom.pxToRem(48)}`,
      marginTop: 8,
      marginBottom: 56,
      ...theme.typography.h2,
      [theme.breakpoints.down("sm")]: {
        marginLeft: `${theme.custom.pxToRem(32)} `,
        marginBottom: `${theme.custom.pxToRem(32)} !important`,
      },
    },
    subTitle: {
      marginTop: 120,
      marginLeft: `${theme.custom.pxToRem(48)}`,
      color: `${theme.palette.primary.main} !important`,
      ...theme.typography.label,
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.custom.pxToRem(80)}`,
        marginLeft: `${theme.custom.pxToRem(32)}`,
      },
    },
  };
});
