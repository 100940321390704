import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  outlined: {
    ...theme.custom.smallButton,
    borderRadius: 100,
    border: `2px solid ${theme.palette.primary.main}`,
    color: ({ active }) => {
      return active
        ? `${theme.palette.text.secondary}`
        : `${theme.palette.primary.main}`;
    },
    backgroundColor: ({ active }) => {
      return active
        ? `${theme.palette.primary.main}`
        : `${theme.palette.text.secondary}`;
    },
    "&:hover": {
      backgroundColor: ({ active }) => {
        return active
          ? `${theme.palette.error.main}`
          : `${theme.palette.primary.transparent}`;
      },
      border: ({ active }) => {
        return active
          ? `2px solid ${theme.palette.error.main}`
          : `2px solid ${theme.palette.primary.main}`;
      },
    },
    [theme.breakpoints.down("sm")]: {
      filter: "none !important",
      "&:hover": {
        backgroundColor: ({ active }) => {
          return active
            ? `${theme.palette.primary.main}`
            : `${theme.palette.background.default}`;
        },
        border: `2px solid ${theme.palette.primary.main} !important`,
      },
    },
  },
}));
