import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import previousArrowIcon from "assets/decoration/previousArrow.svg";
import nextArrowIcon from "assets/decoration/nextArrow.svg";
import previousArrowIconSmall from "assets/decoration/previousArrowSmall.svg";
import nextArrowIconSmall from "assets/decoration/nextArrowSmall.svg";
import BlackPicture from "assets/fallbacks/blackPic.PNG";
import Link from "shared_components/Link/Link";
import { FUND, VIDEOS } from "navigation/constants";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "shared_components/Loader/Loader";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
//swiper
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from "swiper";
import "./carousel.css";
import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
//MUI
import useStyles from "./styles.js";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { getVideos } from "redux/ducks/carousel";
import { useDispatch, useSelector } from "react-redux";

SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

//default values I found this example with 0 60 105 2
// rotate: 0, //Slide rotate in degrees
// stretch: 400, //Stretch space between slides (in px)
// depth: 205, //Depth offset in px (slides translate in Z axis) - how much is slider "bented inwards"
// modifier: 1, //Effect multiplier
// slideShadows: false, //Enables slides shadows
const carouselSettings = {
  xs: {
    // <600
    rotate: 0,
    stretch: 207,
    depth: 145,
    modifier: 1,
    slideShadows: false,
  },
  sm: {
    //600 < x < 960
    rotate: 0,
    stretch: 350,
    depth: 145,
    modifier: 1,
    slideShadows: false,
  },
  md: {
    //960< x <1280
    rotate: 0,
    stretch: 450,
    depth: 365,
    modifier: 1,
    slideShadows: false,
  },
  lg: {
    //1280<
    rotate: 0,
    stretch: 450,
    depth: 565,
    modifier: 1,
    slideShadows: false,
  },
};

const Carousel = () => {
  const classes = useStyles();
  const isLaptop = useMediaQuery("(max-width:1279px)");
  const isTablet = useMediaQuery("(max-width:959px)");
  const isPhone = useMediaQuery("(max-width:599px)");
  const videos = useSelector((state) => state.data.carousel.videos);
  const is_loading = useSelector((state) => state.data.carousel.is_loading);
  const user_guid = useSelector((state) => state.auth.user.user_guid);
  const dispatch = useDispatch();
  let slides = [];
  const [activeSlide, setActiveSlide] = React.useState(5);

  useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      //fetch only if we do not have videos already
      if (videos.length === 0) dispatch(getVideos());
    }
  }, [dispatch, user_guid]);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.realIndex);
  };

  //need to check if slides exist, or else swiper librabry can not work with empty array
  //fill slides
  if (videos.length) {
    slides = videos.map((video, index) => {
      //extract data form the video object
      const id = video.snippet.video_guid;
      const url = video.details.url;
      const title = video.snippet.title;
      const description =
        video.details.description || "No description provided";
      const fundName = video.snippet.primary_fund_name;
      const primaryFundGuid = video.snippet.primary_fund_guid;
      // const companyPic = video.snippet.company_pic; TODO:put this back when we have company page ready
      // only active slide is displayed as iframe, other slides are just thumbnails
      return (
        //it does not work if u exclude this to separate file
        //part of the styling is in the CSS because it was the only way to target html elements with specific class
        <SwiperSlide key={id} className={classes.carouselCard}>
          <div className="video-wrapper">
            {activeSlide === index ? (
              <Vimeo
                autopause //will stop all other videos on the page
                video={url}
                //onLoad listener can be useful here to make spinner
              />
            ) : (
              <div className={classes.thumbnailWrapper}>
                <img
                  src={video.snippet.thumbnail_pic || BlackPicture}
                  alt={"video thumbnail"}
                  className={classes.thumbnail}
                />
              </div>
            )}
          </div>
          <aside className={classes.aside}>
            <Typography variant="h4" className={classes.title}>
              <CustomEllipsis lines={2} text={title} component="div" />
            </Typography>
            {!isTablet && (
              <Typography
                variant="body2"
                className={classes.description}
                component="div"
              >
                <CustomEllipsis lines={6} text={description} component="div" />
              </Typography>
            )}

            <Link
              to={`${FUND}/${primaryFundGuid}${VIDEOS}`}
              className={classes.link}
            >
              <div className={classes.linkTextWrapper}>
                {/* add this when we have company logo 
                <div className={classes.companyLogo}>
                  <img
                    src={companyPic}
                    alt="fund pic"
                    className={classes.companyLogoImg}
                  />
                </div> */}
                <Typography className={classes.label} component="div">
                  <CustomEllipsis lines={1} text={fundName} />
                </Typography>
              </div>
            </Link>
          </aside>
        </SwiperSlide>
      );
    });
  }

  return (
    <>
      {is_loading ? (
        <Loader
          size={40}
          height={
            isPhone
              ? "19.625rem"
              : isTablet
              ? "12.3125rem"
              : isLaptop
              ? "18.375rem"
              : "21.875rem"
          }
        />
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.arrowButtonLeft}>
            <img
              src={isTablet ? previousArrowIconSmall : previousArrowIcon}
              alt="previous arrow"
              className={"previous"}
            />
          </div>
          {videos.length ? (
            <Swiper
              effect={"coverflow"}
              centeredSlides={true}
              slidesPerView={"auto"}
              initialSlide={activeSlide}
              navigation={{
                // prevEl: isPhone ? ".next" : ".previous",
                // nextEl: isPhone ? ".previous" : ".next",
                prevEl: ".previous",
                nextEl: ".next",
              }}
              breakpoints={{
                0: {
                  coverflowEffect: carouselSettings.xs,
                },
                600: {
                  coverflowEffect: carouselSettings.sm,
                },
                960: {
                  coverflowEffect: carouselSettings.md,
                },
                1280: {
                  coverflowEffect: carouselSettings.lg,
                },
              }}
              pagination={isPhone ? { dynamicBullets: true } : false}
              className="mySwiper"
              loop={true}
              allowTouchMove={false}
              onSlideChange={handleSlideChange}
              speed={300}
            >
              {slides}
            </Swiper>
          ) : null}

          <div className={classes.arrowButtonRight}>
            <img
              src={isTablet ? nextArrowIconSmall : nextArrowIcon}
              alt="next arrow"
              className={"next"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel;
