import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navigation: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.background.border}`,
    padding: `0 ${theme.spacing(6)}px`,
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0`,
      justifyContent: "space-around",
    },
  },
  tabsWrapper: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-around",
    },
  },
  button: {
    width: `${theme.custom.pxToRem(204)}`,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
