import React from "react";
import { FUND, VIDEOS } from "navigation/constants";
import Link from "shared_components/Link/Link";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
//REDUX
import { useSelector } from "react-redux";

const RelatedFunds = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const {
    primary_fund_name,
    primary_fund_pic,
    primary_fund_guid,
    primary_fund_visible,
  } = useSelector((state) => state.data.video.snippet);
  return (
    <>
      {primary_fund_visible && (
        <Grid xs={12} lg={8} item className={classes.relatedFunds}>
          <Typography variant="h3">Related Fund</Typography>
          <Link
            to={`${FUND}/${primary_fund_guid}${VIDEOS}`}
            className={classes.card}
          >
            <img src={primary_fund_pic} alt="fund" className={classes.image} />
            <Typography
              variant={isPhone ? "h4" : "h2"}
              className={classes.fundName}
            >
              {primary_fund_name}
            </Typography>
          </Link>
        </Grid>
      )}
    </>
  );
};

export default RelatedFunds;
