import React from "react";
import { investorTypesArray } from "auth/investorTypes";
//MUI
import useStyles from "./styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
//REDUX
import { useSelector } from "react-redux";

//transition for modal
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ open, handleClose }) => {
  const classes = useStyles();
  const investorType = useSelector((state) => state.auth.user.investor_type);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.wrapper}>
        <Typography align="center">
          <b>Disclaimer</b>:{" "}
          {`By selecting ${investorTypesArray[investorType]}, you
          acknowledge that you will be watching ${investorTypesArray[investorType]} video
          content at your own risk.`}
        </Typography>
        <Button
          onClick={() => handleClose(false)}
          autoFocus
          className={classes.button}
        >
          Confirm
        </Button>
      </div>
    </Dialog>
  );
};

export default Modal;
