import * as api from "api/api";
import { getFollowedFunds } from "./followed_funds";
import {
  constructAndSendError,
  splitEventsByTime,
} from "shared_functions/shared_functions";

//action types
const SET_FUND = "fund/SET_FUND";
const SET_INITIAL_STATE = "fund/SET_INITIAL_STATE";
const SET_FUND_VIDEOS = "fund/SET_FUND_VIDEOS";
const SET_MORE_FUND_VIDEOS = "fund/SET_MORE_FUND_VIDEOS";
const START_LOADING_FUND = "fund/START_LOADING_FUND";
const STOP_LOADING_FUND = "fund/STOP_LOADING_FUND";
const START_LOADING_VIDEOS = "fund/START_LOADING_VIDEOS";
const STOP_LOADING_VIDEOS = "fund/STOP_LOADING_VIDEOS";
export const FOLLOW_FUND = "fund/FOLLOW_FUND";
export const UNFOLLOW_FUND = "fund/UNFOLLOW_FUND";

const initialState = {
  is_loading: true,
  id: "",
  fund_guid: "",
  name: "",
  type: "",
  category_tags: [],
  description: "",
  fund_pic: "",
  company_pic: null,
  company_logo: null,
  is_user_following: false,
  videos: {
    is_loading: true,
    paging: {},
    data: [],
  },
  details: {
    address: "",
    phone_number: "",
    external_website_link: "",
    joined: "",
    about: "",
    fact_sheet: null,
  },
  investment_documents: [
    {
      id: "",
      name: "",
      description: "",
      document_link: "",
    },
  ],
  other_documents: [],
  contacts: [
    {
      id: "",
      name: "",
      title: "",
      linked_in_link: "",
    },
  ],
  brokers: [
    {
      id: "",
      name: "",
      description: "",
      website_link: "",
    },
  ],
  upcoming_events: [],
  past_events: [],
};

export const getFund = (fund_guid) => async (dispatch) => {
  try {
    dispatch(startLoadingFund());
    let { data } = await api.getFund(fund_guid);
    //spliting events before storying to redux
    const [upcomingEvents, pastEvents] = splitEventsByTime(data.events);
    data.upcoming_events = upcomingEvents;
    data.past_events = pastEvents;
    dispatch({ type: SET_FUND, payload: data });
    dispatch(stopLoadingFund());
    if (data.investment_documents === null) data.investment_documents = [];
    const PAGE = 0;
    const SIZE = 12;
    dispatch(getFundVideos(data.fund_guid, PAGE, SIZE));
  } catch (error) {
    constructAndSendError(error.message, "fund/getFund", "/fund2", [
      `fund_guid:${fund_guid}`,
    ]);
    console.log(error);
  }
};

export const getFundVideos = (fund_guid, page, size) => async (dispatch) => {
  try {
    dispatch(startLoadingVideos());
    const { data } = await api.getFundVideos(fund_guid, page, size);
    dispatch({ type: SET_FUND_VIDEOS, payload: data });
    dispatch(stopLoadingVideos());
  } catch (error) {
    constructAndSendError(
      error.message,
      "fund/getFundVideos",
      "/fund2/videos",
      [`fund_guid:${fund_guid}`, `page:${page}`, `size:${size}`]
    );
    console.log(error);
  }
};

export const getMoreFundVideos =
  (fund_guid, page, size) => async (dispatch) => {
    try {
      const { data } = await api.getFundVideos(fund_guid, page, size);
      dispatch({ type: SET_MORE_FUND_VIDEOS, payload: data });
    } catch (error) {
      constructAndSendError(
        error.message,
        "fund/getMoreFundVideos",
        "/fund2/videos",
        [`fund_guid:${fund_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const followFund = (fund_guid) => async (dispatch) => {
  try {
    await api.followFund(fund_guid);
    dispatch({ type: FOLLOW_FUND });
    //necessary to keep redux > followed_funds in sink
    dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(error.message, "fund/followFund", "/fund2/follow", [
      `fund_guid:${fund_guid}`,
    ]);
    console.log(error);
  }
};

export const unfollowFund = (fund_guid) => async (dispatch) => {
  try {
    await api.unfollowFund(fund_guid);
    dispatch({ type: UNFOLLOW_FUND });
    //necessary to keep redux > followed_funds in sink
    dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(
      error.message,
      "fund/unfollowFund",
      "/fund2/unfollow",
      [`fund_guid:${fund_guid}`]
    );
    console.log(error);
  }
};

export const setInitialState = () => async (dispatch) => {
  dispatch({ type: SET_INITIAL_STATE });
};

export const startLoadingFund = () => (dispatch) => {
  dispatch({ type: START_LOADING_FUND });
};

export const stopLoadingFund = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_FUND });
};

export const startLoadingVideos = () => (dispatch) => {
  dispatch({ type: START_LOADING_VIDEOS });
};

export const stopLoadingVideos = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_VIDEOS });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FUND: {
      return {
        is_loading: state.is_loading,
        ...action.payload,
        videos: state.videos,
      };
    }
    case SET_FUND_VIDEOS: {
      return {
        ...state,
        videos: {
          paging: action.payload.paging,
          data: action.payload.videos,
          is_loading: state.videos.is_loading,
        },
      };
    }
    case SET_MORE_FUND_VIDEOS: {
      return {
        ...state,
        videos: {
          paging: action.payload.paging,
          data: [...state.videos.data, ...action.payload.videos],
          is_loading: state.videos.is_loading,
        },
      };
    }
    case FOLLOW_FUND: {
      return { ...state, is_user_following: true };
    }
    case UNFOLLOW_FUND: {
      return { ...state, is_user_following: false };
    }
    case SET_INITIAL_STATE: {
      return initialState;
    }
    case START_LOADING_FUND: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING_FUND: {
      return { ...state, is_loading: false };
    }
    case START_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: true } };
    }
    case STOP_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: false } };
    }
    default:
      return state;
  }
};

export default reducer;
