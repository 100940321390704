const data = {
  is_loading: true,
  id: 2,
  guid: 2,
  name: "Asharq Business",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.",
  channel_pic:
    "https://www.qvest.com/fileadmin/_processed_/f/d/csm_pic-asharq-news-03_97aa9cb67e.jpg",
  channel_logo: "https://www.asharqbusiness.com/asharq.png",
  followers_count: 212304,
  is_user_following: false,
  videos: {
    is_loading: false,
    paging: {
      total: 1,
      page: 0,
      size: 1,
    },
    data: [
      {
        snippet: {
          id: 192,
          video_guid: "2799c0b5-d95b-415c-8f87-dad1bd663c2c",
          clip_id: 625420162,
          thumbnail_pic:
            "https://i.ytimg.com/vi/F8Ts2-Z6AwM/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAriMJCMxRgROMNkLwkVGxgUcnstA",
          duration: 2319,
          views_count: 153,
          title:
            "رئيس استراتيجية السلع في ساكسو بنك: نتوقع ارتفاع مؤشر بلومبرغ للسلع لما يصل إلى 10%",
          time_stamp: "2021-10-07T14:37:03.960626+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 191,
          video_guid: "2c0e0713-92b2-4249-ade5-eb60925720d4",
          clip_id: 625411896,
          thumbnail_pic:
            "https://i.ytimg.com/vi/3XCXYGw2cag/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCTfieq1H-oy2UkTV0ePOTeunEffw",
          duration: 2248,
          views_count: 20,
          title:
            "ماذا يعني تعديل عطلة نهاية الأسبوع لقطاع الأعمال في الإمارات؟ - الصباح مع صبا",
          time_stamp: "2021-10-07T14:31:39.12572+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 190,
          video_guid: "cd6a7500-4d49-47ab-b3c8-12a54c96d302",
          clip_id: 625407897,
          thumbnail_pic:
            "https://i.ytimg.com/vi/svws0yINrsI/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUh1yJ_bWkmT9FLTU-H1752DCRIA",
          duration: 3813,
          views_count: 3,
          title:
            "هل ستستعيد أسواق السندات في الدول الناشئة زخمها مع نهاية هذا العام؟ - الصباح مع صبا",
          time_stamp: "2021-10-07T14:22:51.412897+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/iCpU9Up59E8/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCiX6OAbiLuZDyUwm6KxZv7oXgt4A",
          duration: 1830,
          views_count: 82,
          title:
            "المؤتمر الصحفي لبدء تداول أسهم المجموعة في السوق الرئيسية لتداول السعودية",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/ho5IbxlkECc/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBJHj6PEuGZ5OURLmU42CcG6lsY4A",
          duration: 1830,
          views_count: 82,
          title:
            "تعرف على أبرز أخبار الشركات العالمية ليوم 8 ديسمبر في الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/OCbcPbYK0wY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCLN0vqmmyXKBAqbzCYxXeHcxkwbQ",
          duration: 1830,
          views_count: 82,
          title:
            "تعرف على أبرز الأخبار العالمية ليوم 8 ديسمبر في الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/pAtBPhUvJKU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCktbv7k4y07peXvYQtqbJCywUcMA",
          duration: 1830,
          views_count: 82,
          title:
            "حكمت البعيني : اقتصاد العمالة المستقلة ينمو بشكل سريع - الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/yXRSPoegUog/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCB_xE8CG_Mf9wZ3hUfL6zKLPWKxQ",
          duration: 1830,
          views_count: 82,
          title:
            "عماد الفاضل : نحن ضمن مرحلة انتقالية يجب أن تكون منظمة - الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/Cik9FwZRTRw/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAozsJIvqYdlPRHMPoiw0N6xyY0Dw",
          duration: 1830,
          views_count: 82,
          title:
            "جيفري هالي : الاحتياطي الفيدرالي سيتطلع إلى تشديد السياسة النقدية بقوة أكبر في 2022 - الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/4hbC1wnincM/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDiQRypXmlgp5hDiBImeTflSwy-gg",
          duration: 1830,
          views_count: 82,
          title:
            "هل سينجح التواصل بين بايدن وبوتين اليوم بتخفيض حدة التوترات على الحدود الأوكرانية؟ - الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/Qw7cWAHixVE/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBo8Ke894k5R4MIRd6uqEnK5yVwSg",
          duration: 1830,
          views_count: 82,
          title:
            "تعرف على أبرز أخبار الشركات العالمية ليوم 7 ديسمبر في الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/o0RI7ptAgq0/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAi9ZfV4yYxOkDv7WmOb3aiyvCFrg",
          duration: 1830,
          views_count: 82,
          title:
            "تعرف على أبرز الأخبار العالمية ليوم 7 ديسمبر في الصباح مع صبا",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Asharq Business",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
    ],
  },
  details: {
    address: "Dubai",
    external_website_link: "https://www.asharqbusiness.com/",
    joined: "2021-10-07T14:11:55.08658+00:00",
  },
};

export default data;
