import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import { useParams } from "react-router-dom";
//MUI
import useStyles from "./styles";
import Alert from "shared_components/Alerts/Alert/Alert";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { markVideoViewed } from "redux/ducks/video";
import { constructAndSendError } from "shared_functions/shared_functions";

const VideoSection = () => {
  const { guid: video_guid } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const video = useSelector((state) => state.data.video);
  const [openVideoLoadError, setOpenVideoLoadError] = React.useState(false);

  //events
  const handleCloseVideoLoadError = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenVideoLoadError(false);
  };

  const handleError = (error) => {
    constructAndSendError(
      `${error.message}. The video url is ${video.details.url}`,
      "video/videoSection/handleError",
      "vimeoAPI"
    );
    setOpenVideoLoadError(true);
    console.log(error);
  };

  const handleLoaded = () => {
    dispatch(markVideoViewed(video_guid));
  };

  return (
    <>
      {video.is_loading ? (
        <div className={classes.skeletonVideoWrapper}>
          <div className={classes.skeletonVideo}></div>
        </div>
      ) : (
        <div className={classes.videoWrapper}>
          {/* url from redux 'https://vimeo.com/519590797/d4601029fa' */}
          {video.details.url && (
            <Vimeo
              autopause //will stop all other videos on the page
              autoplay
              video={video.details.url}
              className={classes.video}
              onError={handleError}
              onLoaded={handleLoaded}
              speed //Enable playback rate controls
              showPortrait //Show the portrait on the video.
            />
          )}
          <Alert
            open={openVideoLoadError}
            handleClose={handleCloseVideoLoadError}
            text="There was a problem while loading a video. Please, try to refresh the page."
            severity="info"
            variant="filled"
            autoHideDuration={100000}
          />
        </div>
      )}
    </>
  );
};

export default VideoSection;
