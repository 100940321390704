import React from "react";
import CoherraLogo from "assets/icons/logos/coherra_blue.svg";

//MUI
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const steps = ["Investor Experience", "Country"];

export default function HorizontalStepper({ activeStep }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.logoWrapper}>
        <img src={CoherraLogo} alt="coherra's logo" className={classes.logo} />
      </div>
      <Typography variant="h3" className={classes.pageTitle}>
        Create account
      </Typography>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
}
