const data = {
  is_loading: true,
  id: 1,
  guid: 1,
  name: "Real Vision Finance",
  description:
    "Real Vision is the destination for the world’s most successful investors to share their thoughts about what’s happening in today's markets. Think: TED Talks for Finance.Our membership subscription model allows Real Vision to keep it real. We don't dance to the tune of advertisers or corporate sponsors. Instead, we allow the smartest brains in Finance to say what they really think without sensationalizing to drive ratings, or stripping facts to fit between commercial breaks. ",
  channel_pic:
    "https://media.realvision.com/wp/20180627162716/RV-Poster-Textured.png",
  channel_logo:
    "https://static.realvision.com/rv-authentication/production/img/black-badge-generic2x.ee46c45.png",
  followers_count: 26534,
  is_user_following: false,
  videos: {
    is_loading: false,
    paging: {
      total: 1,
      page: 0,
      size: 1,
    },
    data: [
      {
        snippet: {
          id: 192,
          video_guid: "2799c0b5-d95b-415c-8f87-dad1bd663c2c",
          clip_id: 625420162,
          thumbnail_pic: "https://i.ytimg.com/vi/7GdoxTCWPRM/maxresdefault.jpg",
          duration: 2319,
          views_count: 153,
          title: "Launching Real Vision Crypto (w/ Raoul Pal)",
          time_stamp: "2021-10-07T14:37:03.960626+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 191,
          video_guid: "2c0e0713-92b2-4249-ade5-eb60925720d4",
          clip_id: 625411896,
          thumbnail_pic: "https://i.ytimg.com/vi/gu3vvrLRyZM/maxresdefault.jpg",
          duration: 2248,
          views_count: 20,
          title:
            "Chamath Palihapitiya on SPACs, Bitcoin, and the New World of Finance (w/ Raoul Pal)",
          time_stamp: "2021-10-07T14:31:39.12572+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 190,
          video_guid: "cd6a7500-4d49-47ab-b3c8-12a54c96d302",
          clip_id: 625407897,
          thumbnail_pic: "https://i.ytimg.com/vi/yuMGFJ5E-Zs/maxresdefault.jpg",
          duration: 3813,
          views_count: 3,
          title:
            "The Future of Finance: All Will Be Digitized (w/ Raoul Pal and Santiago Velez)",
          time_stamp: "2021-10-07T14:22:51.412897+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/kwT9V_1AC_U/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Mark Cuban: What the Fed-Driven Bubble Means for the Financial Establishment (w/Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/Cg10yYZjK94/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Bitcoin Infiltrates Corporate America (w/ Michael Saylor and Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/xiowlInfj28/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Anthony Pompliano's Story: Lessons on Bitcoin, Life, and Investing (w/ Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/aXipUgtU8mk/mqdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Abra: The Future of Crypto Banking (w/ Bill Barhydt and Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/FlshiqzKgZY/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Bitgo: Securing the World's Crypto (w/ Mike Belshe and Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/xa7IPlPE7Iw/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Why Central Banks Are The Buyers of First Resort (w/ Raoul Pal & James Aitken)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/CpnN6S_DvjQ/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "BlockFi: Earning with Your Crypto (w/ Raoul Pal and Zac Prince)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/4VNkIKTKqyk/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "Mike Novogratz's Approach to Investing in Crypto (w/ Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic: "https://i.ytimg.com/vi/kq6K8hqZQmo/maxresdefault.jpg",
          duration: 1830,
          views_count: 82,
          title:
            "False Illusions & Distorted Rates: A Disaster in the Making (w/ Sven Henrich and Raoul Pal)",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Real Vision Finance",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
    ],
  },
  details: {
    address: "New York, US",
    external_website_link: "https://www.realvision.com/",
    joined: "2021-10-07T14:11:55.08658+00:00",
  },
};

export default data;
