import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: { color: `grey` },
});

const RadioButtonStyled = ({ value, label }) => {
  const classes = useStyles();
  return (
    <div>
      <FormControlLabel
        value={value}
        label={label}
        control={<Radio classes={{ root: classes.root }} />}
      />
    </div>
  );
};

export default RadioButtonStyled;
