import React from "react";
import horizontalWave from "assets/decoration/wave.svg";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import { COMPANY, FUNDS, FUND, VIDEOS } from "navigation/constants";
import Link from "shared_components/Link/Link";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const FundSnippet = ({ fund }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Link to={`${COMPANY}/${fund.company_guid}${FUNDS}`}>
        <div className={classes.companyLogoWrapper}>
          <img
            src={fund.company_logo}
            alt="company"
            className={classes.companyLogo}
          />
        </div>
      </Link>
      <Link
        to={`${FUND}/${fund.fund_guid}${VIDEOS}`}
        className={classes.fundLink}
      >
        <div className={classes.fundPicWrapper}>
          <img src={fund.fund_pic} alt="fund" className={classes.fundPic} />
        </div>
      </Link>

      <div className={classes.infoWrapper}>
        <Link
          to={`${FUND}/${fund.fund_guid}${VIDEOS}`}
          className={classes.fundLink}
        >
          <img src={horizontalWave} alt="wave" className={classes.wave} />
          <div className={classes.textWrapper}>
            {/* TODO: put this back when we decide to 
            <Typography variant="h5" className={classes.type}>
              {fund.typename}
            </Typography> */}
            <Typography variant="h3">{fund.name}</Typography>
            <Typography variant="body2" component="div">
              <CustomEllipsis lines={2} text={fund.description} />
            </Typography>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FundSnippet;
