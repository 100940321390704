import React from "react";
import VideoSnippet from "shared_components/VideoSnippet/VideoSnippet";
import Link from "shared_components/Link/Link";
import { ROOT } from "navigation/constants";
import emptyVideosStateIcon from "assets/icons/icons/emptyVideosState.svg";
import Loader from "shared_components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { getMoreFundVideos } from "redux/ducks/fund";
import VideoCard from "shared_components/VideoCard/VideoCard";

const Videos = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const { guid: fund_guid } = useParams();
  const data = useSelector((state) => state.data.fund.videos.data);
  const paging = useSelector((state) => state.data.fund.videos.paging);
  const is_loading = useSelector((state) => state.data.fund.videos.is_loading);
  const VIDEOS_SIZE = 12;
  const dispatch = useDispatch();

  const fetchMoreVideos = () => {
    dispatch(getMoreFundVideos(fund_guid, paging.page + 1, VIDEOS_SIZE));
  };

  return (
    <>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <div className={classes.backGround}>
          {data !== null && data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreVideos}
              hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
              loader={<Loader />}
            >
              <Grid container spacing={3} className={classes.grid}>
                {data.map((video) => {
                  const { snippet } = video;
                  return (
                    <Grid
                      item
                      xs={isPhone ? 12 : 6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                      key={snippet.video_guid}
                    >
                      {isPhone ? (
                        <VideoCard snippet={snippet} />
                      ) : (
                        <VideoSnippet snippet={snippet} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          ) : (
            <section className={classes.emptyStateWrapper}>
              <div className={classes.itemsWrapper}>
                <img
                  src={emptyVideosStateIcon}
                  alt="icon"
                  className={classes.emptyStateIcon}
                />
                <Typography
                  className={classes.emptyStateTitle}
                  variant="h2"
                  align="center"
                >
                  There are no videos in this fund
                </Typography>
                <Typography
                  className={classes.emptyStateText}
                  variant="h3"
                  align="center"
                >
                  However, you can check out the other videos
                </Typography>
                <Link className={classes.reoderLink} to={ROOT}>
                  <Button size="lg" className={classes.emptyStateButton}>
                    See other videos
                  </Button>
                </Link>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default Videos;
