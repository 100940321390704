import React from "react";
import { Route, Redirect } from "react-router-dom";
//REDUX
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const investor_type = useSelector((state) => state.auth.user.investor_type);
  return (
    <Route
      {...rest}
      render={(props) =>
        // wait until we have fetch user info
        investor_type === null ? (
          <></>
        ) : // anonymous user
        investor_type === 0 ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;
