import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  backGround: {
    backgroundColor: `${theme.palette.background.secondary}`,
  },
  grid: {
    width: `calc(100% - ${theme.spacing(9)}px) !important`,
    margin: `auto`,
    padding: `${theme.spacing(6)}px 0 0 !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(4)}px 0 0 !important`,
      width: `calc(100% - ${theme.custom.pxToRem(40)}) !important`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.spacing(2)}px 0 0 !important`,
      width: `calc(100% - ${theme.custom.pxToRem(8)}) !important`,
    },
  },
  description: {
    position: "relative",
    paddingBottom: `${theme.custom.pxToRem(72)} !important`,
    marginBottom: `${theme.custom.pxToRem(108)} !important`,
    "& .clamp-lines__button": {
      ...theme.custom.largeButton,
      fontFamily: "ProximaNovaSemibold",
      fontWeight: "600",
      fontStyle: "normal",
      color: "white",
      border: "none",
      padding: `${theme.custom.pxToRem(0)} ${theme.custom.pxToRem(
        52.5
      )} !important`,
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      background: theme.palette.background.horizontal_gradient,
    },
    "& .clamp-lines__button:hover": {
      filter: `drop-shadow(${theme.shadows[3]})`,
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  updateText: {
    color: `${theme.palette.grey_tone["400"]} !important`,
  },
  bottomPagePadding: {
    paddingBottom: `100px !important`,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: `50px !important`,
    },
  },
}));
