import React from "react";
import LinkIcon from "assets/icons/icons/link_blue.svg";
import AddDisabledIcon from "assets/icons/icons/add_disabled.svg";
import CreditCardIcon from "assets/icons/icons/credit_card.svg";
import Wave from "assets/decoration/WaveVertical.svg";
import HorizontalWave from "assets/decoration/wave.svg";
import Link from "shared_components/Link/Link";
import { useParams } from "react-router-dom";
import { WHERE_TO_INVEST, FUND, COMPANY, FUNDS } from "navigation/constants";
import ActionButton from "../ActionButton/ActionButton";
import clsx from "clsx";
import Vimeo from "@u-wave/react-vimeo";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";

//MUI
import Loader from "shared_components/Loader/Loader";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Alert from "shared_components/Alerts/Alert/Alert";
import LoginTooltip from "shared_components/LoginTooltip/LoginTooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles";

//REDUX
import { getFund, followFund, unfollowFund } from "redux/ducks/fund";
import { useDispatch, useSelector } from "react-redux";
import TabBar from "../TabBar/TabBar";

const Banner = () => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:959px)");
  const isPhone = useMediaQuery("(max-width:599px)");
  const urlCopyRef = React.useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const fund = useSelector((state) => state.data.fund);
  const { user_guid, is_anonymous } = useSelector((state) => state.auth.user);
  const { guid: fund_guid } = useParams();

  React.useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      //check if fund is in redux, if not get the data
      if (fund_guid !== fund.fund_guid) {
        dispatch(getFund(fund_guid));
      }
    }
  }, [dispatch, fund_guid, user_guid]);

  //events

  const copyToClipboard = () => {
    //necessary bcs none displayed text cant be copied
    urlCopyRef.current.style.display = "inline";
    urlCopyRef.current.select();
    document.execCommand("copy");
    urlCopyRef.current.style.display = "none";
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {fund.is_loading ? (
        <Loader
          size={40}
          height={
            // isPhone ? "18.9375rem" : isTablet ? "12.1875rem" : "22.4375rem"
            isPhone ? "18.9375rem" : isTablet ? "12.1875rem" : "25.3125rem"
          }
        />
      ) : (
        <>
          <div className={classes.banner}>
            <div className={classes.information}>
              <div style={{ width: "100%" }}>
                <div className={classes.titleWrapper}>
                  {/* <Typography
                  variant={isPhone ? "h4" : "h3"}
                  className={classes.typeLabel}
                  data-cy="fundType"
                >
                  {fund.type}
                </Typography> */}
                  <div>
                    <Typography
                      data-cy="fundName"
                      variant={isPhone ? "h3" : "h2"}
                    >
                      {fund.name}
                    </Typography>
                    <Typography variant="h4" className={classes.byCompanyLabel}>
                      <span>By </span>
                      <Link
                        to={`${COMPANY}/${fund.company_guid}${FUNDS}`}
                        className={classes.byCompanyName}
                      >
                        {fund.company_name}
                      </Link>
                    </Typography>
                  </div>

                  {isTablet && (
                    <FollowButton
                      className={classes.button}
                      guid={fund.fund_guid}
                      onFollow={followFund}
                      onUnfollow={unfollowFund}
                      isFollowing={fund.is_user_following}
                    />
                  )}

                  {fund.category_tags !== null &&
                    fund.category_tags.length > 0 &&
                    !isTablet && (
                      <div className={classes.chipsWrapper}>
                        {fund.category_tags.map((tag, index) => (
                          <Chip
                            label={tag}
                            key={index}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  <div></div>
                </div>
                {!isTablet && (
                  <div className={classes.actionButtonsWrapper}>
                    <div className={classes.actionButton}>
                      <IconButton
                        classes={{ root: classes.iconButton }}
                        onClick={copyToClipboard}
                        data-cy="copyLink"
                      >
                        <img src={LinkIcon} alt="Link" />
                      </IconButton>
                      <span className={classes.label}>Link</span>
                      {/* used for copying to the url */}
                      <textarea
                        style={{ display: "none" }}
                        ref={urlCopyRef}
                        value={window.location.href}
                        readOnly
                      ></textarea>
                      <Alert
                        open={open}
                        handleClose={handleClose}
                        text="Link has been copied"
                        severity="success"
                        variant="filled"
                      />
                    </div>
                    <div className={classes.actionButton}>
                      {is_anonymous ? (
                        <>
                          <LoginTooltip
                            title="Would you like to follow the fund?"
                            description="Login or sign-up to follow the fund"
                            placement="top"
                          >
                            <div>
                              <IconButton
                                classes={{
                                  root: clsx(
                                    classes.iconButtonDisabled,
                                    classes.iconButton
                                  ),
                                }}
                                disabled
                                data-cy="followAnnonym"
                              >
                                <img src={AddDisabledIcon} alt="Link" />
                              </IconButton>
                            </div>
                          </LoginTooltip>
                          <span className={classes.label}>Follow</span>
                        </>
                      ) : (
                        <ActionButton
                          is_user_following={fund.is_user_following}
                          fund_guid={fund.fund_guid}
                        />
                      )}
                    </div>
                    <div className={classes.actionButton}>
                      <Link
                        to={`${FUND}/${fund_guid}${WHERE_TO_INVEST}`}
                        data-cy="linkToWhereToInvest"
                      >
                        <IconButton
                          classes={{ root: classes.investIconButton }}
                        >
                          <img src={CreditCardIcon} alt="Credit Card" />
                        </IconButton>
                      </Link>
                      <span className={classes.label}>Where To Invest</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {fund.introvideo !== null && fund.introvideo !== "" ? (
              <div className={classes.videoWrapper}>
                <Vimeo
                  autopause //will stop all other videos on the page
                  autoplay
                  video={fund.introvideo}
                  className={classes.fundIntroVideo}
                  showPortrait //Show the portrait on the video.
                  controls={false}
                  loop
                  muted
                />
                {isPhone ? (
                  <img
                    src={HorizontalWave}
                    alt="wave"
                    className={classes.horizontalWave}
                  />
                ) : (
                  <img src={Wave} alt="wave" className={classes.wave} />
                )}
              </div>
            ) : (
              <div className={classes.pictureWrapper}>
                <img
                  src={fund.fund_pic}
                  alt="fund banner"
                  className={classes.fundPic}
                />
                {isPhone ? (
                  <img
                    src={HorizontalWave}
                    alt="wave"
                    className={classes.horizontalWave}
                  />
                ) : (
                  <img src={Wave} alt="wave" className={classes.wave} />
                )}
              </div>
            )}
          </div>
          {isTablet &&
            fund.category_tags !== null &&
            fund.category_tags.length > 0 && (
              <div className={classes.chipsOverflowWrapper}>
                <div className={classes.chipsWrapper}>
                  {fund.category_tags.map((tag, index) => (
                    <Chip label={tag} key={index} className={classes.chip} />
                  ))}
                </div>
              </div>
            )}
        </>
      )}
      <TabBar />
    </>
  );
};

export default Banner;
