import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentsCount: {
    display: "flex",
    alignItems: "center",
  },
  sort: {
    display: "flex",
    alignItems: "center",
  },
  formWrapper: {
    display: "flex",
    margin: `${theme.custom.pxToRem(16)} 0px !important`,
    alignItems: "flex-start",
  },
  form: {
    flexGrow: 1,
    marginLeft: `${theme.custom.pxToRem(16)} !important`,
    marginBottom: 0,
    alignSelf: "center",
    "& .Mui-focused ~ #submitButtonWrapper": {
      display: "flex",
    },
  },
  avatar: {
    width: `${theme.custom.pxToRem(64)} !important`,
    height: `${theme.custom.pxToRem(64)} !important`,

    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(40)} !important`,
      height: `${theme.custom.pxToRem(40)} !important`,
    },
  },
  avatarBorder: {
    width: `${theme.custom.pxToRem(66)} !important`,
    height: `${theme.custom.pxToRem(66)} !important`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(42)} !important`,
      height: `${theme.custom.pxToRem(42)} !important`,
    },
    borderRadius: "50%",
    background: `${theme.palette.background.vertical_gradient}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    borderRadius: `${theme.custom.pxToRem(25)} !important`,
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(10.5)} ${theme.custom.pxToRem(
        16
      )} !important`,
    },
    padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
      16
    )} !important`,
    minHeight: `0 !important`,
    "& input": {
      padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
        16
      )} !important`,
    },
    "&:hover fieldset": {
      borderColor: `${theme.custom.palette.primary} !important`,
    },
  },
  textFieldDisabled: {
    padding: `${theme.custom.pxToRem(0)} !important`,
    "& input": {
      [theme.breakpoints.down("xs")]: {
        padding: `${theme.custom.pxToRem(10.5)} ${theme.custom.pxToRem(
          16
        )} !important`,
      },
      padding: `${theme.custom.pxToRem(12)} ${theme.custom.pxToRem(
        16
      )} !important`,
    },
  },
  submitButtonWrapper: {
    //if input is not focused anymore this prevent hidding submit button
    display: ({ commentBody }) => {
      if (commentBody === "") return "none";
      else return "flex";
    },
    justifyContent: "flex-end",
    marginTop: `${theme.custom.pxToRem(16)} `,
  },
  submitButton: {
    minWidth: `${theme.custom.pxToRem(170)} `,
    [theme.breakpoints.down("xs")]: {
      minWidth: "0",
      width: `${theme.custom.pxToRem(120)} !important`,
    },
  },
}));
