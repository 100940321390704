import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  skeletonVideoWrapper: {
    maxHeight: `${theme.custom.pxToRem(632)}`,
    width: "100%",
    backgroundColor: "black",
    display: "flex",
  },
  skeletonVideo: {
    maxWidth: `${theme.custom.pxToRem(1124)}`,
    maxHeight: `${theme.custom.pxToRem(632)}`,
    paddingBottom: "56.25%",
    backgroundColor: "black",
  },
  videoWrapper: {
    width: "100%",
    maxHeight: `${theme.custom.pxToRem(632)} !important`,
    backgroundColor: "black",
    overflow: "hidden",
  },
  video: {
    position: "relative",
    paddingBottom: "56.25%",
    "& iframe": {
      width: "100%",
      height: "100%",
      position: "absolute",
      maxHeight: `${theme.custom.pxToRem(632)} !important`,
    },
  },
}));
