import React, { useState, useEffect } from "react";
import AlphabeticIcon from "assets/icons/icons/alphabetic.svg";
import FundCardIcon from "assets/icons/icons/fund_card.svg";
import Loader from "shared_components/Loader/Loader";
//MUI
import FollowingCard from "pages/Following/components/FollowingCard/FollowingCard";
import SortBy from "shared_components/InputComponents/SortBy/SortBy";
import SearchFilter from "shared_components/InputComponents/SearchFilter/SearchFilter";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
//REDUX
import { getFunds } from "redux/ducks/funds";
import { useDispatch, useSelector } from "react-redux";

const MainContent = () => {
  //items
  const POPOVER_ITEMS = [
    {
      text: "Followed Funds",
      icon: FundCardIcon,
    },
    {
      text: "Alphabetically",
      icon: AlphabeticIcon,
    },
  ];
  const dispatch = useDispatch();
  let { data: funds, is_loading } = useSelector((state) => state.data.funds);
  const { user_guid } = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("Followed Funds");
  const searchedPattern = new RegExp(`${filter}`, "gi");
  const filteredFunds = funds.filter((fund) => searchedPattern.test(fund.name));

  useEffect(() => {
    if (user_guid !== null) {
      dispatch(getFunds());
    }
  }, [dispatch, user_guid]);

  switch (sort) {
    case "Alphabetically": {
      filteredFunds.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      break;
    }
    case "Followed Funds": {
      filteredFunds.sort((a, b) => {
        if (a.is_user_following) return -1;
        else return 1;
      });
      break;
    }

    default:
      break;
  }
  return (
    <div className={classes.pageContent}>
      {/* funds count 
        <Typography variant="h2" className={classes.fundCounter}>
          {funds.length} {funds.length === 1 ? "Fund" : "Funds"}
        </Typography> */}
      {/* Interaction components */}
      <Grid container spacing={2}>
        <Grid item sm={12} md={10} lg={8} className={classes.gridItem}>
          <div className={classes.interaction}>
            <SearchFilter
              placeholder="Search Fund Name"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              className={classes.searchFilter}
            />
            <SortBy
              sort={sort}
              setSort={setSort}
              PopOverItems={POPOVER_ITEMS}
              className={classes.sortByWrapper}
            />
          </div>
          {/* Following cards */}
          {is_loading ? (
            <Loader size={40} />
          ) : (
            <div className={classes.followingCards}>
              {filteredFunds.length > 0 ? (
                filteredFunds.map((fund) => {
                  return (
                    <FollowingCard
                      fundTitle={fund.name}
                      fundId={fund.id}
                      fundGuid={fund.fund_guid}
                      imgURL={fund.fund_pic}
                      key={fund.id}
                      description={fund.description}
                      isFollowing={fund.is_user_following}
                      cyId={fund.name}
                    />
                  );
                })
              ) : (
                <>
                  <Typography variant="h3">Didn't find any results.</Typography>
                  <Typography variant="body1">
                    Make sure everything is spelled correctly or try different
                    keywords.
                  </Typography>
                </>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MainContent;
