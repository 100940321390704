import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    padding: "0 !important",
  },
  title: {
    margin: `${theme.custom.pxToRem(16)} 0px ${theme.custom.pxToRem(
      8
    )}!important`,
  },
}));
