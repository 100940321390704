import React from "react";
import GenericProfileImage from "assets/icons/icons/generic_profile_image.svg";
import moment from "moment";
import ClampLines from "react-clamp-lines";
// import CommentIcon from "assets/icons/icons/comment.svg"; TODO: add this when reply functionality will be ready
import MoreOptions from "assets/icons/icons/more_options.svg";
import RedClose from "assets/icons/icons/red_close_circle.svg";
import EditIcon from "assets/icons/icons/edit.svg";
import ThumbUp from "assets/icons/icons/thumbUp.svg";
import { ReactComponent as ThumbUpActive } from "assets/icons/icons/thumbUpActive.svg";
import ThumbUpDisabled from "assets/icons/icons/thumbUpDisabled.svg";
import { trim } from "shared_functions/shared_functions";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import LoginTooltip from "shared_components/LoginTooltip/LoginTooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import {
  deleteComment,
  likeComment,
  unlikeComment,
  editComment,
} from "redux/ducks/video";
import { useDispatch, useSelector } from "react-redux";
import ButtonSecondary from "shared_components/InputComponents/ButtonSecondary/ButtonSecondary";

const Comment = ({ comment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery("(max-width:599px)");
  //popover state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [body, setBody] = React.useState(comment.body);
  const is_anonymous = useSelector((state) => state.auth.user.is_anonymous);

  //events handlers
  //popover
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditComment = (e) => {
    setIsEditing(true);
    setBody(comment.body);
    handleClose();
  };

  const handleDeleteComment = (e) => {
    //this is necessary to fix propagation bug
    e.stopPropagation();
    handleClose();
    dispatch(deleteComment(comment.id));
    setIsEditing(false);
  };

  const handleLike = () => {
    dispatch(likeComment(comment.id));
  };

  const handleUnlike = () => {
    dispatch(unlikeComment(comment.id));
  };

  const handleOnChange = (e) => {
    setBody(e.target.value);
  };

  const handleCancelEditing = (e) => {
    setIsEditing(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editComment(comment.id, trim(body)));
    setIsEditing(false);
  };

  //mui stuff to make popover working
  const open = Boolean(anchorEl);
  const type = open ? "simple-popover" : undefined;

  return (
    <Grid xs={12} lg={8} item className={classes.comment}>
      <div className={classes.commentWrapper}>
        <span className={classes.avatarWrapper}>
          <Avatar
            src={comment.creator_pic_url || GenericProfileImage}
            className={classes.avatar}
          />
        </span>
        <Typography variant="h4">
          {comment.creator_name}
          <span className={classes.timestamp}>
            {" "}
            {isPhone ? <br></br> : <>&#183;</>}{" "}
            {moment(comment.time_stamp).fromNow()}
          </span>
        </Typography>
        {isEditing ? (
          <form
            autoComplete="off"
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <OutlinedInput
              variant="outlined"
              fullWidth
              placeholder="Edit your comment here"
              className={classes.textField}
              required
              color="primary"
              onChange={handleOnChange}
              value={body}
              multiline
            ></OutlinedInput>
            <div className={classes.submitButtonWrapper}>
              <ButtonSecondary
                onClick={handleCancelEditing}
                className={classes.submitButton}
              >
                Cancel
              </ButtonSecondary>
              <ButtonStyled
                type="submit"
                className={classes.submitButton}
                disabled={trim(body) === ""}
              >
                Edit
              </ButtonStyled>
            </div>
          </form>
        ) : (
          <>
            <Typography
              variant="body1"
              component="div"
              className={classes.body}
            >
              <ClampLines
                text={comment.body || ""}
                lines={4}
                ellipsis={"..."}
                buttons={true}
                moreText="Show More"
                lessText="Show Less"
              />
            </Typography>
            <div className={classes.actionButtonsWrapper}>
              <span className={classes.actionButton}>
                {is_anonymous ? (
                  <LoginTooltip
                    title="Like this comment?"
                    description="Login or sign-up to like comments"
                    placement="top"
                  >
                    <div>
                      <IconButton disabled>
                        <img src={ThumbUpDisabled} alt="thumb up" />
                      </IconButton>
                    </div>
                  </LoginTooltip>
                ) : comment.is_liked_by_user ? (
                  <IconButton onClick={handleUnlike}>
                    <ThumbUpActive />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleLike}>
                    <img src={ThumbUp} alt="thumb up" />
                  </IconButton>
                )}
                <Typography variant="body1">
                  {comment.like_count || 0}
                </Typography>
              </span>
              {/*
          add this when reply functionality will used 
          <span className={classes.actionButton}>
            <IconButton>
              <img src={CommentIcon} alt="watch later" />
            </IconButton>
            <Typography variant="h4">Reply</Typography>
          </span> */}
            </div>
          </>
        )}
      </div>
      <div className={classes.iconWrapper}>
        {comment.is_owned_by_current_user ? (
          <>
            <IconButton
              onClick={handleOpen}
              aria-describedby={type}
              classes={{ root: classes.icon }}
            >
              <img src={MoreOptions} alt="more options" />
            </IconButton>
            <Popover
              // this fixes error with elevation
              elevation={0}
              id={type}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{
                paper: classes.popover,
              }}
            >
              <div className={classes.popoverBodyWrapper}>
                <div className={classes.square}></div>
                <div
                  className={classes.popoverItemWrapper}
                  onClick={handleEditComment}
                >
                  <img src={EditIcon} alt="remove" />
                  <Typography varaint="h4" className={classes.popoverText}>
                    Edit comment
                  </Typography>
                </div>
                <div
                  className={classes.popoverItemWrapper}
                  onClick={handleDeleteComment}
                >
                  <img src={RedClose} alt="remove" />
                  <Typography varaint="h4" className={classes.popoverText}>
                    Delete comment
                  </Typography>
                </div>
              </div>
            </Popover>
          </>
        ) : null}
      </div>
    </Grid>
  );
};

export default Comment;
