import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  videoPlayerWrapper: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "auto",
    },
    [theme.breakpoints.down("md")]: {
      // width: "100%",
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
      //   16
      // )} !important`,
      display: "none",
    },
    backgroundColor: `${theme.palette.background.default}`,
    padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
      48
    )} !important`,
  },
  videoWrapper: {
    width: "100%",
    paddingBottom: `56.25% !important`,
    position: "relative",
    backgroundColor: "black",
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    overflow: "hidden",
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    "& iframe": {
      width: "100%",
      height: "100%",
    },
  },
  videoLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  videoTitle: {
    color: `${theme.palette.primary.main} !important`,
    marginTop: `${theme.custom.pxToRem(16)} !important`,
  },
  infoWrapper: {
    display: "flex",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginRight: `${theme.custom.pxToRem(16)} !important`,
      marginLeft: `-${theme.custom.pxToRem(16)} !important`,
    },
  },
  infoLabel: {
    color: `${theme.palette.grey_tone["400"]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: `${theme.custom.pxToRem(16)} !important`,
  },
  videoDescription: {
    "& .clamp-lines__button": {
      padding: `${theme.custom.pxToRem(10)} 0 0 0`,
      border: "none",
      background: "none",
      cursor: "pointer",
      fontFamily: "ProximaNovaSemibold",
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: `${theme.custom.pxToRem(16)} !important`,
      lineHeight: `${theme.custom.pxToRem(24)} !important`,
      letterSpacing: `${theme.custom.pxToRem(0.18)} !important`,
      color: theme.palette.grey_tone["400"],
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.custom.pxToRem(14)} !important`,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: `${theme.custom.pxToRem(12)} !important`,
      },
    },
    "& .clamp-lines__button:hover": {
      textDecoration: "underline",
    },
  },
}));
