import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  backGround: {
    backgroundColor: `${theme.palette.background.secondary}`,
  },
  grid: {
    width: `calc(100% - ${theme.custom.pxToRem(72)})`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.custom.pxToRem(40)})`,
      margin: `0 auto`,
      paddingBottom: `0`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.custom.pxToRem(8)})`,
      margin: `0 auto`,
      paddingBottom: `0`,
    },
    margin: `0 auto`,
    "&:last-child": {
      margin: `0 auto`,
      paddingBottom: `0`,
    },
  },
  gridItem: {
    marginBottom: `${theme.custom.pxToRem(48)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: `${theme.custom.pxToRem(32)} !important`,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  container: {
    width: `calc(100% - ${theme.spacing(12)}px) !important`,
    margin: `0 auto ${theme.spacing(3)}px !important`,
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: `${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(
      8
    )} !important`,
    margin: `${theme.spacing(4)}px ${theme.spacing(5)}px `,
    [theme.breakpoints.down("sm")]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px `,
    },
    [theme.breakpoints.down("xs")]: {
      transform: `scale(0.5) `,
      margin: 0,
    },
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
  },
  switch: {
    marginLeft: `${theme.custom.pxToRem(8)} !important`,
  },
  sectionTitle: {
    marginBottom: `${theme.spacing(4)}px`,
    borderTop: `1px solid ${theme.palette.background.border} !important`,
    paddingTop: `${theme.spacing(6)}px `,
    paddingLeft: `${theme.spacing(6)}px `,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.spacing(3)}px `,
      marginBottom: `${theme.spacing(3)}px`,
      paddingLeft: `${theme.spacing(4)}px `,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: `${theme.spacing(2)}px `,
      marginBottom: `${theme.spacing(2)}px`,
      paddingLeft: `${theme.spacing(2)}px `,
    },
  },
  applicationsDocsTitle: {
    marginBottom: `${theme.spacing(4)}px`,
    borderTop: `none !important`,
    paddingLeft: `${theme.spacing(6)}px `,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `${theme.spacing(4)}px `,
      marginBottom: `${theme.spacing(3)}px `,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: `${theme.spacing(2)}px `,
      marginBottom: `${theme.spacing(2)}px `,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: `${theme.custom.pxToRem(32)} ${theme.custom.pxToRem(
      12
    )} ${theme.custom.pxToRem(24)}`,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)} `,
    },
  },
  text: {
    wordBreak: "break-all",
  },
  logo: {
    height: `${theme.custom.pxToRem(108)} `,
    width: `100%`,
    margin: `0 auto ${theme.custom.pxToRem(8)}`,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(80)} `,
    },
    [theme.breakpoints.down("xs")]: {
      height: `${theme.custom.pxToRem(72)} `,
    },
  },
  country: {
    color: `${theme.palette.primary.main} !important`,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: `${theme.custom.pxToRem(24)}`,
    borderTop: `1px solid ${theme.palette.background.border} !important`,
    marginTop: `${theme.custom.pxToRem(24)} !important`,
    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(16)}`,
    },
  },
  documentIcon: {
    height: `${theme.custom.pxToRem(64)} !important`,
    width: `${theme.custom.pxToRem(64)} !important`,
    display: "block",
    margin: `0 auto ${theme.custom.pxToRem(16)}`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(8)} !important`,
    },
  },
  docName: {
    marginBottom: `${theme.custom.pxToRem(8)} !important`,
  },
  docButton: {
    margin: `${theme.custom.pxToRem(16)} auto 0`,
    textDecoration: "none",
    width: "100%",
    maxWidth: `${theme.custom.pxToRem(144)} !important`,
  },
}));
