import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { investorTypes } from "auth/investorTypes";
// Assets
import profileBadge from "assets/icons/investorOnboarding/profileBadge.svg";
// MUI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// data object
const investorText = [
  {
    title: "*What is a Retail Investor?",
    description:
      "An individual investor, retail investor or a private person who commits capital for their personal, not on behalf of a company. If you are unsure which type of investor you are, you are most likely a retail investor.",
    disclaimer: " ",
  },
  {
    title: "*What is a Qualified Investor?",
    description:
      "A qualified investor, also referred to as an accredited investor, is an individual or entity that can purchase securities that aren't registered with the securities regulation authorities primarily due to the investor's income and/or net worth.",
    disclaimer:
      "Disclaimer: By selecting qualified investor, you acknowledge that you will be watching qualified investor video content at your own risk.",
  },
  {
    title: "*What is a Institutional Investor?",
    description:
      "An institutional investor is a company or organization that invests money on behalf of other people or organisations.",
    disclaimer:
      "Disclaimer: By selecting institutional investor, you acknowledge that you will be watching institutional investor video content at your own risk.",
  },
];

const InvestorExperiencePage = ({ investorType, setInvestorType }) => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");

  const [title, setTitle] = useState(investorText[0].title);
  const [description, setDescription] = useState(
    investorText[0].setDescription
  );
  const [disclaimer, setDisclaimer] = useState(investorText[0].setDescription);

  // set the description based on investor experience selection
  useEffect(() => {
    if (investorType === investorTypes.RETAIL_INVESTOR) {
      setTitle(investorText[0].title);
      setDescription(investorText[0].description);
      setDisclaimer(investorText[0].disclaimer);
    } else if (investorType === investorTypes.QUALIFIED_INVESTOR) {
      setTitle(investorText[1].title);
      setDescription(investorText[1].description);
      setDisclaimer(investorText[1].disclaimer);
    } else if (investorType === investorTypes.INSTITUTIONAL_INVESTOR) {
      setTitle(investorText[2].title);
      setDescription(investorText[2].description);
      setDisclaimer(investorText[2].disclaimer);
    }
  }, [investorType]);

  // connect to redux here
  useEffect(() => {
    if (investorType !== null) {
    }
  }, [investorType]);

  return (
    <div>
      <img src={profileBadge} alt="icon badge" className={classes.badge} />
      <Grid container>
        <Grid item xs={12} md={8} lg={6}>
          <Typography
            variant={isPhone ? "h3" : "h2"}
            className={classes.header}
          >
            Select type of investor
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.text}>
        <Grid item xs={12} md={8} lg={6}>
          <Typography variant="body1">
            Let us know what kind of investment experience you have. We use this
            information to determine what types of information regulation allows
            us to display for your user type.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={5} lg={4} className={classes.form}>
          {/* Insert forms here */}
          <Select
            value={investorType}
            variant="outlined"
            fullWidth
            onChange={(e) => setInvestorType(e.target.value)}
          >
            <MenuItem value={investorTypes.RETAIL_INVESTOR}>
              Retail Investor
            </MenuItem>
            <MenuItem value={investorTypes.QUALIFIED_INVESTOR}>
              Qualified Investor
            </MenuItem>
            <MenuItem value={investorTypes.INSTITUTIONAL_INVESTOR}>
              Institutional Investor
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container className={classes.informationGrid}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h4" className={classes.explainerHeader}>
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
          <Typography variant="body2" className={classes.disclaimer}>
            {disclaimer}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestorExperiencePage;
