import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  grid: {
    width: `calc(100% - ${theme.custom.pxToRem(72)})`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.custom.pxToRem(40)})`,
      margin: `0 auto`,
      paddingBottom: `0`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.custom.pxToRem(8)})`,
      margin: `0 auto`,
      paddingBottom: `0`,
    },
    margin: `0 auto`,
    "&:last-child": {
      margin: `0 auto`,
      paddingBottom: `0`,
    },
  },
  gridItem: {
    marginBottom: `${theme.custom.pxToRem(48)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: `${theme.custom.pxToRem(32)} !important`,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: `${theme.custom.pxToRem(32)} ${theme.custom.pxToRem(
      12
    )} ${theme.custom.pxToRem(24)}`,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(16)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(8)} `,
    },
  },
  avatar: {
    width: `${theme.custom.pxToRem(64)} !important`,
    height: `${theme.custom.pxToRem(64)} !important`,
  },
  avatarBorder: {
    width: `${theme.custom.pxToRem(68)} !important`,
    height: `${theme.custom.pxToRem(68)} !important`,
    borderRadius: "50%",
    background: `${theme.palette.background.vertical_gradient}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  managerName: {
    color: `${theme.palette.primary.main} !important`,
    margin: `${theme.custom.pxToRem(16)} 0 ${theme.custom.pxToRem(
      8
    )} !important`,
  },
  managerPosition: {
    color: `${theme.palette.grey_tone["400"]} !important`,
  },
  linksButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    gap: `${theme.custom.pxToRem(16)} !important`,
    "& a": {
      textDecoration: "none",
    },
  },
  linkButton: {
    "&:hover": {
      filter: `drop-shadow(${theme.custom.palette.shadows.lvl2})`,
    },
    [theme.breakpoints.down("sm")]: {
      "&:hover": {
        filter: `none`,
      },
    },
  },
}));
