import React from "react";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import SwitchStyled from "shared_components/InputComponents/SwitchStyled/SwitchStyled";
//REDUX
import { updateNotificationSetting } from "redux/ducks/user";
import { useDispatch } from "react-redux";

const ListItem = ({ item, icon }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleToggle = (e) => {
    if (item.name === "Receive notifications on email") return;
    dispatch(updateNotificationSetting(item.id, e.target.checked));
  };

  return (
    <div className={classes.listItem}>
      <div className={classes.wrapper}>
        <img src={icon} alt="icon" className={classes.icon} />
        <Typography variant="body1">{item.name}</Typography>
      </div>
      <SwitchStyled
        className={classes.switch}
        handleSwitchClick={handleToggle}
        checked={item.is_turn_on}
        disabled={item.name === "Receive notifications on email"}
      />
    </div>
  );
};

export default ListItem;
