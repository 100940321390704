import * as api from "api/api";
import userTypes from "auth/userTypes";
import { getCategories } from "./categories";
import { constructAndSendError } from "shared_functions/shared_functions";
import { getDataByIpAddress } from "auth/functions";

//action types
const SET_PREFERED_CATEGORIES = "user/SET_PREFERED_CATEGORIES";
const START_LOADING_PREFERED_CATEGORIES =
  "user/START_LOADING_PREFERED_CATEGORIES";
const STOP_LOADING_PREFERED_CATEGORIES =
  "user/STOP_LOADING_PREFERED_CATEGORIES";
const SET_ANONYMOUS = "user/SET_ANONYMOUS";
const SET_INVESTOR = "user/SET_INVESTOR";
const START_LOADING_USER = "user/START_LOADING_USER";
const STOP_LOADING_USER = "user/STOP_LOADING_USER";
const UPDATE_INVESTOR_TYPE = "user/UPDATE_INVESTOR_TYPE";
const UPDATE_INVESTOR_COUNTRY = "user/UPDATE_INVESTOR_COUNTRY";
const SET_USER_AUTHENTICATED = "user/SET_USER_AUTHENTICATED";
const SET_USER_UNAUTHENTICATED = "user/SET_USER_UNAUTHENTICATED";
export const RESET_REDUX = "user/RESET_REDUX";
const SET_NOTIFICATIONS = "user/SET_NOTIFICATIONS";
const SET_MORE_NOTIFICATIONS = "user/SET_MORE_NOTIFICATIONS";
const UPDATE_NOTIFICATIONS_READ = "user/UPDATE_NOTIFICATIONS_READ";
const SET_NOTIFICATIONS_SETTINGS = "user/SET_NOTIFICATIONS_SETTINGS";
const START_LOADING_NOTIFICATIONS_SETTINGS =
  "user/START_LOADING_NOTIFICATIONS_SETTINGS";
const STOP_LOADING_NOTIFICATIONS_SETTINGS =
  "user/STOP_LOADING_NOTIFICATIONS_SETTINGS";
const UPDATE_NOTIFICATION_SETTING = "user/UPDATE_NOTIFICATION_SETTING";
const SET_LAST_PAGE = "user/SET_LAST_PAGE";
const SET_WAS_LAST_MAIN_PAGE = "user/SET_WAS_LAST_MAIN_PAGE";

const initialState = {
  is_loading: true,
  first_name: "",
  last_name: "",
  is_anonymous: true,
  usedOAuth: "", //used to determitane which oauth was used to login
  wasLastMainPage: false,
  picture_url: "",
  user_guid: null,
  last_page_url: "/", //need to be initialy set to home page
  id: null,
  enabled: false,
  email: "",
  country: null,
  investor_type: null,
  app_id: null,
  device: null,
  language: null,
  city: null,
  ip: null,
  region: null,
  timezone: null,
  settings: {
    is_loading: true,
    notifications_preferences: [],
    notifications_delivery: [],
  },
  ordered_categories: {
    is_loading: true,
    data: [],
  },
  notifications: {
    paging: {},
    unread: 0,
    data: [],
  },
};

export const authorize = (authObject) => async (dispatch) => {
  try {
    dispatch(startLoadingUser());
    const { data } = await api.authorize(authObject);
    //this is important to overwrite any old user_guid in LS before updating user preferences
    localStorage.setItem("user_guid", data.user[0].user_guid);
    //if we have new user, update his tracking data
    if (data.created_user) {
      const ipData = await getDataByIpAddress();
      await api.updateUserPreferences(ipData);
      //merge IP data with user from authorize call
      data.user[0] = { ...data.user[0], ...ipData };
    }
    let usedOAuth = "";
    switch (parseInt(authObject.token_type)) {
      case userTypes.google: {
        usedOAuth = "Google";
        break;
      }
      case userTypes.facebook: {
        usedOAuth = "Facebook";
        break;
      }
      case userTypes.linkedIn: {
        usedOAuth = "LinkedIn";
        break;
      }
      default: {
        break;
      }
    }
    data.user[0].usedOAuth = usedOAuth;
    //this will call root reducer in store_config.js and set redux to initial state
    dispatch({ type: RESET_REDUX });
    dispatch({ type: SET_USER_AUTHENTICATED, payload: data.user[0] });
    //set investor token if the user is not anonymous
    if (parseInt(authObject.token_type) > userTypes.anonymous) {
      localStorage.setItem("investor_token", authObject.access_token);
      localStorage.setItem("token_type", authObject.token_type);
    }
    //sets is_anonymous to true/false
    if (parseInt(authObject.token_type) === userTypes.anonymous) {
      dispatch(setUserAnonymous());
    } else {
      dispatch(setUserLoggedin());
      //fetch notifications for user
      dispatch(getNotifications());
    }
    dispatch(stopLoadingUser());
    return data;
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/authorize",
      "/user/authorize",
      [],
      JSON.stringify(authObject)
    );
    console.log(error);
  }
};

export const logout = () => (dispatch) => {
  sessionStorage.removeItem("scrollOffset");
  localStorage.removeItem("user_guid");
  localStorage.removeItem("investor_token");
  localStorage.setItem("token_type", userTypes.anonymous);
  dispatch({ type: SET_USER_UNAUTHENTICATED });
  //needs to be done so anonymous user is set back as active user
  const data = {};
  data.access_token = localStorage.getItem("anonym_token");
  data.token_type = userTypes.anonymous;
  dispatch(authorize(data));
};

export const setUserAnonymous = () => (dispatch) => {
  dispatch({ type: SET_ANONYMOUS });
};

export const setUserLoggedin = () => (dispatch) => {
  dispatch({ type: SET_INVESTOR });
};

export const getPreferedCategories = () => async (dispatch) => {
  try {
    dispatch(startLoadingPrefferedCategories());
    const {
      data: { ordered_categories: data },
    } = await api.getPreferedCategories();
    dispatch({ type: SET_PREFERED_CATEGORIES, payload: data });
    dispatch(stopLoadingPrefferedCategories());
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/getPreferedCategories",
      "/user/preferences/categories"
    );
    console.log(error);
  }
};

export const setNewCategoriesOrder =
  (orderedCategories) => async (dispatch) => {
    try {
      await api.setNewCategoriesOrder(orderedCategories);
      //need to update list in redux/ducks/categories
      dispatch(getCategories());
    } catch (error) {
      constructAndSendError(
        error.message,
        "user/setNewCategoriesOrder",
        "/user/preferences/categories",
        [],
        JSON.stringify(orderedCategories)
      );
      console.log(error);
    }
  };

export const setPreferedCategories = (ordered_categories) => (dispatch) => {
  dispatch({ type: SET_PREFERED_CATEGORIES, payload: ordered_categories });
};

export const setNotificationsSettings = () => async (dispatch) => {
  try {
    dispatch(startLoadingNotificationSettings());
    const { data } = await api.getUserNotificationsSettings();
    dispatch({ type: SET_NOTIFICATIONS_SETTINGS, payload: data });
    dispatch(stopLoadingNotificationSettings());
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/setNotificationsSettings",
      "/user/preferences/notifications"
    );
    console.log(error);
  }
};

export const updateNotificationSetting =
  (preference_id, is_turn_on) => async (dispatch) => {
    try {
      await api.updateUserNotificationSetting(preference_id, is_turn_on);
      dispatch({
        type: UPDATE_NOTIFICATION_SETTING,
        payload: { preference_id, is_turn_on },
      });
    } catch (error) {
      constructAndSendError(
        error.message,
        "user/updateNotificationSetting",
        "/user/preferences/notifications",
        [`preference_id:${preference_id}`, `is_turned_on:${is_turn_on}`]
      );
      console.log(error);
    }
  };

export const setNewInvestorType = (type) => (dispatch) => {
  dispatch({ type: UPDATE_INVESTOR_TYPE, payload: type });
};

export const setNewInvestorCountry = (code) => (dispatch) => {
  dispatch({ type: UPDATE_INVESTOR_COUNTRY, payload: code });
};

export const updateUserPreferences = (params) => async (dispatch) => {
  try {
    //does not need to upload redux bcs data will be already in redux from form
    await api.updateUserPreferences(params);
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/updateUserPreferences",
      "/user/preferences",
      [JSON.stringify(params)]
    );
    console.log(error);
  }
};

export const getNotifications = (page, size) => async (dispatch) => {
  try {
    const { data } = await api.getNotifications(page, size);
    dispatch({ type: SET_NOTIFICATIONS, payload: data });
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/getNotifications",
      "/user/notifications",
      [`page:${page}`, `size:${size}`]
    );
    console.log(error);
  }
};

export const getMoreNotifications = (page, size) => async (dispatch) => {
  try {
    const { data } = await api.getNotifications(page, size);
    dispatch({ type: SET_MORE_NOTIFICATIONS, payload: data });
    //mark new notifications received as read
    const notificationsIds = data.data
      .filter((notification) => !notification.was_read)
      .map((notification) => notification.id);
    if (notificationsIds.length > 0)
      dispatch(markNotificationsRead(notificationsIds));
  } catch (error) {
    constructAndSendError(
      error.message,
      "user/getMoreNotifications",
      "/user/notifications",
      [`page:${page}`, `size:${size}`]
    );
    console.log(error);
  }
};

export const markNotificationsRead =
  (notifications_ids) => async (dispatch) => {
    try {
      await api.markNotificationsRead(notifications_ids);
    } catch (error) {
      constructAndSendError(
        error.message,
        "user/getNotifications",
        "/user/notifications",
        [],
        JSON.stringify(notifications_ids)
      );
      console.log(error);
    }
  };

export const updateNotificationsRead = () => (dispatch) => {
  dispatch({ type: UPDATE_NOTIFICATIONS_READ });
};

export const setLastPageUrl = (url) => (dispatch) => {
  dispatch({ type: SET_LAST_PAGE, payload: url });
};

export const setWasLastMainPage = (state) => (dispatch) => {
  dispatch({ type: SET_WAS_LAST_MAIN_PAGE, payload: state });
};

export const startLoadingUser = () => (dispatch) => {
  dispatch({ type: START_LOADING_USER });
};

export const stopLoadingUser = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_USER });
};

export const startLoadingPrefferedCategories = () => (dispatch) => {
  dispatch({ type: START_LOADING_PREFERED_CATEGORIES });
};

export const stopLoadingPrefferedCategories = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_PREFERED_CATEGORIES });
};

export const startLoadingNotificationSettings = () => (dispatch) => {
  dispatch({ type: START_LOADING_NOTIFICATIONS_SETTINGS });
};

export const stopLoadingNotificationSettings = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_NOTIFICATIONS_SETTINGS });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AUTHENTICATED: {
      return { ...state, ...action.payload };
    }
    case SET_USER_UNAUTHENTICATED: {
      return { ...initialState };
    }
    case SET_PREFERED_CATEGORIES: {
      return {
        ...state,
        ordered_categories: {
          ...state.ordered_categories,
          data: action.payload,
        },
      };
    }
    case SET_ANONYMOUS: {
      return { ...state, is_anonymous: true };
    }
    case SET_INVESTOR: {
      return { ...state, is_anonymous: false };
    }
    case SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case SET_MORE_NOTIFICATIONS: {
      const { paging, data } = action.payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          paging,
          data: [...state.notifications.data, ...data],
        },
      };
    }
    case UPDATE_NOTIFICATIONS_READ: {
      let unreadCount = 0;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: state.notifications.data.map((notification) => {
            if (!notification.was_read) {
              unreadCount++;
              return { ...notification, was_read: true };
            } else return notification;
          }),
          unread: state.notifications.unread - unreadCount,
        },
      };
    }
    case SET_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          notifications_preferences: action.payload.notifications_preferences,
          notifications_delivery: action.payload.notifications_delivery,
        },
      };
    }
    case UPDATE_NOTIFICATION_SETTING: {
      return {
        ...state,
        settings: {
          notifications_preferences:
            state.settings.notifications_preferences.map((notification) => {
              if (notification.id === action.payload.preference_id)
                return {
                  ...notification,
                  is_turn_on: action.payload.is_turn_on,
                };
              else return notification;
            }),
          notifications_delivery: state.settings.notifications_delivery.map(
            (notification) => {
              if (notification.id === action.payload.preference_id)
                return {
                  ...notification,
                  is_turn_on: action.payload.is_turn_on,
                };
              else return notification;
            }
          ),
        },
      };
    }
    case UPDATE_INVESTOR_TYPE: {
      return { ...state, investor_type: action.payload };
    }
    case UPDATE_INVESTOR_COUNTRY: {
      return { ...state, country: action.payload };
    }
    case SET_LAST_PAGE: {
      return {
        ...state,
        last_page_url: action.payload,
      };
    }
    case SET_WAS_LAST_MAIN_PAGE: {
      return {
        ...state,
        wasLastMainPage: action.payload,
      };
    }

    case START_LOADING_USER: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING_USER: {
      return { ...state, is_loading: false };
    }
    case START_LOADING_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          is_loading: true,
        },
      };
    }
    case STOP_LOADING_NOTIFICATIONS_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          is_loading: false,
        },
      };
    }
    case START_LOADING_PREFERED_CATEGORIES: {
      return {
        ...state,
        ordered_categories: {
          ...state.ordered_categories,
          is_loading: true,
        },
      };
    }
    case STOP_LOADING_PREFERED_CATEGORIES: {
      return {
        ...state,
        ordered_categories: {
          ...state.ordered_categories,
          is_loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
