import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  bottomSection: {
    width: "100% !important",
    minHeight: "25vh",
    margin: "0 !important",
    position: "relative",
    zIndex: 100,
    backgroundColor: `${theme.palette.highlight.main}`,
    display: "flex",
    justifyContent: "center",
  },
  wave: {
    position: "absolute",
    transform: "translateY(-60%)",
    left: 0,
    zIndex: -100,
  },

  footerGrid: {
    width: "90vw",
    margin: "auto",
  },
  swiper: {
    "& .swiper-pagination-bullet-active": {
      background: "white !important",
      marginTop: `${theme.custom.pxToRem(8)} !important`,
    },
  },
  swiperSlide: {
    backgroundColor: "transparent !important",
    display: "flex",
    justifyContent: "center",
  },
}));
