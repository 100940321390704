import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navigation: {
    display: "flex",
    padding: `0 ${theme.spacing(6)}px`,
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "scroll",
    // hide scroll bar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(2)}px`,
      justifyContent: "flex-start",
      display: "block",
    },
  },
  tabsWrapper: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down("xs")]: {
      gap: `${theme.custom.pxToRem(32)}`,
      width: "fit-content",
    },
  },
  button: {
    width: `${theme.custom.pxToRem(204)}`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
