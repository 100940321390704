import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  emptyStateWrapper: {
    marginTop: `${theme.custom.pxToRem(105)}`,
    padding: `0 ${theme.custom.pxToRem(16)} ${theme.custom.pxToRem(105)} `,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  itemsWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  emptyStateTitle: {
    marginTop: `${theme.custom.pxToRem(32)} !important`,
  },
  emptyStateText: {
    marginTop: `${theme.custom.pxToRem(12)} !important`,
  },
  emptyStateButton: {
    marginTop: `${theme.custom.pxToRem(24)} !important`,
  },
  reoderLink: {
    textDecoration: "none",
  },
}));
