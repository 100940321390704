import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageContent: {
    padding: `${theme.custom.pxToRem(48)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(32)} !important`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  gridItem: {
    width: "100%",
  },
  fundCounter: {
    marginBottom: 16,
  },
  interaction: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-end",
    },
  },
  searchFilter: {
    maxWidth: `${theme.custom.pxToRem(546)} !important`,

    "& MuiOutlinedInput-root": {
      minHeight: "0 !important",
    },
  },
  sortByWrapper: {
    width: `${theme.custom.pxToRem(185)} !important`,
    marginLeft: `${theme.custom.pxToRem(40)} !important`,
  },
  followingCards: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));
