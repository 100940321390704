import React from "react";
import { ReactComponent as CoherraLogo } from "assets/icons/logos/coherra_blue.svg";
import {
  ROOT,
  WHERE_TO_INVEST,
  LOGIN,
  FUND,
  COMPANY,
  FUNDS,
} from "navigation/constants";
import Link from "shared_components/Link/Link";
import { useParams } from "react-router-dom";
import BackButton from "shared_components/BackButton/BackButton";
//MUI
import useStyles from "./styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// sub components
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import NavMenu from "./NavMenu/NavMenu";
import NotificationPopOver from "./NotifcationPopOver/NotificationPopOver";
//REDUX
import { useSelector } from "react-redux";

const Navbar = ({ displayWhereToInvest, displayCompanyLogo }) => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:959px)");
  //this needs to have a user mapped, otherwise it will not display annonymous user correctly
  const user = useSelector((state) => state.auth.user);
  const companyLogo = useSelector((state) => state.data.fund.company_logo);
  const companyGuid = useSelector((state) => state.data.fund.company_guid);
  const { guid: fund_guid } = useParams();

  //events

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.loggosWrapper}>
          {isTablet && window.location.pathname !== ROOT && (
            <>
              <BackButton className={classes.backButton} />
              <Divider orientation="vertical" />
            </>
          )}
          {/* hide coherra logo when companyPic is displayed and we are on tablets */}
          {(!displayCompanyLogo || !isTablet) && (
            <>
              <Link to={ROOT} className={classes.logoLink}>
                <CoherraLogo className={classes.logo} />
              </Link>
              <Divider orientation="vertical" />
            </>
          )}
          {displayCompanyLogo && companyLogo !== null && (
            <>
              <Link
                to={`${COMPANY}/${companyGuid}${FUNDS}`}
                className={classes.companyLogoLink}
              >
                <img
                  src={companyLogo}
                  alt="companyLogo"
                  className={classes.companyLogo}
                />
              </Link>
              <Divider orientation="vertical" />
            </>
          )}
          {/* display where to invest button only when props displayWhereToInvest are passed down  */}
          {displayWhereToInvest && !isTablet && (
            <>
              <Link
                to={`${FUND}/${fund_guid}${WHERE_TO_INVEST}`}
                className={classes.whereToInvestLink}
              >
                <ButtonStyled size="lg" variant="cta">
                  Where To Invest
                </ButtonStyled>
              </Link>
            </>
          )}
        </div>
        <div className={classes.actionsWrapper}>
          {!user.is_loading && (
            <>
              {user.is_anonymous ? (
                // do not display button on /login page
                window.location.pathname === LOGIN ? null : (
                  <Link
                    to={(location) => {
                      return {
                        ...location,
                        pathname: LOGIN,
                        state: location.pathname,
                      };
                    }}
                    className={classes.whereToInvestLink}
                  >
                    <ButtonStyled size="lg" fullWidth variant="cta">
                      <Typography variant="h4">Login / Sign-up</Typography>
                    </ButtonStyled>
                  </Link>
                )
              ) : (
                <>
                  <NotificationPopOver />
                  <Divider
                    orientation="vertical"
                    classes={{ root: classes.actionsDivider }}
                  />
                  <NavMenu />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
