import React from "react";
//MUI
import Grid from "@material-ui/core/Grid";
import SocialLoginCard from "./SocialLoginCard/SocialLoginCard";
import useStyles from "./styles";

const MainSection = () => {
  const classes = useStyles();
  return (
    <main className={classes.mainSection}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        className={classes.mainGrid}
      >
        <Grid item xs={12} sm={8} md={6} lg={4} className={classes.socialModal}>
          <SocialLoginCard />
        </Grid>
      </Grid>
    </main>
  );
};

export default MainSection;
