import React from "react";
import ClampLines from "react-clamp-lines";
import { trim } from "lodash";
import TabBar from "../../components/TabBar/TabBar";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import Loader from "shared_components/Loader/Loader";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { useSelector } from "react-redux";

const About = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const videoChannel = useSelector((state) => state.data.video_channel);

  return (
    <>
      <TabBar />
      <div className={classes.backGround}>
        {videoChannel.is_loading ? (
          <Loader size={40} />
        ) : (
          <>
            <Grid
              container
              spacing={3}
              className={classes.grid}
              justifyContent="space-between"
              direction={isPhone ? "column-reverse" : "row"}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h2" className={classes.title}>
                  About us
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.description}
                  data-cy="fundDescription"
                >
                  <ClampLines
                    text={
                      videoChannel.description !== null &&
                      trim(videoChannel.description) !== ""
                        ? videoChannel.description
                        : "The fund does not have about"
                    }
                    lines={6}
                    ellipsis={"..."}
                    buttons={true}
                    moreText="Read&#160;More"
                    lessText="Read&#160;Less"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <DetailsCard />
              </Grid>
            </Grid>
            <div className={classes.bottomPagePadding} />
          </>
        )}
      </div>
    </>
  );
};

export default About;
