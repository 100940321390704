import React from "react";
import ArrowBackIcon from "assets/icons/icons/arrow_back.svg";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import { ReactComponent as PlayIcon } from "assets/icons/icons/mediaBlue.svg";
import { ReactComponent as ThumbUpIcon } from "assets/icons/icons/thumbUpBlue.svg";
import { ReactComponent as CameraIcon } from "assets/icons/icons/video_camera.svg";
import Wave from "assets/decoration/WaveVertical.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import VideoSnippet from "shared_components/VideoSnippet/VideoSnippet";
import Loader from "shared_components/Loader/Loader";
import { numberWithCommas } from "shared_functions/shared_functions";
import ClampLines from "react-clamp-lines";
import VideoCard from "shared_components/VideoCard/VideoCard";
//MUI
import useStyles from "./styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedPlaylist,
  getPlaylistVideos,
  clearSelectedPlaylist,
} from "redux/ducks/company";

const PlaylistDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { playlistGuid } = useParams();
  const isTablet = useMediaQuery("(max-width:959px)");
  const isPhone = useMediaQuery("(max-width:599px)");
  const VIDEOS_SIZE = 12;
  const { user_guid } = useSelector((state) => state.auth.user);
  const companyGuid = useSelector((state) => state.data.company.company_guid);
  const selectedPlaylist = useSelector(
    (state) => state.data.company.selected_playlist
  );
  const data = useSelector(
    (state) => state.data.company.selected_playlist.videos.data
  );
  const paging = useSelector(
    (state) => state.data.company.selected_playlist.videos.paging
  );

  //clear the previous storage if playlist guids are not the same
  if (selectedPlaylist.playlist_guid !== null) {
    if (selectedPlaylist.playlist_guid !== playlistGuid) {
      dispatch(clearSelectedPlaylist());
    }
  }

  React.useEffect(() => {
    //makes sure we wait for user_guid before we make requests
    if (user_guid !== null) {
      //makes sure we wait for company to be in the redux
      if (companyGuid !== null) {
        //only make request if we do not have data already in the redux
        if (selectedPlaylist.playlist_guid !== playlistGuid)
          dispatch(setSelectedPlaylist(playlistGuid));
      }
    }
  }, [user_guid, companyGuid]);

  //events handlers
  const handleForwardToPreviousPage = () => {
    history.goBack();
  };

  const fetchMoreVideos = () => {
    dispatch(getPlaylistVideos(playlistGuid, paging.page + 1, VIDEOS_SIZE));
  };

  return (
    <>
      <section className={classes.section}>
        <div className={classes.leftSide}>
          {!isTablet && (
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={handleForwardToPreviousPage}
            >
              <img src={ArrowBackIcon} alt="arrow back" />
            </IconButton>
          )}
          <Typography variant="h2" className={classes.name}>
            {selectedPlaylist.name}
          </Typography>
          {isPhone && (
            <div className={classes.underTitle}>
              <Typography variant="h4" className={classes.playlistLabel}>
                Playlist
              </Typography>
              <Typography variant="h4">
                {`${numberWithCommas(selectedPlaylist.videos_count)} Videos`}
              </Typography>
            </div>
          )}
          {isPhone && <Divider orientation="horizontal" />}
          <Typography
            variant="body2"
            component="div"
            className={classes.description}
          >
            {isPhone ? (
              <ClampLines
                text={
                  selectedPlaylist.description !== null
                    ? selectedPlaylist.description
                    : ""
                }
                lines={5}
                ellipsis={"..."}
                buttons={true}
                moreText="SHOW&#160;MORE"
                lessText="SHOW&#160;LESS"
              />
            ) : (
              <CustomEllipsis
                lines={isTablet ? 4 : 3}
                text={selectedPlaylist.description}
              />
            )}
          </Typography>
          {isTablet && !isPhone && (
            <div className={classes.iconsWrapper}>
              <div className={classes.iconWrapper}>
                <CameraIcon className={classes.icon} />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.videos_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Videos
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <ThumbUpIcon className={classes.icon} />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.likes_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Likes
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <PlayIcon className={classes.icon} />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.views_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Views
                </Typography>
              </div>
            </div>
          )}
        </div>
        {!isTablet && <Divider orientation="vertical" flexItem />}
        <div className={classes.rightSide}>
          {!isTablet && (
            <div className={classes.iconsWrapper}>
              <div className={classes.iconWrapper}>
                <CameraIcon />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.videos_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Videos
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <ThumbUpIcon />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.likes_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Likes
                </Typography>
              </div>
              <div className={classes.iconWrapper}>
                <PlayIcon />
                <Typography variant="body1" className={classes.count}>
                  {numberWithCommas(selectedPlaylist.views_count)}
                </Typography>
                <Typography variant="body1" className={classes.label}>
                  Views
                </Typography>
              </div>
            </div>
          )}

          <div className={classes.pictureWrapper}>
            <img
              src={selectedPlaylist.playlist_pic}
              alt="banner"
              className={classes.picture}
            />
            {!isTablet && (
              <img src={Wave} alt="wave" className={classes.wave} />
            )}
          </div>
        </div>
      </section>
      {data !== null && data.length > 0 ? (
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreVideos}
          hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
          loader={<Loader />}
        >
          <Grid container spacing={3} className={classes.grid}>
            {data.map((video) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  className={classes.gridItem}
                  key={video.video_guid}
                >
                  {isPhone ? (
                    <VideoCard snippet={video} />
                  ) : (
                    <VideoSnippet snippet={video} />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Loader size={40} />
      )}
    </>
  );
};

export default PlaylistDetails;
