import React from "react";
import Wave from "assets/decoration/WaveVertical.svg";
import { useParams } from "react-router-dom";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";
import { numberWithCommas } from "shared_functions/shared_functions";
//MUI
import Loader from "shared_components/Loader/Loader";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStyles from "./styles";

//REDUX
import {
  getCompany,
  followCompany,
  unfollowCompany,
} from "redux/ducks/company";
import { useDispatch, useSelector } from "react-redux";
import TabBar from "../TabBar/TabBar";

const Banner = () => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:959px)");
  const isPhone = useMediaQuery("(max-width:599px)");
  const dispatch = useDispatch();
  //this needed to be done this way due to unnecessary bad UI design
  //searches if we are on playlist details page
  const isPlaylistDetailsPage = new RegExp(
    "/company/.+/playlists/.+",
    "gi"
  ).test(window.location.pathname);
  const company = useSelector((state) => state.data.company);
  const { user_guid } = useSelector((state) => state.auth.user);
  const isFollowing = useSelector(
    (state) => state.data.company.is_user_following
  );
  const { companyGuid } = useParams();

  React.useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      //check if company is in redux, if not get the data
      if (companyGuid !== company.company_guid) {
        dispatch(getCompany(companyGuid));
      }
    }
  }, [dispatch, companyGuid, user_guid]);

  return (
    <>
      {company.is_loading ? (
        <Loader
          size={40}
          height={
            // isPhone ? "18.9375rem" : isTablet ? "12.1875rem" : "22.4375rem"
            isPhone ? "18.375rem" : "20rem"
          }
        />
      ) : (
        <>
          {/* display tabbar on all pages expect playlist details page when on phone */}
          {(!isPlaylistDetailsPage || !isPhone) && (
            <div className={classes.banner}>
              <div className={classes.information}>
                <div className={classes.companyLogoWrapper}>
                  {company.company_logo !== "null" && (
                    <img
                      src={company.company_logo}
                      alt="logo"
                      className={classes.companyLogo}
                    />
                  )}
                </div>
                <div className={classes.informationWrapper}>
                  <div>
                    <Typography
                      variant="h2"
                      align="center"
                      className={classes.companyName}
                    >
                      {company.name}
                    </Typography>
                    {/*TODO: put this back when we decide to display this for users 
                    <Typography
                      variant="h4"
                      align={isPhone ? "left" : "center"}
                    >
                      <span className={classes.followersCount}>
                        {numberWithCommas(company.followers_count)}
                      </span>{" "}
                      <span className={classes.followersLabel}>Followers</span>
                    </Typography> */}
                  </div>

                  {isTablet && (
                    <FollowButton
                      className={classes.button}
                      guid={companyGuid}
                      onFollow={followCompany}
                      onUnfollow={unfollowCompany}
                      isFollowing={isFollowing}
                    />
                  )}
                </div>
              </div>
              <div className={classes.pictureWrapper}>
                {company.company_pic !== "null" && (
                  <img
                    src={company.company_pic}
                    alt="company banner"
                    className={classes.fundPic}
                  />
                )}
                {!isPhone && (
                  <img src={Wave} alt="wave" className={classes.wave} />
                )}
              </div>
            </div>
          )}
        </>
      )}
      <TabBar />
    </>
  );
};

export default Banner;
