import React from "react";
// import PlaylistSection from "../../components/PlaylistSection/PlaylistSection";TODO: uncomment when we decide to have code on line 22
import Loader from "shared_components/Loader/Loader";
import VideoSnippet from "shared_components/VideoSnippet/VideoSnippet";
import emptyVideosStateIcon from "assets/icons/icons/emptyVideosState.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import VideoCard from "shared_components/VideoCard/VideoCard";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Divider from "@material-ui/core/Divider"; TODO: uncomment when we decide to have code on line 22
//REDUX
import { useSelector, useDispatch } from "react-redux";
import { getMoreCompanyFundsVideos } from "redux/ducks/company";

const PlaylistsDashboard = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:599px)");
  const dispatch = useDispatch();
  //necessary when we decide to keep code on line 22
  // const companyPlaylists = useSelector((state) => state.data.company.playlists);
  // const fundsPlaylists = useSelector((state) => state.data.company.funds.data);
  const VIDEOS_SIZE = 12;
  const { companyGuid } = useParams();
  const data = useSelector((state) => state.data.company.funds_videos.data);
  const paging = useSelector((state) => state.data.company.funds_videos.paging);
  const is_loading = useSelector(
    (state) => state.data.company.funds_videos.is_loading
  );

  //events
  const fetchMoreVideos = () => {
    dispatch(
      getMoreCompanyFundsVideos(companyGuid, paging.page + 1, VIDEOS_SIZE)
    );
  };
  return (
    <>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <div className={classes.backGround}>
          {/*this can be reused in video_channel pages
          <PlaylistSection
            title="Playlists"
            playlists={companyPlaylists}
            type="company"
          />
          <Divider orientation="horizontal" className={classes.divider} />
          <PlaylistSection
            title="Fund Videos"
            playlists={fundsPlaylists}
            type="fund"
          /> */}
          {data !== null && data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreVideos}
              hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
              loader={<Loader />}
            >
              <Grid container spacing={3} className={classes.grid}>
                {data.map((video) => {
                  return (
                    <Grid
                      item
                      xs={isPhone ? 12 : 6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                      key={video.video_guid}
                    >
                      {isPhone ? (
                        <VideoCard snippet={video} />
                      ) : (
                        <VideoSnippet snippet={video} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          ) : (
            <section className={classes.emptyStateWrapper}>
              <div className={classes.itemsWrapper}>
                <img
                  src={emptyVideosStateIcon}
                  alt="icon"
                  className={classes.emptyStateIcon}
                />
                <Typography
                  className={classes.emptyStateTitle}
                  variant="h2"
                  align="center"
                >
                  There are no videos in the funds
                </Typography>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default PlaylistsDashboard;
