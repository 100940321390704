import * as api from "api/api";
import {
  constructAndSendError,
  splitEventsByTime,
} from "shared_functions/shared_functions";
// moocked data to test frontend
// import AuAgData from "../mocks/companies/AuAg/AuAg";
// import AuAgFunds from "../mocks/companies/AuAg/AuAgFunds";
// import PlaylistVideosPage0 from "../mocks/companies/AuAg/PlaylistVideosPage0";
// import PlaylistVideosPage1 from "../mocks/companies/AuAg/PlaylistVideosPage1";

//action types
const SET_COMPANY = "company/SET_COMPANY";
const SET_COMPANY_FUNDS = "company/SET_COMPANY_FUNDS";
const SET_MORE_COMPANY_FUNDS = "company/SET_MORE_COMPANY_FUNDS";
const SET_FUNDS_VIDEOS = "company/SET_FUNDS_VIDEOS";
const SET_MORE_FUNDS_VIDEOS = "company/SET_MORE_FUNDS_VIDEOS";
const START_LOADING_FUNDS_VIDEOS = "company/START_LOADING_FUNDS_VIDEOS";
const STOP_LOADING_FUNDS_VIDEOS = "company/STOP_LOADING_FUNDS_VIDEOS";
const SET_SELECTED_PLAYLIST = "company/SET_SELECTED_PLAYLIST";
const CLEAR_SELECTED_PLAYLIST = "company/CLEAR_SELECTED_PLAYLIST";
const SET_PLAYLIST_VIDEOS = "company/SET_PLAYLIST_VIDEOS";
const START_LOADING_COMPANY = "company/START_LOADING_COMPANY";
const STOP_LOADING_COMPANY = "company/STOP_LOADING_COMPANY";
const START_LOADING_FUNDS = "company/START_LOADING_FUNDS";
const STOP_LOADING_FUNDS = "company/STOP_LOADING_FUNDS";
const START_LOADING_VIDEOS = "company/START_LOADING_VIDEOS";
const STOP_LOADING_VIDEOS = "company/STOP_LOADING_VIDEOS";
const FOLLOW_COMPANY = "company/FOLLOW_COMPANY";
const UNFOLLOW_COMPANY = "company/UNFOLLOW_COMPANY";
const SET_INITIAL_STATE = "company/SET_INITIAL_STATE";

const initialState = {
  is_loading: true,
  company_guid: null,
  name: null,
  company_pic: null,
  company_logo: null,
  description: null,
  followers_count: null,
  is_user_following: false,
  funds: {
    is_loading: true,
    paging: null,
    data: [],
  },
  funds_videos: {
    is_loading: true,
    paging: null,
    data: [],
  },
  playlists: null,
  selected_playlist: {
    playlist_guid: null,
    name: null,
    description: null,
    playlist_pic: null,
    videos_count: null,
    likes_count: null,
    views_count: null,
    videos: {
      is_loading: true,
      paging: null,
      data: [],
    },
  },
  details: {
    address: null,
    external_website_link: null,
    joined: null,
  },
  upcoming_events: [],
  past_events: [],
};

export const getCompany = (company_guid) => async (dispatch) => {
  try {
    dispatch(startLoadingCompany());
    const { data } = await api.getCompany(company_guid);
    //mocked data
    // let data = AuAgData;
    //spliting events before storying to redux
    const [upcomingEvents, pastEvents] = splitEventsByTime(data.events);
    data.upcoming_events = upcomingEvents;
    data.past_events = pastEvents;
    dispatch({ type: SET_COMPANY, payload: data });
    dispatch(stopLoadingCompany());
    const PAGE = 0;
    const SIZE = 12;
    dispatch(getCompanyFunds(company_guid, PAGE, SIZE));
    dispatch(getCompanyFundsVideos(company_guid, PAGE, SIZE));
  } catch (error) {
    constructAndSendError(
      error.message,
      "company/getCompany",
      "/company/playlists",
      [`company_guid:${company_guid}`]
    );
    console.log(error);
  }
};

export const getCompanyFunds =
  (company_guid, page, size) => async (dispatch) => {
    try {
      dispatch(startLoadingFunds());
      const { data } = await api.getCompanyFunds(company_guid, page, size);
      //mocked data
      // let data = AuAgFunds;
      dispatch({ type: SET_COMPANY_FUNDS, payload: data });
      dispatch(stopLoadingFunds());
    } catch (error) {
      constructAndSendError(
        error.message,
        "company/getCompanyFunds",
        "/company/funds",
        [`company_guid:${company_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const getMoreCompanyFunds =
  (company_guid, page, size) => async (dispatch) => {
    try {
      const { data } = await api.getCompanyFunds(company_guid, page, size);
      dispatch({ type: SET_MORE_COMPANY_FUNDS, payload: data });
    } catch (error) {
      constructAndSendError(
        error.message,
        "company/getMoreCompanyFunds",
        "/company/funds",
        [`company_guid:${company_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const getCompanyFundsVideos =
  (company_guid, page, size) => async (dispatch) => {
    try {
      dispatch(startLoadingFundsVideos());
      const { data } = await api.getCompanyFundsVideos(
        company_guid,
        page,
        size
      );
      //mocked data
      // let data = AuAgFunds;
      dispatch({ type: SET_FUNDS_VIDEOS, payload: data });
      dispatch(stopLoadingFundsVideos());
    } catch (error) {
      constructAndSendError(
        error.message,
        "company/getCompanyFundsVideos",
        "/company/funds/videos",
        [`company_guid:${company_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const getMoreCompanyFundsVideos =
  (company_guid, page, size) => async (dispatch) => {
    try {
      const { data } = await api.getCompanyFundsVideos(
        company_guid,
        page,
        size
      );
      dispatch({ type: SET_MORE_FUNDS_VIDEOS, payload: data });
    } catch (error) {
      constructAndSendError(
        error.message,
        "company/getCompanyFundsVideos",
        "/company/funds/videos",
        [`company_guid:${company_guid}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const followCompany = (company_guid) => async (dispatch) => {
  try {
    await api.followCompany(company_guid);
    dispatch({ type: FOLLOW_COMPANY });
    //necessary to keep redux > followed_VIDEO_CHANNELs in sink
    // dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(
      error.message,
      "company/followCompany",
      "/company/follow",
      [`company_guid:${company_guid}`]
    );
    console.log(error);
  }
};

export const unfollowCompany = (company_guid) => async (dispatch) => {
  try {
    await api.unfollowCompany(company_guid);
    dispatch({ type: UNFOLLOW_COMPANY });
    //necessary to keep redux > followed_VIDEO_CHANNELs in sink
    // dispatch(getFollowedFunds());
  } catch (error) {
    constructAndSendError(
      error.message,
      "company/unfollowCompany",
      "/company/unfollow",
      [`company_guid:${company_guid}`]
    );
    console.log(error);
  }
};

export const setSelectedPlaylist = (playlist_guid) => (dispatch) => {
  dispatch({ type: SET_SELECTED_PLAYLIST, payload: playlist_guid });
  const PAGE = 0;
  const SIZE = 12;
  dispatch(getPlaylistVideos(playlist_guid, PAGE, SIZE));
};

export const getPlaylistVideos =
  (playlist_guid, page, size) => async (dispatch) => {
    const { data } = await api.getCompanyPlaylistVideos(
      playlist_guid,
      page,
      size
    );
    //mocked data
    // let data;
    // switch (page) {
    //   case 0: {
    //     data = PlaylistVideosPage0;
    //     break;
    //   }
    //   case 1: {
    //     data = PlaylistVideosPage1;
    //     break;
    //   }
    //   default:
    //     break;
    // }
    dispatch({ type: SET_PLAYLIST_VIDEOS, payload: data });
  };

export const clearSelectedPlaylist = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_PLAYLIST });
};

export const setInitialState = () => async (dispatch) => {
  dispatch({ type: SET_INITIAL_STATE });
};

export const startLoadingCompany = () => (dispatch) => {
  dispatch({ type: START_LOADING_COMPANY });
};

export const stopLoadingCompany = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_COMPANY });
};

export const startLoadingFunds = () => (dispatch) => {
  dispatch({ type: START_LOADING_FUNDS });
};

export const stopLoadingFunds = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_FUNDS });
};

export const startLoadingFundsVideos = () => (dispatch) => {
  dispatch({ type: START_LOADING_FUNDS_VIDEOS });
};

export const stopLoadingFundsVideos = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_FUNDS_VIDEOS });
};

export const startLoadingVideos = () => (dispatch) => {
  dispatch({ type: START_LOADING_VIDEOS });
};

export const stopLoadingVideos = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_VIDEOS });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_COMPANY_FUNDS: {
      //if the company has not funds, set to empty array
      if (action.payload.funds === null) action.payload.funds = [];
      return {
        ...state,
        funds: {
          paging: action.payload.paging,
          data: action.payload.funds,
          is_loading: state.funds.is_loading,
        },
      };
    }
    case SET_MORE_COMPANY_FUNDS: {
      return {
        ...state,
        funds: {
          paging: action.payload.paging,
          data: [...state.funds.data, ...action.payload.funds],
          is_loading: state.funds.is_loading,
        },
      };
    }
    case SET_FUNDS_VIDEOS: {
      //if the company has not funds, set to empty array
      if (action.payload.videos === null) action.payload.videos = [];
      return {
        ...state,
        funds_videos: {
          ...state.funds_videos,
          paging: action.payload.paging,
          data: action.payload.videos,
        },
      };
    }
    case SET_MORE_FUNDS_VIDEOS: {
      //if the company has not funds, set to empty array
      if (action.payload.videos === null) action.payload.videos = [];
      return {
        ...state,
        funds_videos: {
          ...state.funds_videos,
          paging: action.payload.paging,
          data: [...state.funds_videos.data, ...action.payload.videos],
        },
      };
    }
    case FOLLOW_COMPANY: {
      return {
        ...state,
        is_user_following: true,
        followers_count: state.followers_count + 1,
      };
    }
    case UNFOLLOW_COMPANY: {
      return {
        ...state,
        is_user_following: false,
        followers_count: state.followers_count - 1,
      };
    }
    case SET_SELECTED_PLAYLIST: {
      const selectedPlaylist = state.playlists.filter(
        (playlist) => playlist.playlist_guid === action.payload
      );
      return {
        ...state,
        selected_playlist: {
          ...state.selected_playlist,
          ...selectedPlaylist[0],
        },
      };
    }
    case SET_PLAYLIST_VIDEOS: {
      return {
        ...state,
        selected_playlist: {
          ...state.selected_playlist,
          videos: {
            paging: action.payload.paging,
            data: [
              ...state.selected_playlist.videos.data,
              ...action.payload.videos,
            ],
            is_loading: state.selected_playlist.videos.is_loading,
          },
        },
      };
    }
    case CLEAR_SELECTED_PLAYLIST: {
      return {
        ...state,
        selected_playlist: { ...initialState.selected_playlist },
      };
    }
    case SET_INITIAL_STATE: {
      return initialState;
    }
    case START_LOADING_COMPANY: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING_COMPANY: {
      return { ...state, is_loading: false };
    }
    case START_LOADING_FUNDS: {
      return { ...state, funds: { ...state.funds, is_loading: true } };
    }
    case STOP_LOADING_FUNDS: {
      return { ...state, funds: { ...state.funds, is_loading: false } };
    }
    case START_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: true } };
    }
    case STOP_LOADING_VIDEOS: {
      return { ...state, videos: { ...state.videos, is_loading: false } };
    }
    case START_LOADING_FUNDS_VIDEOS: {
      return {
        ...state,
        funds_videos: {
          ...state.funds_videos,
          is_loading: true,
        },
      };
    }
    case STOP_LOADING_FUNDS_VIDEOS: {
      return {
        ...state,
        funds_videos: {
          ...state.funds_videos,
          is_loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
