import React from "react";
import Video from "../Video/Video";
import VideoDetails from "../VideoDetails/VideoDetails";
import HorizontalLine from "assets/decoration/horizontal_line_video.svg";
import RelatedFunds from "../RelatedFunds/RelatedFunds";
import CommentSection from "../CommentSection/CommentSection";
import Loader from "shared_components/Loader/Loader";
import { useParams } from "react-router-dom";

//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
//REDUX
import { setInitialState, getVideo } from "redux/ducks/video";
import { useDispatch, useSelector } from "react-redux";

const MainContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const video = useSelector((state) => state.data.video);
  const user_guid = useSelector((state) => state.auth.user.user_guid);
  const { guid: video_guid } = useParams();

  //used to clear up the old video state from the redux to prevent errors from not displaying the video
  video.snippet.video_guid !== "" &&
    video.snippet.video_guid !== video_guid &&
    dispatch(setInitialState());

  React.useEffect(() => {
    //makes sure we wait for guid before we make requests
    if (user_guid !== null) {
      dispatch(getVideo(video_guid));
    }
  }, [dispatch, video_guid, user_guid]);

  return (
    <main className={classes.main}>
      <Video />
      {video.is_loading ? (
        <>
          <Loader size={40} />
          <div style={{ height: "460px" }}></div>
        </>
      ) : (
        <Grid
          container
          className={classes.gridWrapper}
          spacing={3}
          justifyContent="center"
        >
          <VideoDetails />
          <RelatedFunds />
        </Grid>
      )}

      <img
        src={HorizontalLine}
        alt="horizontal line"
        className={classes.line}
      />
      <Grid
        container
        className={classes.gridWrapper}
        spacing={3}
        justifyContent="center"
      >
        <CommentSection video_guid={video_guid} />
      </Grid>
    </main>
  );
};

export default MainContent;
