import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { getCountryNameByCode } from "shared_functions/shared_functions";
import countryList from "assets/data/countryList";
import { ONBOARDING, ONBOARDING_SUCCESS } from "navigation/constants";
import { investorTypes } from "auth/investorTypes";
//MUI
import useStyles from "./styles";
// Components
import ProgressBar from "./ProgressBar/ProgressBar";
import WizardBar from "./WizardBar/WizardBar";
import Stepper from "./ProgressBar/Stepper/Stepper.jsx";
import HorizontalStepper from "./HorizontalStepper/HorizontalStepper";
//Pages
import InvestorExperiencePage from "./Pages/InvestorExperiencePage";
import CountryPage from "./Pages/CountryPage";
//REDUX
import {
  setNewInvestorType,
  setNewInvestorCountry,
  updateUserPreferences,
} from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const steps = [
  {
    sectionLabel: "1. Create Account",
    substeps: [
      {
        label: "Investor Experience",
      },
      {
        label: "Country",
      },
    ],
  },
  // Can add more sections down here
];

const Onboarding = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPhone = useMediaQuery("(max-width:599px)");
  const defaultCountry = useSelector((state) => state.auth.user.country);
  const last_page_url = useSelector((state) => state.auth.user.last_page_url);
  const [activeStep, setActiveStep] = useState(0);
  const [activeSection, setActiveSection] = useState(0);
  const [lastStep, setLastStep] = useState(false);
  const [investorType, setInvestorType] = useState(
    investorTypes.RETAIL_INVESTOR
  );
  const country = {
    name: getCountryNameByCode(countryList, defaultCountry),
    code: defaultCountry,
  };

  // Keep track of section steps and sub steps
  useEffect(() => {
    if (
      activeStep > steps[activeSection].substeps.length - 1 &&
      activeSection >= steps.length - 1
    ) {
      //path when onboarding is finished
      const params = {
        country: country.code,
        investor_type: investorType,
      };
      dispatch(setNewInvestorType(investorType));
      dispatch(setNewInvestorCountry(country.code));
      dispatch(updateUserPreferences(params));
      history.push({
        pathname: ONBOARDING_SUCCESS,
        state: {
          prevPage: ONBOARDING,
        },
      });
      return;
    }
    if (activeStep > steps[activeSection].substeps.length - 1) {
      setActiveSection(activeSection + 1);
      setActiveStep(0);
    } else if (activeStep < 0) {
      // activeSection can't go below zero
      if (activeSection > 0) {
        setActiveSection(activeSection - 1);
      }
      setActiveStep(0);
    }
    //only activeStep is needed here
  }, [
    activeStep,
    country.code,
    dispatch,
    activeSection,
    history,
    investorType,
    last_page_url,
  ]);

  // Check if it is the last step - used to render the wizard bar
  useEffect(() => {
    if (
      activeStep > steps[activeSection].substeps.length - 2 &&
      activeSection >= steps.length - 1
    ) {
      setLastStep(true);
    } else {
      setLastStep(false);
    }
    //only activeStep and lastStep is necessary here
  }, [activeStep, lastStep, activeSection]);

  const handleBackClick = () => {
    setActiveStep(activeStep - 1);
  };
  const handleNextClick = () => {
    setActiveStep(activeStep + 1);
  };
  const handleCountryChange = (country) => {
    if (country === null) return;
    else dispatch(setNewInvestorCountry(country.code));
  };

  // add new pages here
  const renderPage = () => {
    switch (true) {
      case activeStep === 0 && activeSection === 0:
        return (
          <InvestorExperiencePage
            investorType={investorType}
            setInvestorType={setInvestorType}
          />
        );
      case activeStep === 1 && activeSection === 0:
        return (
          <CountryPage
            country={country}
            handleCountryChange={handleCountryChange}
          />
        );
      default:
        <InvestorExperiencePage />;
    }
  };

  return (
    <div>
      {isPhone ? (
        <HorizontalStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ) : (
        <ProgressBar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          steps={steps}
        >
          <Stepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            steps={steps}
          />
        </ProgressBar>
      )}

      <WizardBar
        handleNextClick={handleNextClick}
        handleBackClick={handleBackClick}
        lastStep={lastStep}
        activeStep={activeStep}
        activeSection={activeSection}
      />
      <div className={classes.pageContent}>{renderPage()}</div>
    </div>
  );
};

export default Onboarding;
