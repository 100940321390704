import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  badge: {
    height: 88,
    width: 88,
    marginBottom: theme.custom.pxToRem(24),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  header: {
    marginBottom: theme.custom.pxToRem(8),
  },
  text: {
    marginBottom: theme.custom.pxToRem(16),
  },
  informationGrid: {
    marginBottom: theme.custom.pxToRem(88),
  },
  form: {
    marginBottom: theme.custom.pxToRem(16),
  },
  disclaimer: {
    marginTop: 16,
    fontFamily: "ProximaNovaSemibold",
    fontWeight: 600,
  },
}));
