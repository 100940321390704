import React, { useEffect } from "react";
import Loader from "shared_components/Loader/Loader";
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
// components
import NotificationListItem from "pages/UserSettings/subpages/Notifications/ListItem/ItemList";
// Graphical elements
import notificationBagde from "assets/icons/process/notification-bagde.svg";
import playCircleIcon from "assets/icons/icons/playCircle.svg";
import writeNoteIcon from "assets/icons/icons/writeNote.svg";
import emailIcon from "assets/icons/icons/email.svg";
//REDUX
import { setNotificationsSettings } from "redux/ducks/user";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    user_guid,
    settings: { notifications_preferences, notifications_delivery, is_loading },
  } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user_guid !== null) {
      dispatch(setNotificationsSettings());
    }
  }, [dispatch, user_guid]);

  return (
    <>
      <div className={classes.headerBagde}>
        <img
          className={classes.badge}
          src={notificationBagde}
          alt="notification"
        />
        <Typography variant="h2" className={classes.bagdeText}>
          Notifications
        </Typography>
      </div>
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <>
          <div className={classes.listItemContainer}>
            <Typography variant="h3" className={classes.listItemsTitle}>
              Preferences
            </Typography>
            <Grid container>
              {notifications_preferences.length > 0
                ? notifications_preferences.map((item, index) => (
                    <Grid item xs={12} md={8} lg={8} key={index}>
                      <NotificationListItem
                        item={item}
                        icon={
                          index === 0
                            ? playCircleIcon
                            : index === 1
                            ? writeNoteIcon
                            : playCircleIcon
                        }
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
          </div>

          <div className={classes.listItemContainer}>
            <Typography variant="h3" className={classes.listItemsTitle}>
              Delivery
            </Typography>
            <Grid container>
              {notifications_delivery.length > 0
                ? notifications_delivery.map((item, index) => (
                    <Grid item xs={12} md={8} lg={8} key={index}>
                      <NotificationListItem
                        item={item}
                        icon={index === 0 ? emailIcon : writeNoteIcon}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default Notifications;
