import React from "react";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { getLocalDDMMMhhmmFromUTC } from "shared_functions/shared_functions";

const EventCard = ({ event, isDisabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {isDisabled && <div className={classes.disabledOverlay}></div>}
      <img src={event.event_pic} alt="thumbnail" className={classes.picture} />
      <div className={classes.timeWrapper}>
        <Typography className={classes.time}>
          {getLocalDDMMMhhmmFromUTC(event.event_time)}
        </Typography>
        <Typography variant="h4" component="div">
          <CustomEllipsis lines={2} text={event.title} />
        </Typography>
      </div>
      <Divider />
      <div className={classes.buttonWrapper}>
        <a
          href={event.event_link}
          className={classes.link}
          target="_blank"
          rel="noreferrer"
        >
          <Button className={classes.button}>View Event</Button>
        </a>
      </div>
    </div>
  );
};

export default EventCard;
