import React from "react";
import moment from "moment";
import ThumbUp from "assets/icons/icons/thumbUp.svg";
import { ReactComponent as ThumbUpActive } from "assets/icons/icons/thumbUpActive.svg";
import ThumbUpDisabled from "assets/icons/icons/thumbUpDisabled.svg";
import WatchLater from "assets/icons/icons/watchLater.svg";
import { ReactComponent as WatchLaterActive } from "assets/icons/icons/watch_later_active.svg";
import WatchLaterDisabled from "assets/icons/icons/watch_later_disabled.svg";
import LinkIcon from "assets/icons/icons/link_blue.svg";
import { useParams } from "react-router-dom";
import Link from "shared_components/Link/Link";
import { WATCH_LATER } from "navigation/constants";
import ClampLines from "react-clamp-lines";

//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LoginTooltip from "shared_components/LoginTooltip/LoginTooltip";
import Alert from "shared_components/Alerts/Alert/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import {
  likeVideo,
  unlikeVideo,
  addToWatchLater,
  removeFromWatchLater,
} from "redux/ducks/video";
import { useDispatch, useSelector } from "react-redux";

const VideoDetails = () => {
  const classes = useStyles();
  const { guid: video_guid } = useParams();
  const isPhone = useMediaQuery("(max-width:599px)");
  const dispatch = useDispatch();
  const video = useSelector((state) => state.data.video);
  const { is_anonymous } = useSelector((state) => state.auth.user);
  //used for copying url and displaying it correctly with popup
  const urlCopyRef = React.useRef(null);
  const [openCopySuccess, setOpenCopySuccess] = React.useState(false);
  const [openAddWatchLaterSuccess, setOpenAddWatchLaterSuccess] =
    React.useState(false);

  //event handlres
  const handleLike = () => {
    dispatch(likeVideo(video_guid));
  };

  const handleUnlike = () => {
    dispatch(unlikeVideo(video_guid));
  };

  const handleAddToWatchLater = () => {
    dispatch(addToWatchLater(video_guid));
    setOpenAddWatchLaterSuccess(true);
  };

  const handleRemoveFromWatchLater = () => {
    dispatch(removeFromWatchLater(video_guid));
  };
  //copying to clipboard
  const copyToClipboard = () => {
    //necessary bcs none displayed text cant be copied
    urlCopyRef.current.style.display = "inline";
    urlCopyRef.current.select();
    document.execCommand("copy");
    urlCopyRef.current.style.display = "none";
    setOpenCopySuccess(true);
  };

  const handleCloseCopySuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenCopySuccess(false);
  };

  const handleCloseAddWatchLaterSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAddWatchLaterSuccess(false);
  };
  return (
    <>
      <Grid xs={12} lg={8} item>
        <Typography variant={isPhone ? "h3" : "h2"}>
          {video.snippet.title}
        </Typography>
      </Grid>
      <Grid xs={12} lg={8} item>
        <div className={classes.labelWrapper}>
          <Typography
            variant={isPhone ? "body2" : "h3"}
            className={classes.infoLabel}
          >
            {video.snippet.views_count} views &#183;{" "}
            {moment(video.snippet.time_stamp).format("D MMM YYYY")}
          </Typography>
          <div className={classes.actionButtonsWrapper}>
            <span className={classes.actionButton}>
              {is_anonymous ? (
                <LoginTooltip
                  title="Like this video?"
                  description="Login or sign-up to like the video"
                  placement="top"
                >
                  <div>
                    <IconButton
                      disabled
                      classes={{ root: classes.icon }}
                      data-cy="likeDisabled"
                    >
                      <img src={ThumbUpDisabled} alt="thumb up" />
                    </IconButton>
                  </div>
                </LoginTooltip>
              ) : video.details.is_liked ? (
                <IconButton
                  onClick={handleUnlike}
                  classes={{ root: classes.icon }}
                >
                  <ThumbUpActive />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleLike}
                  classes={{ root: classes.icon }}
                >
                  <img src={ThumbUp} alt="thumb up" />
                </IconButton>
              )}

              <Typography variant={isPhone ? "body2" : "body1"}>
                {video.details.likes_count}
              </Typography>
            </span>

            <span className={classes.actionButton}>
              {is_anonymous ? (
                <LoginTooltip
                  title="Want to watch the video later?"
                  description="Login or sign-up to save the video"
                  placement="top"
                >
                  <div>
                    <IconButton
                      disabled
                      classes={{ root: classes.icon }}
                      data-cy="watchLaterDisabled"
                    >
                      <img src={WatchLaterDisabled} alt="watch later" />
                    </IconButton>
                  </div>
                </LoginTooltip>
              ) : video.details.is_in_watch_later_list ? (
                <IconButton
                  onClick={handleRemoveFromWatchLater}
                  classes={{ root: classes.icon }}
                >
                  <WatchLaterActive />
                </IconButton>
              ) : (
                <IconButton
                  onClick={handleAddToWatchLater}
                  classes={{ root: classes.icon }}
                >
                  <img src={WatchLater} alt="watch later" />
                </IconButton>
              )}
              <Alert
                open={openAddWatchLaterSuccess}
                handleClose={handleCloseAddWatchLaterSuccess}
                severity="success"
                variant="filled"
                autoHideDuration={100000000}
              >
                <Typography variant="body1" className={classes.alertText}>
                  Video has been added to your{" "}
                  <Link to={WATCH_LATER} className={classes.alertLink}>
                    watch later list
                  </Link>
                </Typography>
              </Alert>
              <Typography variant={isPhone ? "body2" : "body1"}>
                Watch Later
              </Typography>
            </span>
            <span className={classes.actionButton}>
              <IconButton
                onClick={copyToClipboard}
                classes={{ root: classes.icon }}
              >
                <img src={LinkIcon} alt="Link" />
              </IconButton>
              <textarea
                style={{ display: "none" }}
                ref={urlCopyRef}
                value={window.location.href}
                readOnly
              ></textarea>
              <Alert
                open={openCopySuccess}
                handleClose={handleCloseCopySuccess}
                text="Link has been copied"
                severity="success"
                variant="filled"
              />
              <Typography variant={isPhone ? "body2" : "body1"}>
                Copy Link
              </Typography>
            </span>
          </div>
        </div>
      </Grid>
      <Grid xs={12} lg={8} item>
        <Typography
          variant="body1"
          className={classes.videoDescription}
          component="div"
        >
          <ClampLines
            text={
              video.details.description !== null
                ? video.details.description
                : ""
            }
            lines={5}
            ellipsis={"..."}
            buttons={true}
            moreText="SHOW&#160;MORE"
            lessText="SHOW&#160;LESS"
          />
        </Typography>
      </Grid>
    </>
  );
};

export default VideoDetails;
