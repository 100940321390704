import React, { useState } from "react";
import SortIcon from "assets/icons/icons/sort.svg";
import MenuPopOver from "shared_components/MenuPopOver/MenuPopOver";
import clsx from "clsx";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

export default function Sortby({ sort, setSort, PopOverItems, className }) {
  const classes = useStyles();

  //pop-over menu handlers
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx(classes.sort, className)}>
      <IconButton onClick={handleClick} classes={{ root: classes.icon }}>
        <img src={SortIcon} alt="Sort" />
      </IconButton>
      <Typography variant="body2">
        SORT BY{" "}
        <span className={classes.filter} onClick={handleClick}>
          {sort}
        </span>
      </Typography>
      <MenuPopOver
        anchor={anchorEl}
        handleClose={handleClose}
        listItems={PopOverItems}
        setSort={setSort}
      />
    </div>
  );
}
