import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  page: {
    paddingTop: `${theme.custom.pxToRem(80)} !important`,
    paddingBottom: `${theme.custom.pxToRem(80)} !important`,
    backgroundColor: palette.grey_tone[100],
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(48)} !important`,
    },
  },
  pageContent: {
    marginTop: 88,
    textAlign: "center",
  },
  icon: {
    marginBottom: 32,
  },
  header: {
    marginBottom: 16,
  },
  bodyText: {
    marginBottom: 40,
  },
  link: {
    textDecoration: "none",
  },
}));
