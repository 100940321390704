import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  videosWrapper: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "auto",
      padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
        165
      )} !important`,
      minHeight: `calc(100vh - ${theme.custom.pxToRem(233)})`,
    },
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
        48
      )} !important`,
      minHeight: `calc(100vh - ${theme.custom.pxToRem(185)})`,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
        16
      )} !important`,
      minHeight: `calc(100vh - ${theme.custom.pxToRem(121)})`,
    },
    backgroundColor: `${theme.palette.background.secondary}`,
    padding: `${theme.custom.pxToRem(31)} ${theme.custom.pxToRem(
      24
    )} 0 ${theme.custom.pxToRem(48)}!important`,
    borderRight: `1px solid ${theme.palette.background.border}`,
  },
}));
