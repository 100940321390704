import React from "react";
import CustomEllipsis from "shared_components/CustomEllipsis/CustomEllipsis";
import moment from "moment";
import Link from "shared_components/Link/Link";
import { ReactComponent as ImageIcon } from "assets/icons/icons/image.svg";
import { VIDEO, FUND, ABOUT } from "navigation/constants";
//MUI
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

//notifications types
const NEWSLETTER_UPLOADED = "Followed funds newsletters";
const VIDEO_UPLOADED = "Followed funds video";

export default function NotificationListItem({
  title,
  bodyText,
  fundImg,
  wasRead,
  timestamp,
  type,
  eventId,
  handleClose,
}) {
  const classes = useStyles({ wasRead });
  let linkUrl = "";
  switch (type) {
    case NEWSLETTER_UPLOADED: {
      linkUrl = `${FUND}/${eventId}${ABOUT}#newsletters`;
      break;
    }
    case VIDEO_UPLOADED: {
      linkUrl = `${VIDEO}/${eventId}`;
      break;
    }
    default: {
    }
  }

  return (
    <Link to={linkUrl} className={classes.link} onClick={handleClose}>
      <ListItem className={classes.listItem}>
        {fundImg !== null ? (
          <Avatar alt="fund image" variant="rounded" src={fundImg} />
        ) : (
          <div className={classes.noFundImgWrapper}>
            <ImageIcon />
          </div>
        )}

        <div className={classes.itemsWrapper}>
          <ListItemText
            disableTypography
            className={classes.text}
            primary={
              <>
                <Typography variant="h4" color="textPrimary" component="div">
                  <CustomEllipsis lines={1} text={title} component="div" />
                </Typography>
              </>
            }
            secondary={
              <>
                <Typography variant="body2" color="textPrimary" component="div">
                  <CustomEllipsis lines={2} text={bodyText} component="div" />
                </Typography>
              </>
            }
          />
          <ListItemText
            primary={
              <>
                <Typography
                  variant="h5"
                  className={classes.timestamp}
                  component="div"
                >
                  {moment(timestamp).fromNow()}
                </Typography>
              </>
            }
          />
        </div>
      </ListItem>
      <Divider variant="fullWidth" />
    </Link>
  );
}
