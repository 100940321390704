import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  headerBagde: {
    display: "flex",
    flexDirection: "row",
    marginBottom: `${theme.custom.pxToRem(48)}`,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  badge: {
    filter: `drop-shadow(1px 2px 25px rgba(102, 126, 234, 0.2))`,
  },
  bagdeText: {
    paddingLeft: 16,
  },
  listItemContainer: {
    marginBottom: 40,
  },
  listItemsTitle: {
    marginBottom: `${theme.custom.pxToRem(4)} !important`,
  },
}));
