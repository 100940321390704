import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_VIDEO = "video/SET_VIDEO";
const SET_COMMENTS = "video/SET_COMMENTS";
const SUBMIT_COMMENT = "video/SUBMIT_COMMENT";
const DELETE_COMMENT = "video/DELETE_COMMENT";
const EDIT_COMMENT = "video/EDIT_COMMENT";
const LIKE_COMMENT = "video/LIKE_COMMENT";
const UNLIKE_COMMENT = "video/UNLIKE_COMMENT";
const START_LOADING_VIDEO = "video/START_LOADING_VIDEO";
const STOP_LOADING_VIDEO = "video/STOP_LOADING_VIDEO";
const START_LOADING_COMMENTS = "video/START_LOADING_COMMENTS";
const STOP_LOADING_COMMENTS = "video/STOP_LOADING_COMMENTS";
const LIKE_VIDEO = "video/LIKE_VIDEO";
const UNLIKE_VIDEO = "video/UNLIKE_VIDEO";
const ADD_TO_WATCHLATER = "video/ADD_TO_WATCHLATER";
const REMOVE_FROM_WATCHLATER = "video/REMOVE_FROM_WATCHLATER";
const SET_INITIAL_STATE = "video/SET_INITIAL_STATE";

const initialState = {
  is_loading: true,
  snippet: {
    time_stamp: "",
    video_guid: "",
    title: "",
    primary_fund_name: "",
    primary_fund_id: "",
    duration: 0,
    views_count: 0,
    thumbnail_pic: "",
  },
  details: {
    tags: [],
    is_liked: false,
    is_in_watch_later_list: false,
    description: "",
    likes_count: 0,
    url: "",
  },
  comments: {
    is_loading: true,
    paging: {},
    data: [],
  },
};

export const getVideo = (video_guid) => async (dispatch) => {
  try {
    dispatch(startLoadingVideo());
    const { data } = await api.getVideo(video_guid);
    dispatch({ type: SET_VIDEO, payload: data });
    dispatch(stopLoadingVideo());
  } catch (error) {
    constructAndSendError(error.message, "video/getVideo", "/video", [
      `video_guid:${video_guid}`,
    ]);
    console.log(error);
  }
};

export const getComments = (video_guid, page) => async (dispatch) => {
  try {
    dispatch(startLoadingComments());
    const { data } = await api.getVideoComments(video_guid, page);
    //needed to make check in component easier
    if (data.comments === null) data.comments = [];
    dispatch({ type: SET_COMMENTS, payload: data });
    dispatch(stopLoadingComments());
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/getComments",
      "/video/comments",
      [`video_guid:${video_guid}`, `page:${page}`]
    );
    console.log(error);
  }
};

export const submitComment =
  (video_guid, commentBody, first_name, last_name, picture_url) =>
  async (dispatch) => {
    try {
      const { data } = await api.submitComment(video_guid, commentBody);
      data.body = commentBody;
      data.first_name = first_name;
      data.last_name = last_name;
      data.creator_pic_url = picture_url;
      dispatch({ type: SUBMIT_COMMENT, payload: data });
    } catch (error) {
      constructAndSendError(
        error.message,
        "video/submitComment",
        "/video/comment",
        [
          `video_guid:${video_guid}`,
          `first_name:${first_name}`,
          `last_name:${last_name}`,
          `picture_url:${picture_url}`,
        ],
        JSON.stringify(commentBody)
      );
      console.log(error);
    }
  };

export const deleteComment = (comment_id) => async (dispatch) => {
  try {
    await api.deleteComment(comment_id);
    dispatch({ type: DELETE_COMMENT, payload: comment_id });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/deleteComment",
      "/video/comment",
      [`comment_id:${comment_id}`]
    );
    console.log(error);
  }
};

export const editComment = (comment_id, comment_body) => async (dispatch) => {
  try {
    await api.editComment(comment_id, comment_body);
    dispatch({ type: EDIT_COMMENT, payload: { comment_id, comment_body } });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/editComment",
      "/video/comment",
      [`comment_id:${comment_id}`],
      JSON.stringify(comment_body)
    );
    console.log(error);
  }
};

export const likeComment = (comment_id) => async (dispatch) => {
  try {
    await api.likeComment(comment_id);
    dispatch({ type: LIKE_COMMENT, payload: comment_id });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/likeComment",
      "/video/comment/like",
      [`comment_id:${comment_id}`]
    );
    console.log(error);
  }
};

export const unlikeComment = (comment_id) => async (dispatch) => {
  try {
    await api.unlikeComment(comment_id);
    dispatch({ type: UNLIKE_COMMENT, payload: comment_id });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/unlikeComment",
      "/video/comment/unlike",
      [`comment_id:${comment_id}`]
    );
    console.log(error);
  }
};

export const addToWatchLater = (video_guid) => async (dispatch) => {
  try {
    await api.addToWatchLater(video_guid);
    dispatch({ type: ADD_TO_WATCHLATER });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/addToWatchLater",
      "/video/watchlater/add",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const removeFromWatchLater = (video_guid) => async (dispatch) => {
  try {
    await api.removeFromWatchLater(video_guid);
    dispatch({ type: REMOVE_FROM_WATCHLATER });
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/removeFromWatchLater",
      "/video/watchlater/remove",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const likeVideo = (video_guid) => async (dispatch) => {
  try {
    await api.likeVideo(video_guid);
    dispatch({ type: LIKE_VIDEO });
  } catch (error) {
    constructAndSendError(error.message, "video/likeVideo", "/video/like", [
      `video_guid:${video_guid}`,
    ]);
    console.log(error);
  }
};

export const unlikeVideo = (video_guid) => async (dispatch) => {
  try {
    await api.unlikeVideo(video_guid);
    dispatch({ type: UNLIKE_VIDEO });
  } catch (error) {
    constructAndSendError(error.message, "video/unlikeVideo", "/video/unlike", [
      `video_guid:${video_guid}`,
    ]);
    console.log(error);
  }
};

export const markVideoViewed = (video_guid) => async (dispatch) => {
  try {
    await api.markVideoViewed(video_guid);
  } catch (error) {
    constructAndSendError(
      error.message,
      "video/markVideoViewed",
      "/video/watched",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const setInitialState = () => (dispatch) => {
  dispatch({ type: SET_INITIAL_STATE });
};

export const startLoadingVideo = () => (dispatch) => {
  dispatch({ type: START_LOADING_VIDEO });
};

export const stopLoadingVideo = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_VIDEO });
};

export const startLoadingComments = () => (dispatch) => {
  dispatch({ type: START_LOADING_COMMENTS });
};

export const stopLoadingComments = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_COMMENTS });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO: {
      return {
        ...state,
        snippet: action.payload.snippet,
        details: action.payload.details,
      };
    }
    case SET_COMMENTS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          data: action.payload.comments,
          paging: action.payload.paging,
        },
      };
    }
    case SUBMIT_COMMENT: {
      const { id, time_stamp, body, creator_pic_url, first_name, last_name } =
        action.payload;
      const newComment = {
        id,
        time_stamp,
        creator_pic_url,
        creator_name: `${first_name} ${last_name}`,
        body,
        is_owned_by_current_user: true,
        is_liked_by_current_user: false,
        like_count: 0,
      };
      const newCommetsData = [newComment, ...state.comments.data];
      return {
        ...state,
        comments: {
          ...state.comments,
          data: newCommetsData,
        },
      };
    }
    case DELETE_COMMENT: {
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (comment) => comment.id !== action.payload
          ),
        },
      };
    }
    case EDIT_COMMENT: {
      const { comment_id, comment_body } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((comment) => {
            if (comment.id === comment_id)
              return {
                ...comment,
                body: comment_body,
              };
            else return comment;
          }),
        },
      };
    }
    case LIKE_COMMENT: {
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((comment) => {
            if (comment.id === action.payload)
              return {
                ...comment,
                is_liked_by_user: true,
                like_count: comment.like_count + 1,
              };
            else return comment;
          }),
        },
      };
    }
    case UNLIKE_COMMENT: {
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((comment) => {
            if (comment.id === action.payload)
              return {
                ...comment,
                is_liked_by_user: false,
                like_count: comment.like_count - 1,
              };
            else return comment;
          }),
        },
      };
    }
    case LIKE_VIDEO: {
      return {
        ...state,
        details: {
          ...state.details,
          is_liked: true,
          likes_count: state.details.likes_count + 1,
        },
      };
    }
    case UNLIKE_VIDEO: {
      return {
        ...state,
        details: {
          ...state.details,
          is_liked: false,
          likes_count: state.details.likes_count - 1,
        },
      };
    }
    case ADD_TO_WATCHLATER: {
      return {
        ...state,
        details: { ...state.details, is_in_watch_later_list: true },
      };
    }
    case REMOVE_FROM_WATCHLATER: {
      return {
        ...state,
        details: { ...state.details, is_in_watch_later_list: false },
      };
    }
    case SET_INITIAL_STATE: {
      return initialState;
    }
    case START_LOADING_VIDEO: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING_VIDEO: {
      return { ...state, is_loading: false };
    }
    case START_LOADING_COMMENTS: {
      return { ...state, comments: { ...state.comments, is_loading: true } };
    }
    case STOP_LOADING_COMMENTS: {
      return { ...state, comments: { ...state.comments, is_loading: false } };
    }
    default:
      return state;
  }
};

export default reducer;
