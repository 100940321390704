import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  sidebar: {
    width: `${theme.custom.pxToRem(250)} `,
    backgroundColor: `${palette.grey_tone[0]}`,
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    borderRight: `1px solid ${palette.grey_tone[200]}`,
    zIndex: 200,
    [theme.breakpoints.down("sm")]: {
      width: `${theme.custom.pxToRem(200)} `,
    },
  },
  container: {
    marginTop: `${theme.custom.pxToRem(48)} `,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.custom.pxToRem(32)} `,
    },
  },
  logo: {
    marginLeft: `${theme.custom.pxToRem(48)}`,
    marginBottom: `${theme.custom.pxToRem(64)}`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: `${theme.custom.pxToRem(32)}`,
      marginBottom: `${theme.custom.pxToRem(48)}`,
    },
  },
  subheader: {
    marginLeft: `${theme.custom.pxToRem(48)}`,
    color: `${palette.primary}`,
    marginBottom: `${theme.custom.pxToRem(4)}`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: `${theme.custom.pxToRem(32)}`,
    },
  },
  name: {
    marginLeft: `${theme.custom.pxToRem(48)} `,
    marginBottom: `${theme.custom.pxToRem(8)} `,
    [theme.breakpoints.down("sm")]: {
      marginLeft: `${theme.custom.pxToRem(32)}`,
    },
  },
}));
