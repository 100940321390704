import React from "react";
import moment from "moment";
import ThumbUp from "assets/icons/icons/thumbUp.svg";
import { ReactComponent as ThumbUpActive } from "assets/icons/icons/thumbUpActive.svg";
import Vimeo from "shared_components/Vimeo/Vimeo";
import { VIDEO } from "navigation/constants";
import Link from "shared_components/Link/Link";
import ClampLines from "react-clamp-lines";
//MUI
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
//REDUX
import { likeVideo, unlikeVideo } from "redux/ducks/watch_later";
import { useDispatch } from "react-redux";

const VideoSection = ({ activeVideo }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //events
  const handleLike = () => {
    dispatch(likeVideo(activeVideo.snippet.video_guid));
  };

  const handleUnlike = () => {
    dispatch(unlikeVideo(activeVideo.snippet.video_guid));
  };
  return (
    <div className={classes.videoPlayerWrapper}>
      <div className={classes.videoWrapper}>
        <Vimeo url={activeVideo.details.url} className={classes.video} />
        {/* <Vimeo
                      autopause //will stop all other videos on the page
                      video={activeVideo.details.url}
                      className={classes.video}
                      onError={handleError}
                      speed //Enable playback rate controls
                      showPortrait //Show the portrait on the video.
                    /> */}
      </div>
      <Link
        to={`${VIDEO}/${activeVideo.snippet.video_guid}`}
        className={classes.videoLink}
      >
        <Typography variant="h3" className={classes.videoTitle}>
          {activeVideo.snippet.title}
        </Typography>
      </Link>
      <div className={classes.infoWrapper}>
        <span className={classes.actionButton}>
          {activeVideo.details.is_liked ? (
            <IconButton onClick={handleUnlike}>
              <ThumbUpActive />
            </IconButton>
          ) : (
            <IconButton onClick={handleLike}>
              <img src={ThumbUp} alt="thumb up" />
            </IconButton>
          )}
          <Typography variant="body1">
            {activeVideo.details.likes_count}
          </Typography>
        </span>

        <Typography variant="h3" className={classes.infoLabel}>
          {activeVideo.snippet.views_count} views &#183;{" "}
          {moment(activeVideo.snippet.time_stamp).format("D MMM YYYY")}
        </Typography>
      </div>
      <Typography
        variant="body1"
        component="div"
        className={classes.videoDescription}
      >
        <ClampLines
          text={
            activeVideo.details.description !== null
              ? activeVideo.details.description
              : ""
          }
          lines={5}
          ellipsis={"..."}
          buttons={true}
          moreText="SHOW&#160;MORE"
          lessText="SHOW&#160;LESS"
        />
      </Typography>
    </div>
  );
};

export default VideoSection;
