import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import * as ROUTES from "./constants";
import * as PAGES from "pages";
import ScrollToTop from "./scrollTop";
import Auth from "auth/Auth";
import ProtectedRoute from "./ProtectedRoute";
import LoginRoute from "./LoginRoute";
import LoaderRoute from "./LoaderRoute";
import OnboardingRoute from "./OnboardingRoute";

const Navigation = () => {
  return (
    <Router>
      <Auth />
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.ROOT} component={PAGES.Home} />
        <LoginRoute exact path={ROUTES.LOGIN} component={PAGES.Login} />
        <Route exact path={ROUTES.TEST} component={PAGES.Test} />
        <Route exact path={ROUTES.LINKEDIN} component={LinkedInPopUp} />
        <Route path={`${ROUTES.VIDEO}/:guid`} component={PAGES.Video} />
        <Route path={`${ROUTES.FUND}/:guid`} component={PAGES.Fund} />
        <Route
          path={`${ROUTES.VIDEO_CHANNEL}/:guid`}
          component={PAGES.VideoChannel}
        />
        <Route
          path={`${ROUTES.COMPANY}/:companyGuid`}
          component={PAGES.Company}
        />
        <ProtectedRoute
          path={`${ROUTES.SETTINGS}/*`}
          component={PAGES.UserSettings}
        />
        <ProtectedRoute path={ROUTES.FOLLOWING} component={PAGES.Following} />
        <ProtectedRoute
          path={ROUTES.REORDER_CATEGORIES}
          component={PAGES.ReorderCategories}
        />
        <ProtectedRoute
          path={ROUTES.WATCH_LATER}
          component={PAGES.WatchLater}
        />
        <OnboardingRoute
          exact
          path={ROUTES.ONBOARDING}
          component={PAGES.Onboarding}
        />
        <Route
          path={ROUTES.ONBOARDING_SUCCESS}
          component={PAGES.OnboardingSuccess}
        />
        <Route
          path={ROUTES.TERMS_AND_CONDITIONS}
          component={PAGES.TermsAndConditions}
        />
        <LoaderRoute exact path={ROUTES.LOADER} component={PAGES.Loader} />
        <Route path={"*"} component={PAGES.NotFound} />
      </Switch>
    </Router>
  );
};

export default Navigation;
