import React from "react";
import Navbar from "shared_components/Navbar/Navbar";
import Navigation from "./components/Navigation/Navigation";
import Banner from "./components/Banner/Banner";
import FinishedRegCheck from "auth/FinishedRegCheck";
import { useParams } from "react-router-dom";
//MUI
import useStyles from "./styles";
//REDUX
import { setInitialState } from "redux/ducks/company";
import { useDispatch, useSelector } from "react-redux";

const Company = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company_guid = useSelector((state) => state.data.company.company_guid);
  const { companyGuid } = useParams();

  //clear the previous REDUX storage if company_guid are different
  company_guid !== null &&
    company_guid !== companyGuid &&
    dispatch(setInitialState());

  return (
    <>
      <FinishedRegCheck />
      <Navbar />
      <main className={classes.main}>
        <Banner />
        <Navigation />
      </main>
    </>
  );
};

export default Company;
