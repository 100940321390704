import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  menuPaper: {
    borderRadius: 12,
    border: "1px solid #d3d4d5",
    boxShadow: theme.shadows[4],
    overflow: "hidden",
  },
  menuList: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    marginBottom: -1,
  },
  menuItem: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.grey_tone[300]}`,
  },
  listItem: {
    minWidth: 32,
    paddingRight: 16,
  },
}));
