import React, { useEffect } from "react";
import GenericProfileImage from "assets/icons/icons/generic_profile_image.svg";
import ClockIcon from "assets/icons/icons/clock.svg";
import Comment from "./Comment/Comment";
import CommunityIcon from "assets/icons/icons/community.svg";
import Loader from "shared_components/Loader/Loader";
import { trim } from "shared_functions/shared_functions";

//MUI
import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import LoginTooltip from "shared_components/LoginTooltip/LoginTooltip";
import SortBy from "shared_components/InputComponents/SortBy/SortBy";

//REDUX
import { getComments, submitComment } from "redux/ducks/video";
import { useDispatch, useSelector } from "react-redux";

//items
const POPOVER_ITEMS = [
  {
    text: "Most Recent",
    icon: ClockIcon,
  },
  {
    text: "Most Relevant",
    icon: CommunityIcon,
  },
];

const CommentSection = ({ video_guid }) => {
  const dispatch = useDispatch();
  const { user_guid, is_anonymous, picture_url, first_name, last_name } =
    useSelector((state) => state.auth.user);
  const comments = useSelector((state) => state.data.video.comments);
  const [commentBody, setCommentBody] = React.useState("");
  const classes = useStyles({ commentBody: trim(commentBody) });
  const [sort, setSort] = React.useState("Most Recent");

  useEffect(() => {
    if (user_guid !== null) {
      dispatch(getComments(video_guid, 0));
    }
  }, [dispatch, user_guid, video_guid]);

  switch (sort) {
    case "Most Recent": {
      comments.data.sort(
        (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
      );
      break;
    }
    case "Most Relevant": {
      comments.data.sort((a, b) => b.like_count - a.like_count);
      break;
    }
    default:
      break;
  }

  //events
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      submitComment(
        video_guid,
        trim(commentBody),
        first_name,
        last_name,
        picture_url
      )
    );
    setCommentBody("");
  };

  const handleOnChange = (e) => {
    setCommentBody(e.target.value);
  };

  const handleClick = () => {
    //scroll to the page bottom when making first comment
    if (comments.data.length === 0 && commentBody === "")
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
  };

  return (
    <>
      {comments.is_loading ? (
        <Grid xs={12} item>
          <Loader size={40} />
        </Grid>
      ) : (
        <>
          <Grid xs={12} lg={8} item>
            <div className={classes.header}>
              <div className={classes.commentsCount}>
                <Typography variant="h3">
                  {comments.data !== null ? comments.data.length : 0} Comments
                </Typography>
              </div>
              <div className={classes.sort}>
                <SortBy
                  sort={sort}
                  setSort={setSort}
                  PopOverItems={POPOVER_ITEMS}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={12} lg={8} item>
            <div className={classes.formWrapper}>
              <span className={classes.avatarBorder}>
                <Avatar
                  src={picture_url || GenericProfileImage}
                  className={classes.avatar}
                />
              </span>
              {is_anonymous ? (
                <form className={classes.form}>
                  <LoginTooltip
                    title="Would you like to leave a comment?"
                    description="Login or sign-up to comment"
                    placement="top"
                  >
                    <OutlinedInput
                      variant="outlined"
                      fullWidth
                      placeholder="Login / Sign-up to write a comment"
                      className={classes.textField}
                      classes={{ disabled: classes.textFieldDisabled }}
                      required
                      color="primary"
                      disabled
                    ></OutlinedInput>
                  </LoginTooltip>
                </form>
              ) : (
                <form
                  autoComplete="off"
                  className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <OutlinedInput
                    variant="outlined"
                    fullWidth
                    placeholder="Write your comment here"
                    className={classes.textField}
                    required
                    color="primary"
                    onChange={handleOnChange}
                    onClick={handleClick}
                    value={commentBody}
                    multiline
                  ></OutlinedInput>
                  <div
                    className={classes.submitButtonWrapper}
                    id="submitButtonWrapper"
                  >
                    <ButtonStyled
                      type="submit"
                      className={classes.submitButton}
                      disabled={trim(commentBody) === ""}
                    >
                      Comment
                    </ButtonStyled>
                  </div>
                </form>
              )}
            </div>
          </Grid>
          {comments.data !== null
            ? comments.data.map((comment, index) => (
                <Comment key={index} comment={comment} />
              ))
            : null}
        </>
      )}
    </>
  );
};

export default CommentSection;
