import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_CATEGORIES = "categories/SET_CATEGORIES";
const SET_MORE_CATEGORIES = "categories/SET_MORE_CATEGORIES";
const START_LOADING = "categories/START_LOADING";
const STOP_LOADING = "categories/STOP_LOADING";

const initialState = {
  is_loading: true,
  data: [],
};

export const getCategories = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const {
      data: { categories: data },
    } = await api.getCategories();
    dispatch({ type: SET_CATEGORIES, payload: data });
    dispatch(stopLoading());
  } catch (error) {
    constructAndSendError(
      error.message,
      "categories/getCategories",
      "/data/categories/videos"
    );
    console.log(error);
  }
};

export const getMoreCategoryVideos =
  (category_id, page, size) => async (dispatch) => {
    try {
      const { data } = await api.getMoreCategoryVideos(category_id, page, size);
      dispatch({
        type: SET_MORE_CATEGORIES,
        payload: { ...data, category_id },
      });
    } catch (error) {
      constructAndSendError(
        error.message,
        "categories/getMoreCategoryVideos",
        "/data/category/videos",
        [`category_id:${category_id}`, `page:${page}`, `size:${size}`]
      );
      console.log(error);
    }
  };

export const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      return { ...state, data: action.payload };
    }
    case SET_MORE_CATEGORIES: {
      const { category_id, paging, videos } = action.payload;
      return {
        ...state,
        data: state.data.map((category) => {
          if (category.id === category_id) {
            return {
              ...category,
              paging,
              videos: [...category.videos, ...videos],
            };
          } else {
            return category;
          }
        }),
      };
    }
    case START_LOADING: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING: {
      return { ...state, is_loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
