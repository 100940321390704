import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    borderRadius: `${theme.custom.pxToRem(12)}`,
    overflow: "hidden",
    filter: `drop-shadow(${theme.shadows[1]})`,
    height: "100%",
    "&:hover": {
      filter: `drop-shadow(${theme.custom.palette.shadows.lvl3})`,
    },
  },
  companyLogoWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "white",
    borderBottomRightRadius: `${theme.custom.pxToRem(12)}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: `${theme.custom.pxToRem(40)}`,
  },
  companyLogo: {
    height: `${theme.custom.pxToRem(26)}`,
    padding: `${theme.custom.pxToRem(4)} ${theme.custom.pxToRem(8)}`,
  },
  fundPicWrapper: {
    width: "100%",
    height: `${theme.custom.pxToRem(112)}`,
  },
  fundPic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  infoWrapper: {
    position: "relative",
    zIndex: 100,
    backgroundColor: theme.palette.grey_tone["0"],
    height: "100%",
  },
  wave: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: -1,
    transform: "translateY(-55%)",
  },
  fundLink: {
    textDecoration: "none",
    color: "unset",
  },
  textWrapper: {
    padding: `0 ${theme.custom.pxToRem(8)} ${theme.custom.pxToRem(16)}`,
    display: "flex",
    flexDirection: "column",
    gap: `${theme.custom.pxToRem(4)}`,
  },
  type: {
    color: theme.palette.primary.main,
  },
}));
