import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";

import ButtonStyled from "shared_components/InputComponents/Button/ButtonStyled";
import ButtonSecondary from "shared_components/InputComponents/ButtonSecondary/ButtonSecondary";

export default function WizardBar({
  handleBackClick,
  handleNextClick,
  lastStep,
  activeStep,
  activeSection,
}) {
  const classes = useStyles();
  const [buttonVariant, setButtonVariant] = useState("default");
  const [buttonLabel, setButtonLabel] = useState("Next");

  // if last step - Change variant and label of button
  useEffect(() => {
    if (lastStep === true) {
      setButtonVariant("success");
      setButtonLabel("Finish");
    } else {
      setButtonVariant("default");
      setButtonLabel("Next");
    }
  }, [lastStep]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={4} sm={3} md={2} lg={1}>
            {!(activeStep === 0 && activeSection === 0) && (
              <ButtonSecondary size="lg" fullWidth onClick={handleBackClick}>
                Back
              </ButtonSecondary>
            )}
          </Grid>
          <Grid item xs={6} sm={5} md={3} lg={2}>
            <ButtonStyled
              size="lg"
              variant={buttonVariant}
              fullWidth
              onClick={handleNextClick}
            >
              {buttonLabel}
            </ButtonStyled>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
