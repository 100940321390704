import palette from "styles/palette";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
    marginRight: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(36px)",
      color: `${palette.primary}`,
      "& + $track": {
        backgroundColor: `${palette.grey_tone[300]}`,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      border: `6px solid ${palette.grey_tone[300]}`,
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 24 / 2,
    //border: `1px solid ${palette.grey_tone[300]}`,
    backgroundColor: `${palette.grey_tone[300]}`,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <span data-cy="showFollowedFundSwitch">
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    </span>
  );
});
export default CustomSwitch;
