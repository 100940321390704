import React from "react";
import { ABOUT, FUNDS, VIDEOS, EVENTS } from "navigation/constants";
import { ReactComponent as PlayIcon } from "assets/icons/icons/media.svg";
import { ReactComponent as BrochureIcon } from "assets/icons/icons/brochure.svg";
import { ReactComponent as FundCardIcon } from "assets/icons/icons/fund_card_shape.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/icons/calendar.svg";
import TabBarItem from "./TabBarItem/TabBarItem";
import FollowButton from "shared_components/InputComponents/FollowButton/FollowButton.jsx";
import { useParams } from "react-router";
//MUI
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//REDUX
import { followCompany, unfollowCompany } from "redux/ducks/company";
import { useSelector } from "react-redux";

const tabBarItems = [
  {
    icon: FundCardIcon,
    text: "Funds",
    path: FUNDS,
    svgIssue: true,
  },
  {
    icon: PlayIcon,
    text: "Videos",
    path: VIDEOS,
    svgIssue: false,
  },
  {
    icon: BrochureIcon,
    text: "About",
    path: ABOUT,
    svgIssue: false,
  },
  {
    icon: CalendarIcon,
    text: "Events",
    path: EVENTS,
    svgIssue: true,
  },
];

const TabBar = () => {
  const classes = useStyles();
  const path = window.location.pathname;
  const { companyGuid, playlistGuid } = useParams();
  const isPhone = useMediaQuery("(max-width:599px)");
  const isFollowing = useSelector(
    (state) => state.data.company.is_user_following
  );
  const is_loading = useSelector((state) => state.data.company.is_loading);

  return (
    <>
      {/* display tabbar on all pages expect playlist details page when on phone */}
      {(playlistGuid === undefined || !isPhone) && (
        <section className={classes.navigation} data-cy="tabbar">
          <div className={classes.tabsWrapper}>
            {tabBarItems.map((item, index) => (
              <TabBarItem
                Icon={item.icon}
                text={item.text}
                isActive={path.includes(item.path)}
                key={index}
                link={item.path}
                svgIssue={item.svgIssue}
              />
            ))}
          </div>
          {!is_loading && (
            <FollowButton
              className={classes.button}
              guid={companyGuid}
              onFollow={followCompany}
              onUnfollow={unfollowCompany}
              isFollowing={isFollowing}
            />
          )}
        </section>
      )}
    </>
  );
};

export default TabBar;
