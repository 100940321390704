import React from "react";
import clsx from "clsx";
//MUI
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

const ButtonSecondary = ({
  size,
  variant,
  type,
  onClick,
  children,
  fullWidth,
  className,
  disabled,
}) => {
  let style = { variant };

  const classes = useStyles(style);
  return (
    <Button
      className={clsx(
        className,
        size === "lg" ? classes.largeButton : classes.smallButton
      )}
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
      variant="outlined"
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default ButtonSecondary;
