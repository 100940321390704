import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  backGround: {
    backgroundColor: `${theme.palette.background.secondary}`,
  },
  grid: {
    width: `calc(100% - ${theme.spacing(9)}px) !important`,
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.custom.pxToRem(40)}) !important`,
      paddingTop: `${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${theme.custom.pxToRem(8)}) !important`,
      paddingTop: `${theme.spacing(2)}px`,
    },
    margin: `auto`,
    paddingTop: `${theme.spacing(6)}px`,
  },
  gridItem: {
    marginBottom: `${theme.custom.pxToRem(40)} !important`,
    [theme.breakpoints.down("xs")]: {
      marginBottom: `${theme.custom.pxToRem(16)} !important`,
    },
  },
  reoderLink: {
    textDecoration: "none",
  },
  emptyStateWrapper: {
    paddingTop: `${theme.custom.pxToRem(105)} `,
    paddingBottom: `${theme.custom.pxToRem(105)} `,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(85)} `,
      paddingBottom: `${theme.custom.pxToRem(85)} `,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: `${theme.custom.pxToRem(65)} `,
      paddingBottom: `${theme.custom.pxToRem(65)} `,
    },
  },
  itemsWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  emptyStateIcon: {
    [theme.breakpoints.down("sm")]: {
      height: `${theme.custom.pxToRem(190)}`,
    },
    [theme.breakpoints.down("xs")]: {
      height: `${theme.custom.pxToRem(150)}`,
    },
  },
  emptyStateTitle: {
    marginTop: `${theme.custom.pxToRem(32)} !important`,
  },
  emptyStateText: {
    marginTop: `${theme.custom.pxToRem(12)} !important`,
    color: theme.palette.grey_tone["400"],
  },
  emptyStateButton: {
    marginTop: `${theme.custom.pxToRem(24)} !important`,
  },
}));
