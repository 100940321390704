import React from "react";
import searchIcon from "assets/icons/icons/search.svg";
//MUI
import TextField from "@material-ui/core/TextField";
import useStyles from "./styles";

export default function SearchFilter({
  onChange,
  value,
  placeholder,
  label,
  className,
}) {
  const classes = useStyles();
  return (
    <TextField
      onChange={onChange}
      value={value}
      variant="outlined"
      placeholder={placeholder}
      label={label}
      fullWidth
      className={className}
      classes={{ root: classes.root }}
      InputProps={{
        endAdornment: <img src={searchIcon} alt="Search" />,
      }}
    />
  );
}
