import React from "react";
//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DocumentCard from "pages/Fund/components/DocumentCard/DocumentCard";
//REDUX
import { useSelector } from "react-redux";

const DocumentsSection = ({ title, reduxSelector, sectionId, innerRef }) => {
  const classes = useStyles();
  const documents = useSelector((state) => state.data.fund[`${reduxSelector}`]);
  return (
    <section id={sectionId} ref={innerRef}>
      <Grid
        container
        spacing={3}
        className={classes.documentsGrid}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.documentsTitle}>
            {title}
          </Typography>
        </Grid>
        {documents !== null && documents.length > 0 ? (
          documents.map((document, index) => (
            <Grid
              item
              xs={12}
              md={6}
              className={classes.documentItem}
              key={index}
            >
              <DocumentCard docLink={document.url}>
                <Typography
                  variant="h4"
                  className={classes.text}
                  data-cy="documentTitle"
                >
                  {document.title}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {document.description}
                </Typography>
              </DocumentCard>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4" data-cy="noDocumentsText">
              No documents found for this fund
            </Typography>
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default DocumentsSection;
