import React from "react";
import VideoSnippet from "shared_components/VideoSnippet/VideoSnippet";
import TabBar from "../../components/TabBar/TabBar";
import Link from "shared_components/Link/Link";
import { ROOT } from "navigation/constants";
import emptyVideosStateIcon from "assets/icons/icons/emptyVideosState.svg";
import Loader from "shared_components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useParams } from "react-router-dom";TODO: put this back when we have video channel ready
//MUI
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "shared_components/InputComponents/Button/ButtonStyled";

//REDUX
import { useSelector } from "react-redux";

const Videos = () => {
  const classes = useStyles();
  // const { guid: video_channel_guid } = useParams(); TODO: put this back when we have video channel ready
  const data = useSelector((state) => state.data.video_channel.videos.data);
  const paging = useSelector((state) => state.data.video_channel.videos.paging);
  const is_loading = useSelector(
    (state) => state.data.video_channel.is_loading
  );
  const VIDEOS_SIZE = 12;
  // const dispatch = useDispatch();

  const fetchMoreVideos = () => {
    //TODO:change this to fetch additional video channel videos
    // dispatch(getMoreFundVideos(fund_guid, paging.page + 1, VIDEOS_SIZE));TODO: put this back when we have video channel ready
  };

  return (
    <>
      <TabBar />
      {is_loading ? (
        <Loader size={40} />
      ) : (
        <div className={classes.backGround}>
          {data !== null && data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreVideos}
              hasMore={paging.total > paging.page * VIDEOS_SIZE + VIDEOS_SIZE}
              loader={<Loader />}
            >
              <Grid container spacing={3} className={classes.grid}>
                {data.map((video, index) => {
                  const { snippet } = video;
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      className={classes.gridItem}
                      key={index}
                    >
                      <VideoSnippet snippet={snippet} />
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          ) : (
            <section className={classes.emptyStateWrapper}>
              <div className={classes.itemsWrapper}>
                <img
                  src={emptyVideosStateIcon}
                  alt="icon"
                  className={classes.emptyStateIcon}
                />
                <Typography
                  className={classes.emptyStateTitle}
                  variant="h2"
                  align="center"
                >
                  There are no videos in this video channel
                </Typography>
                <Typography
                  className={classes.emptyStateText}
                  variant="h3"
                  align="center"
                >
                  However, you can check out the other videos
                </Typography>
                <Link className={classes.reoderLink} to={ROOT}>
                  <Button size="lg" className={classes.emptyStateButton}>
                    See other videos
                  </Button>
                </Link>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default Videos;
