import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
//REDUX
import { useSelector } from "react-redux";
import { ONBOARDING } from "navigation/constants";

const FinishedRegCheck = () => {
  const history = useHistory();
  const { investor_type, is_anonymous } = useSelector(
    (state) => state.auth.user
  );

  useEffect(() => {
    // check for investor type null bcs we need to first make a check for a user and we donot know when will we have this information
    if (investor_type === null || is_anonymous) return;
    else if (investor_type < 1) history.push(ONBOARDING);
    else return;
  }, [investor_type, is_anonymous, history]);

  return <></>;
};

export default FinishedRegCheck;
