import React from "react";
import Navigation from "navigation/Navigation";

//Styles
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "styles/MuiTheme";
import "styles/styles.css"; //uncomment this if want to change highlighting color

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* apply reset styling */}
        <CssBaseline />
        <Navigation />
      </ThemeProvider>
    </>
  );
};

export default App;
