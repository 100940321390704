import React from "react";
import AddIcon from "assets/icons/icons/add.svg";
import CrossIcon from "assets/icons/icons/crossWhite.svg";
import TickIcon from "assets/icons/icons/tickWhite.svg";
//MUI
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
//REDUX
import { followFund, unfollowFund } from "redux/ducks/fund";
import { useDispatch } from "react-redux";

const ActionButton = ({ is_user_following, fund_guid }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const handleFollow = () => {
    dispatch(followFund(fund_guid));
    setIsMouseOver(false);
  };

  const handleUnfollow = () => {
    dispatch(unfollowFund(fund_guid));
    setIsMouseOver(false);
  };

  const handleMouseEnter = (e) => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = (e) => {
    setIsMouseOver(false);
  };

  return (
    <>
      {is_user_following ? (
        <>
          <IconButton
            classes={{ root: classes.iconButtonActive }}
            onClick={handleUnfollow}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-cy="followingButton"
          >
            {isMouseOver ? (
              <img src={CrossIcon} alt="Add" />
            ) : (
              <img src={TickIcon} alt="Add" />
            )}
          </IconButton>
          <span className={classes.label}>Following</span>
        </>
      ) : (
        <>
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={handleFollow}
            data-cy="followButton"
          >
            <img src={AddIcon} alt="Add" />
          </IconButton>
          <span className={classes.label}>Follow</span>
        </>
      )}
    </>
  );
};

export default ActionButton;
