import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  smallButton: {
    ...theme.custom.smallButton,
    background: ({ variant }) => {
      switch (variant) {
        case "warning":
          return theme.palette.error.main;
        case "success":
          return theme.palette.success.main;
        case "cta":
          return theme.palette.accent.main;
        default:
          return theme.palette.background.horizontal_gradient;
      }
    },
    "&:hover": {
      background: ({ variant }) => {
        switch (variant) {
          case "warning":
            return theme.palette.error.main;
          case "success":
            return theme.palette.success.main;
          case "cta":
            return theme.palette.accent.main;
          default:
            return theme.palette.background.horizontal_gradient;
        }
      },
    },
  },
  largeButton: {
    ...theme.custom.largeButton,
    background: ({ variant }) => {
      switch (variant) {
        case "warning":
          return theme.palette.error.main;
        case "success":
          return theme.palette.success.main;
        case "cta":
          return theme.palette.accent.main;
        default:
          return theme.palette.background.horizontal_gradient;
      }
    },
    "&:hover": {
      background: ({ variant }) => {
        switch (variant) {
          case "warning":
            return theme.palette.error.main;
          case "success":
            return theme.palette.success.main;
          case "cta":
            return theme.palette.accent.main;
          default:
            return theme.palette.background.horizontal_gradient;
        }
      },
    },
  },
}));
