import React from "react";
import CoherraLogo from "assets/icons/logos/coherra_blue.svg";
//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

// Takes Stepper as a children component
const ProgressBar = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <div className={classes.container}>
        <img src={CoherraLogo} alt="coherra's logo" className={classes.logo} />
        <Typography variant="h4" className={classes.subheader}>
          Investor Sign-up
        </Typography>
        <Typography variant="h3" className={classes.name}>
          Name
        </Typography>
        {children}
      </div>
    </div>
  );
};

export default ProgressBar;
