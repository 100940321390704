import React from "react";

//MUI
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// Assets
import coherraLogo from "assets/icons/logos/coherra-Logo-white.svg";

const lastUpdated = "Last updated 2021, July 25";

const content = [
  {
    summary: "Lorem ipsum dolor sit amet",
    header: "Lorem Header ipsum dolor sit amet",
    body: "first Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quod ea non occurrentia fingunt, vincunt Aristonem; Non quam nostram quidem, inquit Pomponius iocans; Non est igitur summummalum dolor. Hoc ipsum elegantius poni meliusque potuit. Suam denique cuique naturam esse ad vivendum ducem. Duo Reges: constructio interrete. Tum Triarius: Posthac quidem, inquit, audacius. Haec para/doca illi, nos admirabilia dicamus. Nam si beatus umquam fuisset, beatam vitam usque ad illum a Cyr extructum rogum pertulisset.",
  },
  {
    summary: "Lorem ipsum dolor sit amet2",
    header: "Lorem Header ipsum dolor sit amet2",
    body: "second Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quod ea non occurrentia fingunt, vincunt Aristonem; Non quam nostram quidem, inquit Pomponius iocans; Non est igitur summummalum dolor. Hoc ipsum elegantius poni meliusque potuit. Suam denique cuique naturam esse ad vivendum ducem. Duo Reges: constructio interrete. Tum Triarius: Posthac quidem, inquit, audacius. Haec para/doca illi, nos admirabilia dicamus. Nam si beatus umquam fuisset, beatam vitam usque ad illum a Cyr extructum rogum pertulisset.",
  },
  {
    summary: "Lorem ipsum dolor sit amet3",
    header: "Lorem Header ipsum dolor sit amet3",
    body: "third Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quod ea non occurrentia fingunt, vincunt Aristonem; Non quam nostram quidem, inquit Pomponius iocans; Non est igitur summummalum dolor. Hoc ipsum elegantius poni meliusque potuit. Suam denique cuique naturam esse ad vivendum ducem. Duo Reges: constructio interrete. Tum Triarius: Posthac quidem, inquit, audacius. Haec para/doca illi, nos admirabilia dicamus. Nam si beatus umquam fuisset, beatam vitam usque ad illum a Cyr extructum rogum pertulisset.",
  },
  {
    summary: "Lorem ipsum dolor sit amet4",
    header: "Lorem Header ipsum dolor sit amet4",
    body: "third Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quod ea non occurrentia fingunt, vincunt Aristonem; Non quam nostram quidem, inquit Pomponius iocans; Non est igitur summummalum dolor. Hoc ipsum elegantius poni meliusque potuit. Suam denique cuique naturam esse ad vivendum ducem. Duo Reges: constructio interrete. Tum Triarius: Posthac quidem, inquit, audacius. Haec para/doca illi, nos admirabilia dicamus. Nam si beatus umquam fuisset, beatam vitam usque ad illum a Cyr extructum rogum pertulisset.",
  },
];

const TermsAndConditions = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <img src={coherraLogo} alt="Coherra Logo" className={classes.logo} />
      <Grid container>
        <Grid item className={classes.page} xs={10} sm={10} md={8}>
          <div className={classes.header}>
            <Typography variant="h1">Terms & Conditions</Typography>
            <Typography variant="h5" color="primary">
              {lastUpdated}
            </Typography>
          </div>
          <Divider />
          <div className={classes.content}>
            <Typography variant="h3">Summary</Typography>
            {/* Summary Items */}
            <div className={classes.summary}>
              {content.map((item, i) => {
                return (
                  <Typography
                    variant="body1"
                    className={classes.summaryItem}
                    key={i}
                  >
                    <span className={classes.number}>{i + 1}. </span>
                    {item.summary}
                  </Typography>
                );
              })}
            </div>
            {/* Paragraph Items */}
            <div className={classes.paragraphs}>
              {content.map((item, i) => {
                return (
                  <div className={classes.paragraphItems}>
                    <Typography
                      variant="h3"
                      className={classes.paragraphHeader}
                    >
                      {i + 1}. {item.header}
                    </Typography>
                    <Typography variant="body1">{item.body}</Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;
