const data = {
  is_loading: true,
  id: 3,
  guid: 3,
  name: "Top Gain",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas justo id mi suscipit, vel congue lorem mattis. Praesent ut leo vel turpis pretium finibus. Sed eget cursus ex, pharetra scelerisque sem. Cras varius leo risus, fermentum maximus risus posuere non. Donec et lectus commodo mi sodales iaculis viverra sed arcu. Fusce porta sapien id orci convallis, a porta nisi viverra. Nam aliquam, metus in dignissim auctor, quam enim ornare sem, et finibus ante tortor sollicitudin nulla. Vivamus nec enim nunc. Suspendisse nec molestie nibh. Suspendisse bibendum lacinia massa, vitae feugiat turpis sagittis semper. Sed vitae dictum neque. Proin commodo.",
  channel_pic:
    "https://novoportal.topgain.com/wp-content/uploads/2021/10/panorama-estudio2.jpg",
  channel_logo:
    "https://pbs.twimg.com/profile_images/1306207171718283265/nfAqHiN7_400x400.jpg",
  followers_count: 100934,
  is_user_following: false,
  videos: {
    is_loading: false,
    paging: {
      total: 1,
      page: 0,
      size: 1,
    },
    data: [
      {
        snippet: {
          id: 192,
          video_guid: "2799c0b5-d95b-415c-8f87-dad1bd663c2c",
          clip_id: 625420162,
          thumbnail_pic:
            "https://i.ytimg.com/vi/1c3x0zkyY5A/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA3jO_8jDsJziZj3om45YUjQ0IWfg",
          duration: 2319,
          views_count: 153,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  08/12",
          time_stamp: "2021-10-07T14:37:03.960626+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 191,
          video_guid: "2c0e0713-92b2-4249-ade5-eb60925720d4",
          clip_id: 625411896,
          thumbnail_pic:
            "https://i.ytimg.com/vi/1c3x0zkyY5A/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA3jO_8jDsJziZj3om45YUjQ0IWfg",
          duration: 2248,
          views_count: 20,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  07/12",
          time_stamp: "2021-10-07T14:31:39.12572+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 190,
          video_guid: "cd6a7500-4d49-47ab-b3c8-12a54c96d302",
          clip_id: 625407897,
          thumbnail_pic:
            "https://i.ytimg.com/vi/aiF33DzJjHE/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCKR-qODiV0f4cohcqZQLvIrf6xNQ",
          duration: 3813,
          views_count: 3,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  06/12",
          time_stamp: "2021-10-07T14:22:51.412897+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/gNFBIKDPp4Q/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDkUbAM0r4VWxRrrzs_tkc9SRZpqA",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  03/12",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/4ZMyDbPBfaE/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCuV8uV6K6X6GuHf5Dsa-N1rMGkng",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  02/12",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/VHF8PwoRCXA/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAkOuoytx4Uce9Y1fjXp6y7Ret7Jg",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  01/12",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/wriC7dul5H4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCvotFJ9F4VxzzaksTckWKiIyTL4A",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  30/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/GWLR7OVfkn4/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBw3ijzlwaRqeZ17nhGhGdJTxRDvg",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  29/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/UB8n56KrN0c/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDpPzn75wZAhAHLogPsiJz-Kc8OOg",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  26/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/1c3x0zkyY5A/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA3jO_8jDsJziZj3om45YUjQ0IWfg",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  25/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/RsThsF1MyTE/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC6RK1Dde2hCVgfoUxBbWKXLUE_0A",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  24/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
      {
        snippet: {
          id: 189,
          video_guid: "2150178c-0407-4428-8d46-93082005f364",
          clip_id: 625399159,
          thumbnail_pic:
            "https://i.ytimg.com/vi/QRplRaoTm1E/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCh8MShIvcb37tZkNKoQrCXD7Uj0g",
          duration: 1830,
          views_count: 82,
          title: "SALA DE FORMAÇÃO DE INVESTIDORES  23/11",
          time_stamp: "2021-10-07T14:11:55.08658+00:00",
          primary_fund_name: "Top Gain",
          primary_fund_id: 10,
          primary_fund_guid: "1",
          primary_fund_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/1/1f0d35aa-fe23-4d58-a852-2e2fbe0818e1.jpg",
          primary_fund_visible: true,
          company_pic:
            "https://explorer-stage.coherra.com:5023/funds/10/5/b08b98ea-b30c-4d4b-b8c3-d364ea814aa7.png",
        },
      },
    ],
  },
  details: {
    address: "São Paulo, Brazil",
    external_website_link: "https://novoportal.topgain.com/",
    joined: "2021-10-07T14:11:55.08658+00:00",
  },
};

export default data;
