//action types
const SET_SHOW_FOLLOWED_FUNDS = "ui/SET_SHOW_FOLLOWED_FUNDS";

const initialState = {
  showFollowedFunds: false,
};

export const setShowFollowedFunds = (state) => (dispatch) => {
  dispatch({ type: SET_SHOW_FOLLOWED_FUNDS, payload: state });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_FOLLOWED_FUNDS: {
      return {
        ...state,
        showFollowedFunds: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
