import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  relatedFunds: {
    marginTop: `${theme.custom.pxToRem(48)} !important`,
  },
  card: {
    borderRadius: `${theme.custom.pxToRem(12)} !important`,
    border: `${theme.custom.pxToRem(1)} solid ${
      theme.palette.grey_tone["300"]
    } !important`,
    display: "flex",
    textDecoration: "none",
    color: `${theme.palette.primary.main} !important`,
    "&:hover": {
      textDecoration: "underline",
    },
    marginTop: `${theme.custom.pxToRem(16)} !important`,
    alignItems: "center",
    height: `${theme.custom.pxToRem(80)} !important`,
    [theme.breakpoints.down("xs")]: {
      height: `${theme.custom.pxToRem(55)} !important`,
    },
  },
  image: {
    height: `100%`,
    width: `${theme.custom.pxToRem(120)}`,
    [theme.breakpoints.down("xs")]: {
      width: `${theme.custom.pxToRem(64)}`,
      marginRight: `${theme.custom.pxToRem(8)}`,
    },
    objectFit: "cover",
    marginRight: `${theme.custom.pxToRem(32)}`,
    borderRadius: `
    ${theme.custom.pxToRem(12)} 
    ${theme.custom.pxToRem(0)} 
    ${theme.custom.pxToRem(0)} 
    ${theme.custom.pxToRem(12)} !important`,
  },

  fundName: {
    flexGrow: 1,
  },
}));
