import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette.js";

export default makeStyles((theme) => ({
  divider: {
    marginTop: 16,
  },
  link: {
    textDecoration: "none",
  },
  textButton: {
    marginTop: 8,
    color: palette.accent,
    fontFamily: "ProximaNovaSemibold",
    "&:hover": {
      color: palette.accent,
      textDecoration: "underline",
    },
  },
}));
