import React from "react";
import CoherraLogo from "assets/icons/logos/coherra_blue.svg";
import { ReactComponent as GreenTick } from "assets/icons/icons/greenTickCircle.svg";
import Button from "shared_components/InputComponents/Button/ButtonStyled";
import { useHistory } from "react-router";
import { ROOT } from "navigation/constants";

//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
//REDUX
import { useSelector } from "react-redux";

const OnboardingSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const last_page_url = useSelector((state) => state.auth.user.last_page_url);
  //   prevent visiting this page from other than onboarding page
  if (history.location.state === undefined) history.push(ROOT);

  const handleClick = () => {
    //this will empty current stack object state
    const replacement = {
      ...history,
      location: {
        ...history.location,
        state: {},
      },
    };
    history.replace(replacement);
    history.push(last_page_url);
  };

  return (
    <>
      <div className={classes.logoWrapper}>
        <img src={CoherraLogo} alt="coherra's logo" className={classes.logo} />
      </div>
      <section className={classes.section}>
        <div className={classes.informationWrapper}>
          <GreenTick />
          <Typography variant="h3" align="center" className={classes.text}>
            Congratulations!<br></br>Your account has been created
          </Typography>
          <Button className={classes.button} size="lg" onClick={handleClick}>
            Continue
          </Button>
        </div>
      </section>
    </>
  );
};

export default OnboardingSuccess;
