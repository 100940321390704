import React from "react";
import ViewPdfIcon from "assets/icons/icons/view_pdf.svg";
// import DownloadIcon from "assets/icons/icons/download.svg"; put this back if you solve CORS on line 27
//MUI
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const DocumentCard = ({ children, docLink }) => {
  const classes = useStyles();
  return (
    <Card>
      <div className={classes.wrapper}>
        <div className={classes.children}>{children}</div>
        <div className={classes.actionIcons}>
          <Tooltip title="Open document" placement="top">
            <div className={classes.actionIcon}>
              <a href={docLink} target="_blank" rel="noreferrer">
                <IconButton classes={{ root: classes.iconButton }}>
                  <img src={ViewPdfIcon} alt="View PDF" />
                </IconButton>
              </a>
            </div>
          </Tooltip>

          {/* does not work bcs of cross origin policy 
          <div className={classes.actionIcon}>
            <a href={docLink} download>
              <IconButton classes={{ root: classes.iconButton }}>
                <img src={DownloadIcon} alt="Download" />
              </IconButton>
            </a>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export default DocumentCard;
