import { makeStyles } from "@material-ui/core/styles";
import palette from "styles/palette";

export default makeStyles((theme) => ({
  page: {
    paddingTop: `${theme.custom.pxToRem(80)} `,
    paddingBottom: `${theme.custom.pxToRem(80)} `,
    backgroundColor: palette.grey_tone[100],
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(48)} `,
    },
  },
  pageContent: {
    position: "relative",
    marginLeft: `${theme.custom.pxToRem(250)} `,
    padding: `${theme.custom.pxToRem(48)} `,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.custom.pxToRem(32)} `,
      marginLeft: `${theme.custom.pxToRem(200)} `,
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.custom.pxToRem(16)} `,
      marginLeft: `0`,
      height: "auto",
      minHeight: "calc(100vh - 194px)",
    },
  },
}));
