import React from "react";
// MUI
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import useStyles from "./styles";

export default function MenuPopOver({
  anchor,
  handleClose,
  listItems,
  setSort,
}) {
  const classes = useStyles();

  return (
    <div>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchor}
        onClose={handleClose}
        open={Boolean(anchor)}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
      >
        {listItems.map((item, index) => (
          <MenuItem
            key={index}
            data-my-value={item.text}
            onClick={() => setSort(item.text)}
            classes={{ root: classes.menuItem }}
          >
            <ListItemIcon>
              <img src={item.icon} alt="icon" />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
