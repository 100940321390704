import * as api from "api/api";
import { constructAndSendError } from "shared_functions/shared_functions";

//action types
const SET_VIDEOS = "watch_later/SET_VIDEOS";
const SET_MORE_VIDEOS = "watch_later/SET_MORE_VIDEOS";
const ADD_ONE_VIDEO = "watch_later/ADD_ONE_VIDEO";
const LIKE_VIDEO = "watch_later/LIKE_VIDEO";
const UNLIKE_VIDEO = "watch_later/UNLIKE_VIDEO";
const START_LOADING = "watch_later/START_LOADING";
const STOP_LOADING = "watch_later/STOP_LOADING";
const REMOVE_FROM_LIST = "watch_later/REMOVE_FROM_LIST";

const initialState = {
  is_loading: true,
  data: [],
  paging: {
    total: 0,
    page: 0,
    size: 10,
  },
};

//ACTIONS

export const getWatchLaterVideos = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let { data } = await api.getWatchLaterVideos();
    //if there are none videos in the database assosiated with watch later list fill the videos with empty array to make displaying easier
    if (data.videos === null) data.videos = [];
    dispatch({ type: SET_VIDEOS, payload: data });
    dispatch(stopLoading());
  } catch (error) {
    constructAndSendError(
      error.message,
      "watch_later/getWatchLaterVideos",
      "/data/watchlater/videos"
    );
    console.log(error);
  }
};

export const getMoreWatchLaterVideos = (page, size) => async (dispatch) => {
  try {
    let { data } = await api.getWatchLaterVideos(page, size);
    dispatch({ type: SET_MORE_VIDEOS, payload: data });
  } catch (error) {
    constructAndSendError(
      error.message,
      "watch_later/getMoreWatchLaterVideos",
      "/data/watchlater/videos",
      [`page:${page}`, `size:${size}`]
    );
    console.log(error);
  }
};

export const removeFromWatchLater = (video_guid, page) => async (dispatch) => {
  try {
    //fetch one more video from next page
    let { data } = await api.getWatchLaterVideos(page);
    //append only if we have video in the next page
    if (data.videos !== null) {
      dispatch({
        type: ADD_ONE_VIDEO,
        payload: {
          video: data.videos[0],
        },
      });
    }
    await api.removeFromWatchLater(video_guid);
    dispatch({ type: REMOVE_FROM_LIST, payload: video_guid });
  } catch (error) {
    constructAndSendError(
      error.message,
      "watch_later/removeFromWatchLAter",
      "/video/watchlater/remove",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const likeVideo = (video_guid) => async (dispatch) => {
  try {
    await api.likeVideo(video_guid);
    dispatch({ type: LIKE_VIDEO, payload: video_guid });
  } catch (error) {
    constructAndSendError(
      error.message,
      "watch_later/likeVideo",
      "/video/like",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const unlikeVideo = (video_guid) => async (dispatch) => {
  try {
    await api.unlikeVideo(video_guid);
    dispatch({ type: UNLIKE_VIDEO, payload: video_guid });
  } catch (error) {
    constructAndSendError(
      error.message,
      "watch_later/unlikeVideo",
      "/video/unlike",
      [`video_guid:${video_guid}`]
    );
    console.log(error);
  }
};

export const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEOS: {
      return {
        ...state,
        data: action.payload.videos,
        paging: action.payload.paging,
      };
    }
    case SET_MORE_VIDEOS: {
      return {
        ...state,
        data: [...state.data, ...action.payload.videos],
        paging: action.payload.paging,
      };
    }
    case ADD_ONE_VIDEO: {
      return {
        ...state,
        data: [...state.data, action.payload.video],
      };
    }
    case LIKE_VIDEO: {
      return {
        ...state,
        data: state.data.map((video) => {
          return video.snippet.video_guid === action.payload
            ? {
                ...video,
                details: {
                  ...video.details,
                  is_liked: true,
                  likes_count: video.details.likes_count + 1,
                },
              }
            : video;
        }),
      };
    }
    case UNLIKE_VIDEO: {
      return {
        ...state,
        data: state.data.map((video) => {
          return video.snippet.video_guid === action.payload
            ? {
                ...video,
                details: {
                  ...video.details,
                  is_liked: false,
                  likes_count: video.details.likes_count - 1,
                },
              }
            : video;
        }),
      };
    }
    case REMOVE_FROM_LIST: {
      return {
        ...state,
        data: state.data.filter(
          (video) => video.snippet.video_guid !== action.payload
        ),
        paging: {
          ...state.paging,
          total: state.paging.total - 1,
        },
      };
    }
    case START_LOADING: {
      return { ...state, is_loading: true };
    }
    case STOP_LOADING: {
      return { ...state, is_loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
