import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    cursor: "pointer",
  },
  header: {
    padding: `${theme.custom.pxToRem(16)}`,
  },
  text: {
    padding: `${theme.custom.pxToRem(16)}`,
  },
  badge: {
    right: "5px !important",
    top: "5px !important",
  },
  paper: {
    borderRadius: 12,
    width: 380,
    border: "1px solid #d3d4d5",
    boxShadow: theme.shadows.lvl3,
    overflow: "hidden",
  },
  list: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    marginBottom: -1,
  },
}));
