import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  line: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: `${theme.custom.pxToRem(16)} 0 !important`,
    },
    margin: `${theme.custom.pxToRem(40)} 0 !important`,
  },
  backDrop: {
    height: "100vh",
    width: "100vw",
    transform: "translate(-50%,-50%)"
  }
}));
