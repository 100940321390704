import React from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useHistory } from "react-router-dom";
import axios from "axios";
import LinkedInIcon from "assets/icons/socials/linkedin.svg";
import { ONBOARDING, LOADER } from "navigation/constants";
import { constructAndSendError } from "shared_functions/shared_functions";
//mui
import clsx from "clsx";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";

//REDUX
import { authorize, setLastPageUrl } from "redux/ducks/user";
import { useDispatch } from "react-redux";

//https://www.linkedin.com/developers/apps
//window.coherra_env global object with environment

const LinkedInLoginButton = () => {
  const history = useHistory();
  //check for the PROD fist and leave stage and development to use staing version

    const SERVER_URL =
        window.coherra_env.environment === "PROD"
            ? "https://linkedin-prodproxy.coherra.com/data"
            : "https://linkedin-stageproxy.coherra.com/data";

    const classes = useStyles();
  const dispatch = useDispatch();
  const redirectUrl = history.location.state;

  const handleSuccess = async ({ code }) => {
    try {
      localStorage.setItem("lastUrl", "/login");
      history.push(LOADER);
      const { data } = await axios.get(`${SERVER_URL}/${code}`, {
        params: {
          redirect_url: `${window.location.origin}/linkedin`,
          app_type_id: 1,
        },
      });
      const userAuthObj = { ...data };
      //needs to be done this way bcs user_id never changes
      userAuthObj.access_token = data.user_id;
      userAuthObj.app_id = process.env.REACT_APP_APP_ID;
      const { user } = await dispatch(authorize(userAuthObj));
      //remove redirectUrl stored by facebook button
      localStorage.removeItem("redirectUrl");
      //check if the user needs to go through the registration process
      if (user[0].investor_type === 0) {
        //persist this to know where to forward user after onboarding
        dispatch(setLastPageUrl(redirectUrl));
        history.push(ONBOARDING);
      } else history.push(redirectUrl);
    } catch (error) {
      constructAndSendError(
        error.message,
        "linkedin/handleSuccess",
        "linkedinOAuth2"
      );
      console.log(error);
    }
  };

  const handleFailure = (error) => {
    constructAndSendError(
      error.errorMessage,
      "linkedin/handleFailure",
      "linkedinOAuth2"
    );
    console.log(error);
  };

  return (
    <LinkedIn
      clientId={
        window.coherra_env.environment === "STAGE"
          ? process.env.REACT_APP_LINKEDIN_CLIENT_ID_STAGE
          : process.env.REACT_APP_LINKEDIN_CLIENT_ID_PROD
      }
      onFailure={handleFailure}
      onSuccess={handleSuccess}
      scope="r_liteprofile r_emailaddress"
      supportIE
      redirectPath="/linkedin"
      redirectUri={`${window.location.origin}/linkedin`}
      renderElement={({ onClick, disabled }) => (
        <Button
          className={clsx(classes.largeButton, classes.linkedIn)}
          onClick={onClick}
          disabled={disabled}
          //overwrittes MuiButton-label class
          classes={{
            label: classes.label,
          }}
        >
          <img
            src={LinkedInIcon}
            alt="linkedin logo"
            className={classes.icon}
          />
          Continue&#160;with&#160;LinkedIn
        </Button>
      )}
    />
  );
};

export default LinkedInLoginButton;
