import React from "react";
import Link from "shared_components/Link/Link";
import { useParams } from "react-router-dom";
import { FUND } from "navigation/constants";

//MUI
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const TabBarItem = ({ Icon, text, isActive, link, svgIssue }) => {
  const classes = useStyles({ svgIssue });
  const { guid } = useParams();
  return (
    <div className={classes.linkTab}>
      <Link
        to={`${FUND}/${guid}${link}`}
        className={isActive ? classes.activeLink : classes.link}
      >
        <Icon className={isActive ? classes.activeTab : ""} />
        <Typography variant="h4" className={isActive ? classes.activeTab : ""}>
          {text}
        </Typography>
      </Link>
      {isActive && <div className={classes.underLine}></div>}
    </div>
  );
};

export default TabBarItem;
