import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  contentLayout: {
    marginLeft: `${theme.custom.pxToRem(250)}`,
    paddingTop: `${theme.custom.pxToRem(136)}`,
    paddingLeft: `${theme.custom.pxToRem(48)}`,
    paddingRight: `${theme.custom.pxToRem(48)}`,
    background: theme.palette.background.secondary,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.custom.pxToRem(80)} `,
      marginLeft: `${theme.custom.pxToRem(200)} `,
    },

    [theme.breakpoints.down("xs")]: {
      marginLeft: `0`,
      padding: `${theme.custom.pxToRem(64)} ${theme.custom.pxToRem(16)} 0`,
    },
  },
}));
